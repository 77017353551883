import React, {useEffect, useState} from 'react'
import {Breadcrumb} from "@themesberg/react-bootstrap";
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import {Button, Card, Col, Form, Input, InputNumber, notification, Radio, Row, Select} from "antd";
import lodash from 'lodash';
import {
    getConditionsByGoalAndSubGoal,
    getStagesByGoal,
    NOTIFICATION_OBJECT_ACCOUNT_TYPE,
    NOTIFICATION_OBJECT_CONDITION,
    NOTIFICATION_OBJECT_TARGET_ARR,
    NOTIFICATION_OBJECT_TYPE
} from "../../common/notification.constant";
import CategoryService from "../../services/category.service";
import NotificationObjectService from "../../services/notification-object.service";
import {Link} from "react-router-dom";

const notificationObjectTypes = lodash.map(NOTIFICATION_OBJECT_TYPE, v => v)
const notificationObjectAccountTypes = lodash.map(NOTIFICATION_OBJECT_ACCOUNT_TYPE, v => v)

const categoryService = new CategoryService()
const notificationObjectService = new NotificationObjectService()

export const NotificationObjectCreate = () => {
    const [form, setForm] = useState({})
    const [conditionsByAccountType, setConditionsByAccountType] = useState([])
    const [isShowInputDay, setIsShowInputDay] = useState(false)
    const [isShowInputOfTypeObject, setIsShowInputOfTypeObject] = useState(false)
    const [conditions, setConditions] = useState([])
    const [subGoals, setSubGoals] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        categoryService.getCustomerGroupCondition()
            .then(response => {
                setConditions(response.data)
            })
    }, [])

    useEffect(() => {
        categoryService.getSubGoal()
            .then(response => setSubGoals(response.data))
    }, [])

    const getConditions = (goal, subGoal) => {
        return getConditionsByGoalAndSubGoal(conditions, goal, subGoal)
    }

    const handleFormChange = (field, value) => {
        if (field === 'customerClassification') {
            if (value === NOTIFICATION_OBJECT_TYPE.OBJECT.code) {
                setIsShowInputOfTypeObject(true)
            } else {
                setIsShowInputOfTypeObject(false)
            }
        }

        if (field === 'customerType') {
            setConditionsByAccountType(lodash.get(NOTIFICATION_OBJECT_CONDITION, value) || [])
            lodash.unset(form, 'customerStatus')
            setIsShowInputDay(false)
        }

        if (field === 'customerStatus') {
            if (['inactive', 'expired', 'trial_expire_soon'].indexOf(value) >= 0) {
                setIsShowInputDay(true)
            }
            else {
                setIsShowInputDay(false)
            }
        }

        if (field === 'goal') {
            lodash.unset(form, 'subGoal')
            lodash.unset(form, 'stage')
            lodash.unset(form, 'stageValue')
            lodash.unset(form, 'conditions')
        }

        setForm({
            ...form,
            [field]: value
        })
    }

    const handleSubmit = () => {
        setLoading(true)
        const data = {...form}
        if (data.customerStatus === 'inactive') {
            lodash.unset(data, 'dayOfExpiredTrial')
            lodash.unset(data, 'dayOfRemainingTrial')
        }

        if (data.customerStatus === 'trial_expired') {
            lodash.unset(data, 'dayOfInactive')
            lodash.unset(data, 'dayOfRemainingTrial')
        }

        if (data.customerStatus === 'trial_expire_soon') {
            lodash.unset(data, 'dayOfExpiredTrial')
            lodash.unset(data, 'dayOfInactive')
        }

        if (data.goal === 'mongcon') {
            lodash.unset(data, 'trimester')
            lodash.unset(data, 'week')
            lodash.unset(data, 'month')
            lodash.unset(form, 'stage')
            lodash.unset(form, 'stageValue')
        }

        if (['new', 'inactive'].indexOf(data.customerStatus) < 0) {
            lodash.unset(data, 'customerStatus')
        }

        notificationObjectService.create(data)
            .then(() => {
                setLoading(false)
                notification.success({
                    message: "Tạo thành công"
                })
                setForm({})
            })
            .catch(error => {
                setLoading(false)
                notification.error({
                    message: error.message || "Lỗi"
                })
            })
    }

    return (
        <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block"
                                listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                        <Breadcrumb.Item to={Routes.DashboardOverview.path}><FontAwesomeIcon icon={Icon.faHome}/></Breadcrumb.Item>
                        <Breadcrumb.Item to={Routes.NotificationObject.path}><FontAwesomeIcon icon={Icon.faBeer}/></Breadcrumb.Item>
                        <Breadcrumb.Item active>Tạo đối tượng nhận thông báo</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>

            <Card title={"Tạo đối tượng nhận thông báo"}>
                <Form labelCol={{sm: 24, xl: 6}} wrapperCol={{sm: 24, xl: 12}}>
                    <Form.Item
                        label={"Tên đối tượng"}
                        required={true}
                    >
                        <Input
                            value={form.name}
                            onChange={e => handleFormChange('name', e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item label={"Phân loại"} required={true}>
                        <Radio.Group
                            value={form.customerClassification}
                            onChange={e => handleFormChange('customerClassification', e.target.value)}
                        >
                            {notificationObjectTypes.map(item => (
                                <Radio key={item.code} value={item.code}>{item.name}</Radio>
                            ))}
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label={"Loại tài khoản"} required={true}>
                        <Radio.Group
                            value={form.customerType}
                            onChange={e => handleFormChange('customerType', e.target.value)}
                        >
                            {notificationObjectAccountTypes.map(item => (
                                <Radio key={item.code} value={item.code}>{item.name}</Radio>
                            ))}
                        </Radio.Group>
                    </Form.Item>

                    {!isShowInputOfTypeObject && (
                        <Form.Item label={"Điều kiện"}>
                            <Row gutter={20}>
                                <Col span={10}>
                                    <Form.Item noStyle>
                                        <Select
                                            allowClear={true}
                                            value={form.customerStatus}
                                            onChange={value => handleFormChange('customerStatus', value)}
                                        >
                                            {conditionsByAccountType.map(item => (
                                                <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {isShowInputDay && (
                                    <>
                                        <Col span={4}><span className={'ant-form-text'}>trong vòng</span></Col>
                                        <Col span={6}>
                                            {form.customerStatus === 'inactive' && (
                                                <Form.Item>
                                                    <InputNumber
                                                        value={form.dayOfInactive}
                                                        onChange={value => handleFormChange('dayOfInactive', value)}
                                                        className={'w100p'}
                                                    />
                                                </Form.Item>
                                            )}

                                            {form.customerStatus === 'trial_expired' && (
                                                <Form.Item>
                                                    <InputNumber
                                                        value={form.dayOfExpiredTrial}
                                                        onChange={value => handleFormChange('dayOfExpiredTrial', value)}
                                                        className={'w100p'}
                                                    />
                                                </Form.Item>
                                            )}

                                            {form.customerStatus === 'trial_expire_soon' && (
                                                <Form.Item>
                                                    <InputNumber
                                                        value={form.dayOfRemainingTrial}
                                                        onChange={value => handleFormChange('dayOfRemainingTrial', value)}
                                                        className={'w100p'}
                                                    />
                                                </Form.Item>
                                            )}

                                        </Col>
                                        <Col span={4}><span>Ngày</span></Col>
                                    </>
                                )}
                            </Row>
                        </Form.Item>
                    )}

                    {isShowInputOfTypeObject && (
                        <>
                            <Form.Item label={"Mục tiêu"} required={true}>
                                <Row gutter={20}>
                                    <Col span={8}>
                                        <Form.Item>
                                            <Select
                                                allowClear={true}
                                                value={form.goal}
                                                onChange={value => handleFormChange('goal', value)}
                                            >
                                                {NOTIFICATION_OBJECT_TARGET_ARR.map(item => (
                                                    <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item label={"Lựa chọn lộ trình"}>
                                            <Select
                                                allowClear={true}
                                                value={form.subGoal}
                                                onChange={value => handleFormChange('subGoal', value)}
                                            >
                                                {subGoals.filter(item => item.goal === form.goal).map(item => (
                                                    <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>

                            {(form.goal === 'mangthai' || form.goal === 'sausinh') && (
                                <>
                                    <Form.Item label={"Giai đoạn"}>
                                        <Row gutter={20}>
                                            <Col span={10}>
                                                <Form.Item>
                                                    <Select
                                                        allowClear={true}
                                                        value={form.stage}
                                                        onChange={value => handleFormChange('stage', value)}
                                                    >
                                                        {getStagesByGoal(form.goal).map(item => (
                                                            <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                {['trimester', 'week', 'month'].indexOf(form.stage) >= 0 && (
                                                    <Form.Item>
                                                        <InputNumber
                                                            value={form.stageValue}
                                                            onChange={value => handleFormChange('stageValue', value)}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Col>
                                        </Row>
                                    </Form.Item>

                                    <Form.Item label={"Điều kiện"}>
                                        <Select
                                            allowClear={true}
                                            value={form.conditions}
                                            onChange={value => handleFormChange('conditions', value)}
                                        >
                                            {getConditions(form.goal, form.subGoal).map(item => (
                                                <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </>
                            )}
                        </>
                    )}

                    <Form.Item wrapperCol={{offset: 6, xl: 12}}>
                        <Button loading={loading} disabled={loading} onClick={handleSubmit} type={'primary'}>Tạo đối tượng</Button>
                        <Button loading={loading} disabled={loading} type={'link'}>
                            <Link to={'/notifications/objects'}>Huỷ</Link>
                        </Button>
                    </Form.Item>
                </Form>
            </Card>

        </div>
    );
}
