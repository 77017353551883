import APIService from './api.services';

class TargetService extends APIService {
    
    constructor() {
        super();
        this.modulePath = 'target';
    }
}

export default TargetService;