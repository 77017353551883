import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, Route, Switch} from "react-router-dom";
import {Routes} from "../routes";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {checkExpired, readProfile} from '../store/global';

// pages
import DashboardOverview from "./dashboard/DashboardOverview";
import Transactions from "./Transactions";
import Settings from "./Settings";
import BootstrapTables from "./tables/BootstrapTables";
import Signin from "./auth/Signin";
import ForgotPassword from "./auth/ForgotPassword";
import ResetPassword from "./auth/ResetPassword";
import Lock from "./auth/Lock";
import NotFoundPage from "./auth/NotFound";
import ServerError from "./auth/ServerError";

// users
import Users from './users/Users';
import UserForm from './users/UserForm';
import Targets from './target/Targets';
import Topics from './topic/Topics';
import Posts from './post/Posts';
import Categories from './category/Categories';
import Packages from './package/Packages';
import Promotions from './promotion/Promotions';
import News from './news/News';
import Hashtag from './hashtag/Hashtag';
import Foods from './foods';
import WeaningFoods from './weaningFoods';
import Surveys from './surveys';
import {Notification} from './notification';

// documentation pages
import DocsOverview from "./documentation/DocsOverview";
import DocsDownload from "./documentation/DocsDownload";
import DocsQuickStart from "./documentation/DocsQuickStart";
import DocsLicense from "./documentation/DocsLicense";
import DocsFolderStructure from "./documentation/DocsFolderStructure";
import DocsBuild from "./documentation/DocsBuild";
import DocsChangelog from "./documentation/DocsChangelog";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Preloader from "../components/Preloader";

import Accordion from "./components/Accordion";
import Alerts from "./components/Alerts";
import Badges from "./components/Badges";
import Breadcrumbs from "./components/Breadcrumbs";
import Buttons from "./components/Buttons";
import Forms from "./components/Forms";
import Modals from "./components/Modals";
import Navs from "./components/Navs";
import Navbars from "./components/Navbars";
import Pagination from "./components/Pagination";
import Popovers from "./components/Popovers";
import Progress from "./components/Progress";
import Tables from "./components/Tables";
import Tabs from "./components/Tabs";
import Tooltips from "./components/Tooltips";
import Toasts from "./components/Toasts";
import {Customer} from "./customers";
import {CustomerDetail} from "./customer-detail";
import {ActivationCodeBatchCreate} from "./activation-code-batch/ActivationCodeBatchCreate";
import {ActivationCodeBatchList} from "./activation-code-batch";
import {NotificationCreate} from "./notification-create";
import {NotificationDetail} from "./notification-detail";
import {NotificationObject} from "./notification-object";
import {NotificationObjectCreate} from "./notification-object-create";
import {NotificationObjectDetail} from "./notification-object-detail";
import {Chat} from "./chat/index";
import OldChat from './chat/Chats';
import {ActivationCodeBatchDetail} from "./activation-code-batch/ActivationCodeBatchDetail";

const RouteWithLoader = ({component: Component, ...rest}) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Route {...rest} render={props => (<> <Preloader show={loaded ? false : true}/> <Component {...props} /> </>)}/>
    );
};

const RouteWithSidebar = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={props =>
            (
                <>
                    <Sidebar/>
                    <main className="content">
                        <Navbar/>
                        <Component {...props} />
                    </main>
                    <ToastContainer/>
                </>
            )}
        />
    );
};

export default () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(readProfile());
        dispatch(checkExpired());
    }, [])

    const isLogined = useSelector(({global}) => global.isLogined) || localStorage.getItem('isLogged');

    return (
        <React.Fragment>

            {!isLogined ? (
                <Switch>
                    {/* auth */}
                    <RouteWithLoader exact path={Routes.Signin.path} component={Signin}/>
                    <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword}/>
                    <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword}/>
                    <RouteWithLoader exact path={Routes.Lock.path} component={Lock}/>
                    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage}/>
                    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError}/>

                    <Redirect to={Routes.Signin.path}/>
                </Switch>
            ) : (
                <Switch>

                    {/* pages */}
                    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview}/>

                    <RouteWithSidebar exact path={Routes.Users.path} component={Users}/>
                    <RouteWithSidebar exact path={Routes.UserForm.init} component={UserForm}/>
                    <RouteWithSidebar exact path={Routes.UserForm.path} component={UserForm}/>

                    <RouteWithSidebar exact path={Routes.Targets.path} component={Targets}/>
                    <RouteWithSidebar exact path={Routes.Topics.path} component={Topics}/>
                    <RouteWithSidebar exact path={Routes.Posts.path} component={Posts}/>
                    <RouteWithSidebar exact path={Routes.Categories.path} component={Categories}/>
                    <RouteWithSidebar exact path={Routes.Packages.path} component={Packages}/>
                    <RouteWithSidebar exact path={Routes.Promotions.path} component={Promotions}/>
                    <RouteWithSidebar exact path={Routes.News.path} component={News}/>
                    <RouteWithSidebar exact path={Routes.Hashtags.path} component={Hashtag}/>
                    <RouteWithSidebar exact path={Routes.Chats.path} component={Chat}/>
                    <RouteWithSidebar exact path={'/chats/old'} component={OldChat}/>
                    <RouteWithSidebar exact path={Routes.Foods.path} component={Foods}/>
                    <RouteWithSidebar exact path={Routes.WeaningFoods.path} component={WeaningFoods}/>
                    <RouteWithSidebar exact path={Routes.Surveys.path} component={Surveys}/>

                    <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions}/>
                    <RouteWithSidebar exact path={Routes.Settings.path} component={Settings}/>
                    <RouteWithSidebar exact path={Routes.BootstrapTables.path} component={BootstrapTables}/>

                    <RouteWithSidebar exact path={Routes.Customer.path} component={Customer}/>
                    <RouteWithSidebar exact path={Routes.CustomerDetail.path} component={CustomerDetail}/>
                    <RouteWithSidebar exact path={Routes.ActivationCodeBatch.path} component={ActivationCodeBatchList}/>
                    <RouteWithSidebar exact path={Routes.ActivationCodeBatchCreate.path} component={ActivationCodeBatchCreate}/>
                    <RouteWithSidebar exact path={Routes.ActivationCodeBatchDetail.path} component={ActivationCodeBatchDetail}/>

                    <RouteWithSidebar exact path={Routes.NotificationObject.path} component={NotificationObject}/>
                    <RouteWithSidebar exact path={Routes.NotificationObjectCreate.path} component={NotificationObjectCreate}/>
                    <RouteWithSidebar exact path={Routes.NotificationObjectDetail.path} component={NotificationObjectDetail}/>

                    <RouteWithSidebar exact path={Routes.Notification.path} component={Notification}/>
                    <RouteWithSidebar exact path={Routes.NotificationCreate.path} component={NotificationCreate}/>
                    <RouteWithSidebar exact path={Routes.NotificationDetail.path} component={NotificationDetail}/>

                    {/* components */}
                    <RouteWithSidebar exact path={Routes.Accordions.path} component={Accordion}/>
                    <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts}/>
                    <RouteWithSidebar exact path={Routes.Badges.path} component={Badges}/>
                    <RouteWithSidebar exact path={Routes.Breadcrumbs.path} component={Breadcrumbs}/>
                    <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons}/>
                    <RouteWithSidebar exact path={Routes.Forms.path} component={Forms}/>
                    <RouteWithSidebar exact path={Routes.Modals.path} component={Modals}/>
                    <RouteWithSidebar exact path={Routes.Navs.path} component={Navs}/>
                    <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars}/>
                    <RouteWithSidebar exact path={Routes.Pagination.path} component={Pagination}/>
                    <RouteWithSidebar exact path={Routes.Popovers.path} component={Popovers}/>
                    <RouteWithSidebar exact path={Routes.Progress.path} component={Progress}/>
                    <RouteWithSidebar exact path={Routes.Tables.path} component={Tables}/>
                    <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs}/>
                    <RouteWithSidebar exact path={Routes.Tooltips.path} component={Tooltips}/>
                    <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts}/>

                    {/* documentation */}
                    <RouteWithSidebar exact path={Routes.DocsOverview.path} component={DocsOverview}/>
                    <RouteWithSidebar exact path={Routes.DocsDownload.path} component={DocsDownload}/>
                    <RouteWithSidebar exact path={Routes.DocsQuickStart.path} component={DocsQuickStart}/>
                    <RouteWithSidebar exact path={Routes.DocsLicense.path} component={DocsLicense}/>
                    <RouteWithSidebar exact path={Routes.DocsFolderStructure.path} component={DocsFolderStructure}/>
                    <RouteWithSidebar exact path={Routes.DocsBuild.path} component={DocsBuild}/>
                    <RouteWithSidebar exact path={Routes.DocsChangelog.path} component={DocsChangelog}/>

                    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage}/>
                    <Redirect to={Routes.NotFound.path}/>
                </Switch>
            )}

        </React.Fragment>
    );
}
