import React, { Component } from "react";
import Select from "react-select";

class SelectField extends Component {
    handleChange = value => {
        const { onChange, name } = this.props;

        onChange(name, value);
    };

    handleBlur = () => {
        const { onBlur, name } = this.props;

        if (onBlur) onBlur(name, true);
    };

    handleKeyDown = (e, cb) => {
        const { onKeyDown } = this.props;

        if (onKeyDown) onKeyDown(e, cb);
    };

    clear = () => {
        this.selectRef.blur();
        this.selectRef.focus();
    }

    render() {
        const {
            id,
            placeholder,
            options,
            value,
            isMulti,
            isDisabled,
            touched,
            error,
            isClearable,
            backspaceRemovesValue,
            ref
        } = this.props;

        return (
            <React.Fragment>
                <Select
                    ref={ref => this.selectRef = ref}
                    id={id}
                    placeholder={placeholder}
                    options={options}
                    value={value}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    onKeyDown={(e) => this.handleKeyDown(e, this.clear)}
                    touched={touched}
                    error={error}
                    isMulti={isMulti}
                    isDisabled={isDisabled}
                    isClearable={isClearable}
                    backspaceRemovesValue={backspaceRemovesValue}
                    components={{ ClearIndicator: null }}
                />
                <div className="input-error">{touched && error ? <p className="error-text">{error}</p> : null}</div>
            </React.Fragment>
        );
    }
}

export { SelectField };
