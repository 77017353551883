import {Breadcrumb} from "@themesberg/react-bootstrap";
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import lodash from 'lodash';
import React, {useEffect, useState} from "react";
import CodeGeneratorService from "../../services/code-generator.service";
import {Table} from "antd";
import moment from "moment";

const codeGeneratorService = new CodeGeneratorService()

export const ActivationCodeBatchDetail = (props) => {
    const id = props.match.params.id
    const [loading, setLoading] = useState(false)
    const [activationCodes, setActivationCodes] = useState([])
    const [total, setTotal] = useState(0)
    const [pageSize, setPageSize] = useState(25)
    const [currentPage, setCurrentPage] = useState(1)

    const columns = [
        {
            title: "Ngày tạo",
            key: "createdAt",
            render: (row) => moment(row.createdTime).format("DD/MM/YYYY")
        },
        {
            title: "Mã",
            key: "code",
            render: row => row.code
        },
        {
            title: "Loại mã",
            key: "type",
            render: row => row.vipPackage
        },
        {
            title: "Tạo bởi",
            key: "createdBy",
            render: row => row.createdStaff
        },
        {
            title: "Trạng thái",
            key: "status",
            render: row => row.status
        },
        {
            title: "Id người dùng",
            key: "activatedUser",
            render: row => row.activatedUser
        },
    ]

    const handleChangePage = (page, pageSize) => {
        setCurrentPage(page)
        setPageSize(pageSize)
    }

    useEffect(() => {
        setLoading(true)
        codeGeneratorService.getDetailBatchById(id,{
            page: currentPage-1,
            size: pageSize
        })
            .then(response => {
                setLoading(false)
                setPageSize(lodash.get(response.headers, 'x-page-size'))
                setTotal(lodash.get(response.headers, 'x-total-count'))
                setActivationCodes(response.data)
            })
            .catch(() => setLoading(false))
    }, [currentPage, pageSize])

    return (
        <div className="activation-code-create-page">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item to={Routes.DashboardOverview.path}>
                            <FontAwesomeIcon icon={faHome} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active><Link to={Routes.ActivationCodeBatch.path}>Mã kích hoạt</Link></Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Mã kích hoạt</h4>
                    <p className="mb-0">Danh sách mã kích hoạt</p>
                </div>
            </div>

            <Table
                loading={loading}
                columns={columns}
                dataSource={activationCodes}
                rowKey={'id'}
                pagination={{
                    current: currentPage,
                    total: total,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    onChange: handleChangePage,
                    onShowSizeChange: handleChangePage
                }}
            />
        </div>
    )
}
