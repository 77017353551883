import React, {useState} from "react";
import SimpleBar from 'simplebar-react';
import {Link, useLocation} from "react-router-dom";
import {CSSTransition} from 'react-transition-group';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faBeer,
    faBell,
    faBook,
    faBoxOpen,
    faBullseye,
    faChartPie,
    faCloudMeatball,
    faCode,
    faCog,
    faComments,
    faCubes,
    faFileAlt,
    faFish,
    faFistRaised,
    faHamburger,
    faHandHoldingUsd,
    faIdCard,
    faListAlt,
    faNewspaper,
    faPercent,
    faSignOutAlt,
    faTable,
    faTimes,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import {Accordion, Badge, Button, Dropdown, Image, Nav, Navbar} from '@themesberg/react-bootstrap';

import {Routes} from "../routes";
import ThemesbergLogo from "../assets/img/themesberg.svg";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";

export default (props = {}) => {
    const location = useLocation();
    const { pathname } = location;
    const [show, setShow] = useState(false);
    const showClass = show ? "show" : "";

    const onCollapse = () => setShow(!show);

    const CollapsableNavItem = (props) => {
        const { eventKey, title, icon, children = null } = props;
        const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

        return (
            <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
                <Accordion.Item eventKey={eventKey}>
                    <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text">{title}</span>
            </span>
                    </Accordion.Button>
                    <Accordion.Body className="multi-level">
                        <Nav className="flex-column">
                            {children}
                        </Nav>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        );
    };

    const NavItem = (props) => {
        const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
        const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
        const navItemClassName = link === pathname ? "active" : "";
        const linkProps = external ? { href: link } : { as: Link, to: link };

        return (
            <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
                <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
              {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

              <span className="sidebar-text">{title}</span>
          </span>
                    {badgeText ? (
                        <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
                    ) : null}
                </Nav.Link>
            </Nav.Item>
        );
    };

    return (
        <>
            <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
                <Navbar.Brand className="me-lg-5" as={Link} to={Routes.DashboardOverview.path}>
                    <Image src={ReactHero} className="navbar-brand-light" />
                </Navbar.Brand>
                <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
                    <span className="navbar-toggler-icon" />
                </Navbar.Toggle>
            </Navbar>
            <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
                <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
                    <div className="sidebar-inner px-4 pt-3">
                        <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
                            <div className="d-flex align-items-center">
                                <div className="user-avatar lg-avatar me-4">
                                    <Image src={ProfilePicture} className="card-img-top rounded-circle border-white" />
                                </div>
                                <div className="d-block">
                                    <h6>Hi, Jane</h6>
                                    <Button as={Link} variant="secondary" size="xs" to={Routes.Signin.path} className="text-dark">
                                        <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                                    </Button>
                                </div>
                            </div>
                            <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                                <FontAwesomeIcon icon={faTimes} />
                            </Nav.Link>
                        </div>
                        <Nav className="flex-column pt-3 pt-md-0">

                            <NavItem title="Tổng quan" link={Routes.DashboardOverview.path} icon={faChartPie} />
                            <NavItem title="Quản trị viên" icon={faUsers} link={Routes.Users.path} />
                            <NavItem title="Khách hàng" icon={faIdCard} link={Routes.Customer.path} />
                            <NavItem title="Mục tiêu" icon={faBullseye} link={Routes.Targets.path} />
                            <NavItem title="Chuyên mục" icon={faListAlt} link={Routes.Categories.path} />
                            <NavItem title="Chủ đề" icon={faHamburger} link={Routes.Topics.path} />
                            <NavItem title="Bài viết" icon={faBook} link={Routes.Posts.path} />
                            <NavItem title="Hashtag" icon={faCubes} link={Routes.Hashtags.path} />
                            <NavItem title="Tin tức" icon={faNewspaper} link={Routes.News.path} />
                            <NavItem title="Khuyến mại" icon={faPercent} link={Routes.Promotions.path} />
                            <NavItem title="Gói cước" icon={faCubes} link={Routes.Packages.path} />
                            <NavItem title="Chats" icon={faComments} link={Routes.Chats.path} />
                            <NavItem title="Sổ tay dinh dưỡng" icon={faFish} link={Routes.Foods.path} />
                            <NavItem title="Sổ tay ăn dặm" icon={faFistRaised} link={Routes.WeaningFoods.path} />
                            <NavItem title="Surveys" icon={faCloudMeatball} link={Routes.Surveys.path} />
                            <NavItem title="Mã kích hoạt" icon={faCode} link={Routes.ActivationCodeBatch.path} />
                            <CollapsableNavItem eventKey="notifications" title="Thông báo" icon={faBell}>
                                <NavItem title="Đối tượng" icon={faBeer} link={Routes.NotificationObject.path} />
                                <NavItem title="Thông báo" icon={faBell} link={Routes.Notification.path} />
                            </CollapsableNavItem>

                            <Dropdown.Divider className="my-3 border-indigo" />

                            <NavItem title="Transactions" icon={faHandHoldingUsd} link={Routes.Transactions.path} />
                            <NavItem title="Settings" icon={faCog} link={Routes.Settings.path} />

                            <CollapsableNavItem eventKey="tables/" title="Tables" icon={faTable}>
                                <NavItem title="Bootstrap Table" link={Routes.BootstrapTables.path} />
                            </CollapsableNavItem>

                            <CollapsableNavItem eventKey="examples/" title="Page Examples" icon={faFileAlt}>
                                <NavItem title="Sign In" link={Routes.Signin.path} />
                                <NavItem title="Sign Up" link={Routes.Signup.path} />
                                <NavItem title="Forgot password" link={Routes.ForgotPassword.path} />
                                <NavItem title="Reset password" link={Routes.ResetPassword.path} />
                                <NavItem title="Lock" link={Routes.Lock.path} />
                                <NavItem title="404 Not Found" link={Routes.NotFound.path} />
                                <NavItem title="500 Server Error" link={Routes.ServerError.path} />
                            </CollapsableNavItem>

                            <NavItem external title="Plugins" link="https://demo.themesberg.com/volt-pro-react/#/plugins/charts" target="_blank" badgeText="Pro" icon={faChartPie} />

                            <Dropdown.Divider className="my-3 border-indigo" />

                            <CollapsableNavItem eventKey="documentation/" title="Getting Started" icon={faBook}>
                                <NavItem title="Overview" link={Routes.DocsOverview.path} />
                                <NavItem title="Download" link={Routes.DocsDownload.path} />
                                <NavItem title="Quick Start" link={Routes.DocsQuickStart.path} />
                                <NavItem title="License" link={Routes.DocsLicense.path} />
                                <NavItem title="Folder Structure" link={Routes.DocsFolderStructure.path} />
                                <NavItem title="Build Tools" link={Routes.DocsBuild.path} />
                                <NavItem title="Changelog" link={Routes.DocsChangelog.path} />
                            </CollapsableNavItem>
                            <CollapsableNavItem eventKey="components/" title="Components" icon={faBoxOpen}>
                                <NavItem title="Accordion" link={Routes.Accordions.path} />
                                <NavItem title="Alerts" link={Routes.Alerts.path} />
                                <NavItem title="Badges" link={Routes.Badges.path} />
                                <NavItem external title="Widgets" link="https://demo.themesberg.com/volt-pro-react/#/components/widgets" target="_blank" badgeText="Pro" />
                                <NavItem title="Breadcrumbs" link={Routes.Breadcrumbs.path} />
                                <NavItem title="Buttons" link={Routes.Buttons.path} />
                                <NavItem title="Forms" link={Routes.Forms.path} />
                                <NavItem title="Modals" link={Routes.Modals.path} />
                                <NavItem title="Navbars" link={Routes.Navbars.path} />
                                <NavItem title="Navs" link={Routes.Navs.path} />
                                <NavItem title="Pagination" link={Routes.Pagination.path} />
                                <NavItem title="Popovers" link={Routes.Popovers.path} />
                                <NavItem title="Progress" link={Routes.Progress.path} />
                                <NavItem title="Tables" link={Routes.Tables.path} />
                                <NavItem title="Tabs" link={Routes.Tabs.path} />
                                <NavItem title="Toasts" link={Routes.Toasts.path} />
                                <NavItem title="Tooltips" link={Routes.Tooltips.path} />
                            </CollapsableNavItem>
                            <NavItem external title="Themesberg" link="https://themesberg.com" target="_blank" image={ThemesbergLogo} />

                        </Nav>
                    </div>
                </SimpleBar>
            </CSSTransition>
        </>
    );
};
