import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Modal } from '@themesberg/react-bootstrap';

import { Field, Formik } from "formik";
import * as Yup from 'yup';

import { ERROR_MESSAGE } from '../../contains/errors';
import { onCreate, onUpdate, hideModal } from "../../store/hashtag";

import Editor from "../../components/Editor";

export default () => {

    const dispatch = useDispatch();
    const isOpenModal = useSelector(({ hashtag }) => hashtag.isOpenModal);
    const activeItem = useSelector(({ hashtag }) => hashtag.item);

    const closeModal = () => {
        dispatch(hideModal());
    }

    const onValidate = Yup.object().shape({
        hashtagName: Yup.string()
            .required(ERROR_MESSAGE.HASHTAG_NAME_REQUIRED),
        hashtagContent: Yup.string()
            .required(ERROR_MESSAGE.HASHTAG_CONTENT_REQUIRED)
    });

    const onSubmit = async (values) => {

        let dataPost = {
            "hashtagId": values.hashtagId,
            "hashtagName": values.hashtagName,
            "hashtagContent": values.hashtagContent,
        };

        if (activeItem.hashtagId > 0) {
            await dispatch(onUpdate(dataPost));
        } else {
            await dispatch(onCreate(dataPost));
        }
    }

    return (
        <Modal size="xl" as={Modal.Dialog} centered show={isOpenModal} onHide={closeModal}>
            <Formik initialValues={activeItem} onSubmit={onSubmit} validationSchema={onValidate}>
                {props => (
                    <Form onSubmit={props.handleSubmit}>
                        <Modal.Header>
                            <Modal.Title className="h6">Tin tức</Modal.Title>
                            <Button variant="close" aria-label="Close" onClick={closeModal} />
                        </Modal.Header>
                        <Modal.Body>

                            <Form.Group className="mb-3">
                                <Form.Label>Tên hashtag</Form.Label>
                                <Form.Control type="text" placeholder="Nhập tên hashtag" name="hashtagName" isInvalid={props.errors.hashtagName && props.touched.hashtagName} onChange={props.handleChange} value={props.values.hashtagName} />
                                <Form.Control.Feedback type="invalid">{props.errors.hashtagName}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Nội dung</Form.Label>
                                <Field name="hashtagContent">
                                    {({ field }) => <Editor className={(props.errors.hashtagContent && props.touched.hashtagContent && `editor-errors`)} value={field.value} onChange={field.onChange(field.name)} />}
                                </Field>
                                {props.errors.hashtagContent && props.touched.hashtagContent && <div className="input-error">{props.errors.hashtagContent}</div>}
                            </Form.Group>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" className="text-gray ms-auto" type="submit">
                                {activeItem.hashtagId > 0 ? `Cập nhật` : `Tạo hashtag`}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}