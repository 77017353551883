import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Modal } from '@themesberg/react-bootstrap';

import { Formik } from "formik";
import * as Yup from 'yup';

import { ERROR_MESSAGE } from '../../contains/errors';
import { fetchAll } from "../../store/target";
import { onCreate, onUpdate, hideModal } from "../../store/category";

export default () => {

    const dispatch = useDispatch();
    const isOpenModal = useSelector(({ category }) => category.isOpenModal);
    const activeItem = useSelector(({ category }) => category.item);
    const listTargets = useSelector(({ targets }) => targets.table.items);

    useEffect(() => {
        (async () => {
            await dispatch(fetchAll())
        })();
    }, [])

    const closeModal = () => {
        dispatch(hideModal());
    }

    const onValidate = Yup.object().shape({
        categoryName: Yup.string()
            .required(ERROR_MESSAGE.CATEGORY_NAME_REQUIRED),
        preFix: Yup.string()
            .required(ERROR_MESSAGE.PREFIX_REQUIRED),
        targetId: Yup.string()
            .required(ERROR_MESSAGE.TARGET_OPTION_REQUIRED)
    });

    const onSubmit = async (values) => {

        let postData = {
            'categoryId': values.categoryId,
            'preFix': values.preFix,
            'categoryName': values.categoryName,
            'categoryContent': values.categoryContent,
            'order': values.order,
            'targetId': values.targetId,
        }

        if (activeItem.categoryId > 0) {
            await dispatch(onUpdate(postData));
        } else {
            await dispatch(onCreate(postData));
        }
    }

    return (
        <Modal as={Modal.Dialog} centered show={isOpenModal} onHide={closeModal}>
            <Formik initialValues={activeItem} onSubmit={onSubmit} validationSchema={onValidate}>
                {props => (
                    <Form onSubmit={props.handleSubmit}>
                        <Modal.Header>
                            <Modal.Title className="h6">Chuyên mục</Modal.Title>
                            <Button variant="close" aria-label="Close" onClick={closeModal} />
                        </Modal.Header>
                        <Modal.Body>


                            <Form.Group className="mb-3">
                                <Form.Label>Tên chuyên mục</Form.Label>
                                <Form.Control type="text" placeholder="Nhập tên chuyên mục" name="categoryName" isInvalid={props.errors.categoryName && props.touched.categoryName} onChange={props.handleChange} value={props.values.categoryName} />
                                <Form.Control.Feedback type="invalid">{props.errors.categoryName}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Prefix chuyên mục</Form.Label>
                                <Form.Control type="text" placeholder="Nhập prefix chuyên mục" name="preFix" isInvalid={props.errors.preFix && props.touched.preFix} onChange={props.handleChange} value={props.values.preFix} />
                                <Form.Control.Feedback type="invalid">{props.errors.preFix}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Mục tiêu</Form.Label>
                                <Form.Select name="targetId" isInvalid={props.errors.targetId && props.touched.targetId} onChange={props.handleChange} value={props?.values?.targetId} >
                                    <option value=''>-- Chọn mục tiêu --</option>
                                    {listTargets.map((target) => <option key={`target-${target.targetId}`} value={(target.targetId)} label={target.targetName} />)}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{props.errors.targetId}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Nội dung</Form.Label>
                                <Form.Control as="textarea" rows="3" name="categoryContent" isInvalid={props.errors.categoryContent && props.touched.categoryContent} onChange={props.handleChange} value={props.values.categoryContent} />
                            </Form.Group>


                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" className="text-gray ms-auto" type="submit">
                                {activeItem.categoryId > 0 ? `Cập nhật` : `Tạo chuyên mục`}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}