import {createSlice} from '@reduxjs/toolkit';
import StoreService from '../../services/store.services';

const namespace = 'global';

const store = new StoreService();

const GLOBAL_STATE = {
    loading: false,
    isLogined: false,
    profile: {},
    toast: {
        isHidden: true,
        type: 'done',
        message: 'This is message default',
        deplay: 3000
    },
    error: {}
};

const globalSlice = createSlice({
    name: namespace,
    initialState: GLOBAL_STATE,
    reducers: {
        checkExpired() {
            store.checkExpired();
        },
        readProfile(state) {
            state.profile = store.profile;
            state.isLogined = store.isLogined;
            localStorage.setItem('isLogged', store.isLogined)
        },
        clearProfile(state) {
            store.clearData();
            state.profile = store.profile;
            state.isLogined = store.isLogined;
        },
        showToast(state, { payload }) {
            let toast;
            if (Array.isArray(payload)) {
                const [type, message] = payload;
                toast = {
                    type,
                    message,
                    deplay: 3000
                };
            } else {
                toast = { ...state.toast, ...payload };
            }
            state.toast = toast;
            state.toast.isHidden = false;
        },
        hideToast(state, { payload }) {
            state.toast = { ...state.toast, payload };
            state.toast.isHidden = true;
        }
    },
    extraReducers: {
    }
});

export const withToast = (payloadCreator) => {
    return async (args, { dispatch }) => {
        try {
            return await payloadCreator(args, dispatch);
        } catch (error) {
            dispatch(showToast([`error`, error.message]));
            throw error;
        }
    }
}

export const { readProfile, clearProfile, showToast, hideToast, checkExpired } = globalSlice.actions;
export default globalSlice.reducer;
