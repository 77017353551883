export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

export const API_URL = process.env.REACT_APP_API_URL;
export const CHAT_API_URL = process.env.REACT_APP_CHAT_API_URL;
export const CHAT_IMG_URL = process.env.REACT_APP_CHAT_IMG_URL;
export const CHAT_SOCKET_URL = process.env.REACT_APP_CHAT_SOCKET_URL;
export const ROOM_SOCKET_URL = process.env.REACT_APP_ROOM_SOCKET_URL;


export const APP_SOCIAL = {
    FACEBOOK: '291501109066329',
    GOOGLE: '358685833274-en0pu0iltpl7ubuheoa6o5etvttig0a5.apps.googleusercontent.com'
}

export const BASIC_TOKEN = `QmFiaXVuaTpCYWJpdW5p`;

export const LIST_API_NOT_REQUIRE_AUTH = [
    'account-managers/login'
];

export const FORMAT_DATE_NORMAL = 'DD/MM/YYYY';

export const PAGE_SIZE_LIST = [10, 20, 30];

export const LOGIN_TYPE = {
    USERNAME: 1,
    EMAIL_OR_PHONE: 2
}

export const SEARCH_PARAMS = {
    keyword: '',
    page: 0,
    size: 10,
    properties: 'userId',
    sortBy: 'asc'
};

export const POST_TYPE = [`FREE`, `VIP`];
export const POST_FORMAT = [
    {
        id: 1,
        name: 'Bài Viết'
    },
    {
        id: 2,
        name: 'Video/Audio'
    },
    {
        id: 3,
        name: 'Info Graphic'
    },
];

export const DEVICE_FORMAT = [
    {
        id: 0,
        name: 'App'
    },
    {
        id: 1,
        name: 'Web Babiuni'
    },
    {
        id: 2,
        name: 'All'
    },
];


export const FOOD_ENUM = [
    {
        id: 'recommend',
        name: 'Nên ăn'
    },
    {
        id: 'warning',
        name: 'Chú ý'
    },
    {
        id: 'disadvise',
        name: 'Không nên'
    },
];

export const SURVEY_DISPLAY_TYPE = [
    {
        id: 'popup',
        name: 'Show popup/Chat'
    },
    {
        id: 'insight',
        name: 'Hiển thị ngay trên Insights/Home'
    },
];

export const SURVEY_QUESTION_TYPE = [
    {
        id: 'choice',
        name: 'Chọn'
    },
    {
        id: 'date',
        name: 'Ngày tháng'
    },
    {
        id: 'text',
        name: 'Trả lời'
    },
    {
        id: 'confirm',
        name: 'Xác nhận'
    },
];

export const CUSTOMER_CODE_FLOW = [
    {
        code: "mongcon",
        name: "Mong con"
    },
    {
        code: "mangthai",
        name: "Mang thai"
    },
    {
        code: "sausinh",
        name: "Sau sinh"
    }
]

export const getCustomerCodeFlow = (code) => {
    return CUSTOMER_CODE_FLOW.find(item => item.code === code)
}

export const PORTRAIT_ORIENTATION_LANDSCAPE = "landscape"
export const PORTRAIT_ORIENTATION_PORTRAIT = "portrait"
export const PORTRAIT_ORIENTATION = [
    {
        code: PORTRAIT_ORIENTATION_LANDSCAPE,
        name: "Chiều ngang"
    },
    {
        code: PORTRAIT_ORIENTATION_PORTRAIT,
        name: "Chiều dọc"
    }
]

export const defaultCustomerAvatar = require('../assets/img/default-avatar.webp')
