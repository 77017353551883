import {Breadcrumb} from "@themesberg/react-bootstrap";
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import lodash from 'lodash';
import React, {useEffect, useState} from "react";
import CodeGeneratorService from "../../services/code-generator.service";
import {Button, Table} from "antd";
import moment from "moment";

const codeGeneratorService = new CodeGeneratorService()

export const ActivationCodeBatchList = () => {
    const [loading, setLoading] = useState(false)
    const [activationItems, setActivationItems] = useState([])
    const [total, setTotal] = useState(0)
    const [pageSize, setPageSize] = useState(25)
    const [currentPage, setCurrentPage] = useState(1)

    const columns = [
        {
            title: "Ngày tạo",
            key: "createdAt",
            render: (row) => moment(row.createdTime).format("DD/MM/YYYY")
        },
        {
            title: "Số lượng",
            key: "quantity",
            render: row => row.quantity
        },
        {
            title: "Loại mã",
            key: "type",
            render: row => row.vipPackage
        },
        {
            title: "Tạo bởi",
            key: "createdBy",
            render: row => row.createdStaff
        },
        {
            title: "Số lượng còn lại",
            key: "remainQuantity",
            render: row => row.remainingQuantity
        },
        {
            title: "",
            key: "detail",
            render: (row) => <Link to={Routes.ActivationCodeBatchDetail.path.replace(':id', row.id)}>Chi tiết</Link>
        }
    ]

    const handleChangePage = (page, pageSize) => {
        setCurrentPage(page)
        setPageSize(pageSize)
    }

    useEffect(() => {
        setLoading(true)
        codeGeneratorService.getList({
            page: currentPage-1,
            size: pageSize
        })
            .then(response => {
                setLoading(false)
                setPageSize(lodash.get(response.headers, 'x-page-size'))
                setTotal(lodash.get(response.headers, 'x-total-count'))
                setActivationItems(response.data)
            })
            .catch(() => setLoading(false))
    }, [currentPage, pageSize])

    return (
        <div className="activation-code-create-page">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item to={Routes.DashboardOverview.path}>
                            <FontAwesomeIcon icon={faHome} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active><Link to={Routes.ActivationCodeBatch.path}>Mã kích hoạt</Link></Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Mã kích hoạt</h4>
                    <p className="mb-0">Danh sách tạo mã kích hoạt</p>
                </div>
            </div>

            <Button type={'primary'} className={'mb-4'}><Link to={Routes.ActivationCodeBatchCreate.path}>Tạo mã</Link></Button>

            <Table
                loading={loading}
                columns={columns}
                dataSource={activationItems}
                rowKey={'id'}
                pagination={{
                    current: currentPage,
                    total: total,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    onChange: handleChangePage,
                    onShowSizeChange: handleChangePage
                }}
            />
        </div>
    )
}
