import APIService from './api.services';

class FileService extends APIService {

    constructor() {
        super();
    }

    async uploadImage(file, body) {
        const bodyFormData = new FormData();
        bodyFormData.append('file ', file);
        if (body) {
            for (let p in body) {
                if (body[p]) {
                    bodyFormData.append(p, body[p]);
                }
            }
        }
        return await this.request('POST', 'files/uploadFile', bodyFormData, true);
    }
}

export default FileService;