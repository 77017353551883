import React from 'react'
import {Col, Form, InputGroup, Row} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {submitSearch} from "../../store/customer";

export const Filter = () => {
    const dispatch = useDispatch()
    return (
        <Row className="justify-content-between align-items-center mb-4">
            <Col xs={12}>
                <InputGroup>
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Form.Control
                        type="text"
                        placeholder="Tên KH, SĐT, Email..."
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                dispatch(submitSearch(e.target.value))
                            }
                        }}
                    />
                </InputGroup>
            </Col>
        </Row>
    )
}
