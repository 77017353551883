import APIService from './api.services';

class PostService extends APIService {

    constructor() {
        super();
        this.modulePath = 'posts';
    }

    getDetailById(id) {
        return this.makeRequest('GET', `/posts/${id}`)
    }
}

export const postService = new PostService()

export default PostService;
