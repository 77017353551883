import { STORE_KEY } from "../contains/store.keys";

class StoreService {

    setData(key, data) {
        if (data === null) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, data);
        }
    }

    getData(key) {
        return localStorage.getItem(key) || '';
    }

    clearData() {
        this.accessToken = null;
        this.profile = null;
    }

    // Return true: continue, false: logout
    checkExpired() {
        const expire = this.profile && this.profile.expiredResourcesDate < Date.now();
        if (expire) {
            this.clearData();
        }
    }

    get isLogined() {
        return this.accessToken && this.profile && this.profile.userId;
    }

    // Access Token
    get accessToken() {
        return this.getData(STORE_KEY.ACCESS_TOKEN);
    }

    set accessToken(token) {
        this.setData(STORE_KEY.ACCESS_TOKEN, token);
    }

    // Profile
    get profile() {
        try {
            const user = this.getData(STORE_KEY.USER_INFO);
            return JSON.parse(user);
        } catch (error) {
            return {};
        }
    }

    set profile(user) {
        this.setData(STORE_KEY.USER_INFO, JSON.stringify(user));
    }

}

export default StoreService;