import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import TopicService from "../../services/topic.service";

import { SEARCH_PARAMS } from '../../contains/common';
import { showToast, withToast } from '../global';

const topicService = new TopicService();
const namespace = 'topics';

const STATE = {
    loading: false,
    item: {
        'file': '',
        'topicId': '',
        'categoryId': '',
        'topicName': '',
        'preFix': '',
        'topicContent': '',
        'order': '',
        'topicAvatar': '',
        'targetId': ''
    },
    searchParams: { ...SEARCH_PARAMS, targetIds: [], categoryIds: [], properties: 'createdDate' },
    isOpenModal: false,
    table: {
        items: [],
        isCheckedAll: false,
        numberOfElements: 0,
        totalElements: 0,
        totalPages: 0,
        number: 0,
    },
    success: {},
    error: {}
};

// actions
export const search = createAsyncThunk(
    `${namespace}/search`,
    async (searchParams) => {
        const { body } = await topicService.search({ ...STATE.searchParams, ...searchParams });
        return body;
    }
);

export const fetchAll = createAsyncThunk(
    `${namespace}/fetchAll`,
    async (params) => {
        const { body } = await topicService.readAll(params);
        return body;
    }
);

export const fetchById = createAsyncThunk(
    `${namespace}/fetchById`,
    async (id) => {
        return await topicService.readOne(id);
    }
);

export const onCreate = createAsyncThunk(
    `${namespace}/create`,
    withToast(
        async (objectDTO, dispatch) => {
            const resp = await topicService.create(objectDTO);
            dispatch(showToast([`done`, `Tạo chủ đề thành công`]));
            return resp;
        }
    )
)

export const onUpdate = createAsyncThunk(
    `${namespace}/update`,
    withToast(
        async (objectDTO, dispatch) => {
            const resp = await topicService.update(objectDTO);
            dispatch(showToast([`done`, `Cập nhật chủ đề thành công`]));
            return resp;
        }
    )
)

export const onDelete = createAsyncThunk(
    `${namespace}/delete`,
    withToast(
        async (objectDTO, dispatch) => {
            const resp = await topicService.delete(objectDTO);
            dispatch(showToast([`done`, `Xóa chủ đề thành công`]));
            return resp;
        }
    )
)

const objectSlice = createSlice({
    name: namespace,
    initialState: STATE,
    reducers: {
        setActiveItem(state, { payload }) {
            if (!payload) {
                state.item = STATE.item;
            } else {
                const targetId = payload?.targetBasic?.targetId || payload?.targetId;
                const categoryId = payload?.categoryBasic?.categoryId || payload?.categoryId;

                delete state.item?.categoryBasic;
                delete state.item?.targetBasic;

                state.item = {
                    ...payload,
                    targetId,
                    categoryId
                };
            }
        },
        setKeyword(state, { payload }) {
            state.searchParams = { ...state.searchParams, keyword: payload }
        },
        openModal(state, { payload }) {
            state.isOpenModal = true;
        },
        hideModal(state, { payload }) {
            state.isOpenModal = false;
        },
        checkAll(state, { payload }) {
            state.table.isCheckedAll = payload;
            state.table.items.map(x => x.isChecked = payload);
        },
        checkItem(state, { payload }) {
            state.table.items = state.table.items.map(x => {
                if (x.topicId === payload.topicId) {
                    x.isChecked = !x.isChecked;
                }
                return x;
            });
            let haveItemFalse = state.table.items.find(x => x.isChecked === false);
            if (haveItemFalse) {
                state.table.isCheckedAll = false;
            } else {
                state.table.isCheckedAll = true;
            }
        },
        onChoicePageSize(state, { payload }) {
            state.searchParams.size = payload;
            state.searchParams.page = 0;
            state.table.isCheckedAll = false;
        },
        onChangePage(state, { payload }) {
            if (payload < 0 || payload >= state.table.totalPages) {
                return;
            }
            state.searchParams.page = payload;
            state.table.isCheckedAll = false;
        }
    },
    extraReducers: {
        // search
        [search.pending](state) {
            state.loading = true
        },
        [search.fulfilled](state, { payload }) {
            state.loading = false;
            if (payload && payload.content) {
                state.table.items = payload.content.map(x => {
                    return { ...STATE.item, ...x };
                });
                state.table.numberOfElements = payload.numberOfElements;
                state.table.totalElements = payload.totalElements;
                state.table.totalPages = payload.totalPages;
                state.table.number = payload.number;
            }
        },
        [search.rejected](state, { error }) {
            state.loading = false
            state.error = error;
        },
        // find all
        [fetchAll.pending](state) {
            state.loading = true
        },
        [fetchAll.fulfilled](state, { payload }) {
            state.loading = false;
            if (payload) {
                state.table.items = payload;
            }
        },
        [fetchAll.rejected](state, { error }) {
            state.loading = false
            state.error = error;
        },
        // find one
        [fetchById.pending](state) {
            state.loading = true
        },
        [fetchById.fulfilled](state, { payload }) {
            state.loading = false;
            objectSlice.caseReducers.setActiveItem(state, { payload: payload.body });
        },
        [fetchById.rejected](state, { error }) {
            state.loading = false
            state.error = error;
        },
        // create
        [onCreate.pending](state) {
            state.loading = true
        },
        [onCreate.fulfilled](state, { payload }) {
            state.loading = false;
            state.item = STATE.item;
            state.table.items.unshift(payload.body);
            state.isOpenModal = false;
        },
        [onCreate.rejected](state, { error }) {
            state.loading = false
            state.error = error;
        },
        // update
        [onUpdate.pending](state) {
            state.loading = true
        },
        [onUpdate.fulfilled](state, { payload }) {
            state.loading = false;
            state.item = STATE.item;
            state.table.items = state.table.items.map(x => {
                if (x.topicId === payload.body.topicId) {
                    x = { ...STATE.item, ...payload.body };
                }
                return x;
            });
            state.isOpenModal = false;
        },
        [onUpdate.rejected](state, { error }) {
            state.loading = false
            state.error = error;
        },
        // delete
        [onDelete.pending](state) {
            state.loading = true
        },
        [onDelete.fulfilled](state) {
            state.loading = false;
            state.table.items = state.table.items.filter(x => x.topicId !== state.item.topicId);
            state.item = STATE.item;
            state.isOpenModal = false;
        },
        [onDelete.rejected](state, { error }) {
            state.loading = false
            state.error = error;
        }
    }
});

export const { setActiveItem, setKeyword, openModal, hideModal, checkAll, checkItem, onChoicePageSize, onChangePage } = objectSlice.actions;
export default objectSlice.reducer;