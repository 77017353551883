import React, {useEffect} from 'react'
import {Breadcrumb} from "@themesberg/react-bootstrap";
import lodash from 'lodash';
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {Filter} from "./Filter";
import {List} from "./List";
import {useDispatch, useSelector} from "react-redux";
import {onChangePage, search} from "../../store/customer";

export const Customer = () => {
    const dispatch = useDispatch()
    const data = useSelector(({customer}) => customer.table)
    const searchParams = useSelector(({customer}) => customer.searchParams)

    useEffect(() => {
        let filterCustomer = () => dispatch(search(searchParams))
        filterCustomer = lodash.debounce(filterCustomer, 300)
        filterCustomer()
    }, [dispatch, searchParams])

    return (
        <React.Fragment>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item to={Routes.DashboardOverview.path}>
                            <FontAwesomeIcon icon={faHome} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Khách hàng</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Khách hàng</h4>
                    <p className="mb-0">Danh sách khách hàng.</p>
                </div>
            </div>

            <Filter />
            <List
                data={data}
                searchParams={searchParams}
                onChangePage={onChangePage}
            />
        </React.Fragment>
    )
}
