import APIService from './api.services';

class NotificationObjectService extends APIService {

    constructor() {
        super();
        this.modulePath = 'category';
    }

    create(data) {
        return this.makeRequest('POST', `customer-group-definition`, data)
    }

    getDetail(id) {
        return this.makeRequest('GET', `customer-group-definition/${id}`)
    }

    update(id, data) {
        return this.makeRequest('PATCH', `customer-group-definition/${id}`, data)
    }

    remove(id) {
        return this.makeRequest('DELETE', `customer-group-definition/${id}`)
    }

    filter(params = {}) {
        return this.makeRequest('GET', 'customer-group-definition', {params})
    }
}

export const notificationObjectService = new NotificationObjectService()
export default NotificationObjectService;
