import APIService from './api.services';

class CategoryService extends APIService {

    constructor() {
        super();
        this.modulePath = 'category';
    }

    getVipPackages() {
        return this.makeRequest('GET', `categories/vip-packages`)
    }

    getCustomerGroupCondition() {
        return this.makeRequest('GET', `categories/customer_group_condition`)
    }

    getSubGoal(filter = {}) {
        return this.makeRequest('GET', `categories/sub_goal`, {params: filter})
    }
}

export const categoryService = new CategoryService()

export default CategoryService;
