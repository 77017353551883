import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';

import { Routes } from "../../routes";

import { NewsTable } from "./NewsTable";
import NewsForm from "./NewsForm";

import { PAGE_SIZE_LIST } from '../../contains/common';
import { openModal, onChoicePageSize, setKeyword, setActiveItem } from "../../store/news";

export default () => {

  const dispatch = useDispatch();
  const searchParams = useSelector(({ news }) => news.searchParams);

  const addItem = () => {
    dispatch(setActiveItem());
    dispatch(openModal());
  };

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item to={Routes.DashboardOverview.path}><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>Tin tức</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Tin tức</h4>
          <p className="mb-0">Quản lý tin tức</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Button onClick={e => addItem()} variant="primary" size="sm" className="me-1">
            <FontAwesomeIcon icon={faPlus} className="me-2" /> Thêm tin tức
          </Button>
          <ButtonGroup className="ms-3">
            <Button variant="outline-primary" size="sm">Chia sẻ</Button>
            <Button variant="outline-primary" size="sm">Xuất dữ liệu</Button>
          </ButtonGroup>
        </div>
      </div>

      {/* page size */}
      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" onChange={e => dispatch(setKeyword(e.target.value))} />
            </InputGroup>
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                {PAGE_SIZE_LIST.map((size) => (
                  <Dropdown.Item key={`page-size-${size}`} onClick={e => dispatch(onChoicePageSize(size))} className="d-flex fw-bold">
                    {size} {searchParams.size === size ? (<span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>) : ''}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <NewsTable />
      <NewsForm />

    </React.Fragment>
  );
};
