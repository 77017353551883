import APIService from './customAPI.services';

class CampaignService extends APIService {

    constructor() {
        super();
    }

    async listCampaign(query) {
        return await this.request('GET', `posts/list-url-campaign${query || ''}`, {});
    }

    async createCampaign(body) {
        return await this.request('POST', `posts/create-url-campaign`, body);
    }

    async removeCampaign(body) {
        return await this.request('POST', `posts/remove-url-campaign`, body);
    }

    async getListChat(obj) {
        return await this.request('GET', `chat?page=${obj.pageNumber}&size=${obj.pageSize}`, {});
    }
}
const campaignService = new CampaignService();
export default campaignService
