import lodash from 'lodash';

export const NOTIFICATION_OBJECT_TYPE = {
    SYSTEM: {code: "system", name: "Hệ thống"},
    OBJECT: {code: "oriented", name: "Hướng đối tượng"}
}

export const NOTIFICATION_OBJECT_ACCOUNT_TYPE = {
    ALL: {code: "all", name: "Cả hai"},
    STANDARD: {code: "standard", name: "Standard"},
    PREMIUM: {code: "premium", name: "Premium"}
}

export const NOTIFICATION_OBJECT_CONDITION = {
    all: [
        {code: "new", name: "Mới cài đặt app"},
        {code: "inactive", name: "Không hoạt động"}
    ],

    standard: [
        {code: "expired", name: "Hết hạn dùng thử"},
        {code: "trial_expire_soon", name: "Sắp hết hạn dùng thử"}
    ],

    premium: [
        {code: "cancel_subscription", name: "Huỷ đăng ký"}
    ]
}

export const NOTIFICATION_OBJECT_TARGET = {
    MANGTHAI: {code: "mangthai", name: "Theo dõi thai kỳ"},
    MONGCON: {code: "mongcon", name: "Mong có con"},
    SAUSINH: {code: "sausinh", name: "Nuôi con nhỏ"},
}

export const NOTIFICATION_OBJECT_PHASE = {
    nth3month: {code: "trimester", name: "Tam cá nguyệt thứ"},
    nthWeek: {code: "week", name: "Tuần thứ"},
    nthMonth: {code: "month", name: "Tháng thứ"},
    postnatal: {code: "postnatal", name: "Sau sinh"}
}

export const NOTIFICATION_OBJECT_PHASE_ARR = lodash.map(NOTIFICATION_OBJECT_PHASE, item => item)
export const NOTIFICATION_OBJECT_TARGET_ARR = lodash.map(NOTIFICATION_OBJECT_TARGET, item => item)
export const NOTIFICATION_OBJECT_TYPE_ARR = lodash.map(NOTIFICATION_OBJECT_TYPE, item => item)
export const NOTIFICATION_OBJECT_ACCOUNT_TYPE_ARR = lodash.map(NOTIFICATION_OBJECT_ACCOUNT_TYPE, item => item)

export const getNotificationObjectClassification = (classification) => {
    switch (classification) {
        case NOTIFICATION_OBJECT_TYPE.SYSTEM.code:
            return NOTIFICATION_OBJECT_TYPE.SYSTEM.name;
        case NOTIFICATION_OBJECT_TYPE.OBJECT.code:
            return NOTIFICATION_OBJECT_TYPE.OBJECT.name;
        default:
            return;
    }
}

export const getNotificationObjectAccountType = (type) => {
    switch (type) {
        case NOTIFICATION_OBJECT_ACCOUNT_TYPE.ALL.code:
            return NOTIFICATION_OBJECT_ACCOUNT_TYPE.ALL.name
        case NOTIFICATION_OBJECT_ACCOUNT_TYPE.PREMIUM.code:
            return NOTIFICATION_OBJECT_ACCOUNT_TYPE.PREMIUM.name
        case NOTIFICATION_OBJECT_ACCOUNT_TYPE.STANDARD.code:
            return NOTIFICATION_OBJECT_ACCOUNT_TYPE.STANDARD.name
        default:
            return;
    }
}

export const repetitionIntervalItems = [
    {
        code: "none",
        name: "Không lặp lại"
    },
    {
        code: "daily",
        name: "Hàng ngày"
    },
    {
        code: "weekly",
        name: "Hàng tuần"
    },
    {
        code: "monthly",
        name: "Hàng tháng"
    }
]

export const loopTypes = [
    {
        code: "none",
        name: "Không lặp lại"
    },
    {
        code: "loop",
        name: "Lặp lại"
    }
]

export const gotoItems = [
    {
        code: "home",
        name: "Home"
    },
    {
        code: "insights",
        name: "Insight"
    },
    {
        code: "social",
        name: "Social"
    },
    {
        code: "app",
        name: "App"
    }
]

export const displayTypes = [
    {
        code: "onscreen",
        name: "Ngoài màn hình"
    },
    {
        code: "inapp",
        name: "In app (quả chuông)"
    }
]

export const getStagesByGoal = (goal) => {
    if (goal === 'mangthai') {
        return [
            {code: "trimester", name: "Tam cá nguyệt thứ"},
            {code: "week", name: "Tuần thứ"},
            {code: "month", name: "Tháng thứ"}
        ]
    }

    if (goal === 'sausinh') {
        return [
            {code: "week", name: "Tuần thứ"},
            {code: "month", name: "Tháng thứ"}
        ]
    }
}

export const getConditionsByGoalAndSubGoal = (conditions, goal, subGoal) => {
    if (goal === 'mongcon' || goal === 'sausinh') return []
    if (goal === 'mangthai') {
        if (!subGoal) {
            return [...conditions]
        }

        // thai giáo theo ngày
        if (subGoal === 'pregnant_daily_plan') {
            return conditions.filter(item => ['fully_educated', 'not_stydying'].indexOf(item.code) >= 0)
        }

        // theo dõi thai kỳ
        if (subGoal === 'pregnancy_tracking') {
            return []
        }

        // ăn vào con ko vào mẹ
        if (subGoal === 'diet_for_fetus_only') {
            return conditions.filter(item => ['fetus_index_over_threshold', 'fetus_index_not_reached'].indexOf(item.code) >= 0)
        }

        // lấy lại vóc dáng
        if (subGoal === 'get_form_back') {
            return []
        }
    }
    return []
}

export const getAppUtilities = () => {
    return [
        {
            code: "PrenatalNoteScreen",
            name: "Sổ khám Thai"
        },
        {
            code: "BabyStatsScreen",
            name: "Tăng trưởng của bé"
        },
        {
            code: "PumpingMilksScreen",
            name: "Hút sữa"
        },
        {
            code: "NutritionBookScreen",
            name: "Sổ tay dinh dưỡng"
        },
        {
            code: "WeaningFoodScreen",
            name: "Sổ tay ăn dặm"
        }
    ]
}

