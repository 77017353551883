import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PostService from '../../services/post.service';

import { SEARCH_PARAMS } from '../../contains/common';
import { showToast, withToast } from '../global';

const postService = new PostService();
const namespace = 'post';

const STATE = {
    loading: true,
    item: {
        'postsId': '',
        'postsName': '',
        'postsAvatar': '',
        'postsContent': '',
        'type': 'FREE',
        'topicId': '',
        'targetId': '',
        'categoryId': '',
        'order': '',
        'hashtagIds': [],
        'attachIds': []
    },
    searchParams: { ...SEARCH_PARAMS, categoryIds: [], targetIds: [], topicIds: [], hashTagIds: [], properties: 'order' },
    isOpenModal: false,
    table: {
        items: [],
        isCheckedAll: false,
        numberOfElements: 0,
        totalElements: 0,
        totalPages: 0,
        number: 0
    },
    error: {}
};

// actions
export const search = createAsyncThunk(
    `${namespace}/search`,
    async (searchParams = {}) => {


        const { body } = await postService.search({ ...STATE.searchParams, ...searchParams });
        return body;
    }
);

export const fetchById = createAsyncThunk(
    `${namespace}/fetchById`,
    async (id) => {
        return await postService.readOne(id);
    }
);

export const onCreate = createAsyncThunk(
    `${namespace}/create`,
    withToast(
        async (objectDTO, dispatch) => {
            const resp = await postService.create(objectDTO);
            dispatch(showToast([`done`, `Tạo bài viết thành công`]));
            return resp;
        }
    )
)

export const onUpdate = createAsyncThunk(
    `${namespace}/update`,
    withToast(
        async (objectDTO, dispatch) => {
            const resp = await postService.update(objectDTO);
            dispatch(showToast([`done`, `Cập nhật bài viết thành công`]));
            return resp;
        }
    )
)

export const onDelete = createAsyncThunk(
    `${namespace}/delete`,
    withToast(
        async (objectDTO, dispatch) => {
            const resp = await postService.delete(objectDTO);
            dispatch(showToast([`done`, `Xóa bài viết thành công`]));
            return resp;
        }
    )
)

const objectSlice = createSlice({
    name: namespace,
    initialState: STATE,
    reducers: {
        setActiveItem(state, { payload }) {
            if (!payload) {
                state.item = STATE.item;
            } else {
                const categoryId = payload?.categoryBasic?.categoryId || payload?.categoryId;
                const topicId = payload?.topicBasic?.topicId || payload?.topicId;
                const targetId = payload?.targetBasic?.targetId || payload?.targetId;
                const hashtagIds = payload?.hashtags?.map(x => { return { value: x.hashtagId, label: x.hashtagName } });
                state.item = { ...payload, targetId, categoryId, topicId, hashtagIds };
            }
        },
        setKeyword(state, { payload }) {
            state.searchParams = { ...state.searchParams, keyword: payload }
        },
        setDeviceType(state, { payload }) {
            state.searchParams = { ...state.searchParams, deviceType: payload }
        },
        openModal(state, { payload }) {
            state.isOpenModal = true;
        },
        hideModal(state, { payload }) {
            state.isOpenModal = false;
        },
        checkAll(state, { payload }) {
            state.table.isCheckedAll = payload;
            state.table.items.map(x => x.isChecked = payload);
        },
        checkItem(state, { payload }) {
            state.table.items = state.table.items.map(x => {
                if (x.postsId === payload.postsId) {
                    x.isChecked = !x.isChecked;
                }
                return x;
            });
            let haveItemFalse = state.table.items.find(x => x.isChecked === false);
            if (haveItemFalse) {
                state.table.isCheckedAll = false;
            } else {
                state.table.isCheckedAll = true;
            }
        },
        onChoicePageSize(state, { payload }) {
            state.searchParams.size = payload;
            state.searchParams.page = 0;
            state.table.isCheckedAll = false;
        },
        onChangePage(state, { payload }) {
            if (payload < 0 || payload >= state.table.totalPages) {
                return;
            }
            state.searchParams.page = payload;
            state.table.isCheckedAll = false;
        }
    },
    extraReducers: {
        // find all
        [search.pending](state) {
            state.loading = true
        },
        [search.fulfilled](state, { payload }) {
            state.loading = false;
            if (payload) {
                state.table.items = payload.content.map(x => {
                    return { ...STATE.item, ...x };
                });
                state.table.numberOfElements = payload.numberOfElements;
                state.table.totalElements = payload.totalElements;
                state.table.totalPages = payload.totalPages;
                state.table.number = payload.number;
            }
        },
        [search.rejected](state, { error }) {
            state.loading = false
            state.error = error;
        },
        // find one
        [fetchById.pending](state) {
            state.loading = true
        },
        [fetchById.fulfilled](state, { payload }) {
            state.loading = false;
            objectSlice.caseReducers.setActiveItem(state, { payload: payload.body });
        },
        [fetchById.rejected](state, { error }) {
            state.loading = false
            state.error = error;
        },
        // create
        [onCreate.pending](state) {
            state.loading = true
        },
        [onCreate.fulfilled](state, { payload }) {
            state.loading = false;
            state.item = STATE.item;
            state.table.items.unshift(payload.body);
            state.isOpenModal = false;
        },
        [onCreate.rejected](state, { error }) {
            state.loading = false
            state.error = error;
        },
        // update
        [onUpdate.pending](state) {
            state.loading = true
        },
        [onUpdate.fulfilled](state, { payload }) {
            state.loading = false;
            state.item = STATE.item;
            state.table.items = state.table.items.map(x => {
                if (x.postsId === payload.body.postsId) {
                    x = { ...STATE.item, ...payload.body };
                }
                return x;
            });
            state.isOpenModal = false;
        },
        [onUpdate.rejected](state, { error }) {
            state.loading = false
            state.error = error;
        },
        // delete
        [onDelete.pending](state) {
            state.loading = true
        },
        [onDelete.fulfilled](state) {
            state.loading = false;
            state.table.items = state.table.items.filter(x => x.postsId !== state.item.postsId);
            state.item = STATE.item;
            state.isOpenModal = false;
        },
        [onDelete.rejected](state, { error }) {
            state.loading = false
            state.error = error;
        }
    }
});

export const { setActiveItem, setKeyword, setDeviceType, openModal, hideModal, checkAll, checkItem, onChoicePageSize, onChangePage } = objectSlice.actions;
export default objectSlice.reducer;
