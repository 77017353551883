import React from "react";
import FoodsService from "../../services/foods.service";
import {
    Breadcrumb,
    Button,
    InputGroup,
    FormControl,
    Spinner,
    Table,
    Tabs,
    Form,
    Pagination,
} from "@themesberg/react-bootstrap";
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import {Helper} from "../../common/helper";
import ModalFood from "./ModalFood"
import ModalDishes from "./ModalDishes"
import {toast} from "react-toastify";

const {Tab} = Tabs;
const pageSize = 20
export default class Foods extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            listChecked: [],
            listItemChecked: [],
            tabActive: 'foods',
            page: 1,
            pageSize: pageSize,

            modalShow: false
        }
    }

    componentDidMount() {
        this.setState({isLoading: true}, () => {
            this.getData();
        })
    }

    getData = async () => {
        const {tabActive} = this.state;
        if (tabActive === 'foods') {
            this.getListFoods()
        } else {
            this.getListDishes()
        }
    };

    getDetailData = async (id) => {
        const {tabActive} = this.state;
        this.setState({isLoadingDetail: true}, () => {
            if (tabActive === 'foods') {
                this.getDetailFood(id)
            } else {
                this.getDetailDishes(id)
            }
        })
    };


    getListFoods = async () => {
        const {page, pageSize} = this.state;
        let queryString = this.buildQueryString();
        if (queryString) {
            queryString = queryString + `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdDate:desc`
        } else {
            queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdDate:desc`
        }

        const resp = await FoodsService.listFoods(queryString);
        const headers = resp.headers || {};
        this.setState({
            data: resp.data,
            metadata: {
                pageCount: parseFloat(headers['x-page-count']),
                page: parseFloat(headers['x-page-number']),
                size: parseFloat(headers['x-page-size']),
                total: parseFloat(headers['x-total-count']),
            },
            isLoading: false,
        })
    }

    getListDishes = async () => {
        const {page, pageSize} = this.state;
        let queryString = this.buildQueryString();
        if (queryString) {
            queryString = queryString + `&page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdDate:desc`
        } else {
            queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdDate:desc`
        }
        const resp = await FoodsService.listDishes(queryString);
        const headers = resp.headers || {};
        this.setState({
            data: resp.data,
            metadata: {
                pageCount: parseFloat(headers['x-page-count']),
                page: parseFloat(headers['x-page-number']),
                size: parseFloat(headers['x-page-size']),
                total: parseFloat(headers['x-total-count']),
            },
            isLoading: false
        })
    }

    getDetailFood = async (id) => {
        const resp = await FoodsService.getFood(id);
        this.setState({
            dataDetail: resp.data,
            isLoadingDetail: false,
            modalShow: true
        })
    }

    getDetailDishes = async (id) => {
        const resp = await FoodsService.getDishes(id);
        this.setState({
            dataDetail: resp.data,
            isLoadingDetail: false,
            modalShow: true
        })
    }

    deleteFood = async (id) => {
        try {
            await FoodsService.deleteFoods(id);
            this.getData();
            toast.success("Xóa bản ghi thành công")
        } catch (err) {
            // console.log('rẻe', err)
            toast.error(err?.message || "Có lỗi xảy ra vui lòng liên hệ kỹ thuật để được hỗ trợ.")
        }
    }

    deleteDishes = async (id) => {
        try {
            await FoodsService.deleteDishes(id);
            this.getData();
            toast.success("Xóa bản ghi thành công")
        } catch (err) {
            toast.error(err?.message || "Có lỗi xảy ra vui lòng liên hệ kỹ thuật để được hỗ trợ.")
        }
    }

    onPageChanged = (page) => {
        this.setState({page: page}, () => {
            this.getData()
        });
    };

    /**
     * xử lý với dữ liệu search trong state
     */
    onSubmitSearch = () => {
        this.setState({page: 1}, () => {
            this.getData()
        })
    };

    /**
     * xây dựng query string theo các giá trị trong state
     * @returns {string}
     */
    buildQueryString = () => {
        let {listChecked, startValue, endValue, querySearch} = this.state;
        let queryString = '';
        for (let p in listChecked) {
            let value = '';
            if (Array.isArray(listChecked[p]) && listChecked[p].length > 0) {
                listChecked[p].map(x => {
                    value = value + x + ',';
                });

                value = value.slice(0, -1)
            }
            if (value) queryString = Helper.updateQueryStringParameter(queryString, p, value)
        }

        if (querySearch) {
            queryString = Helper.updateQueryStringParameter(queryString, 'query', querySearch.trim())
        }

        return queryString
    };

    onItemChecked = (item) => {
        const {listItemChecked = []} = this.state;
        let newList = listItemChecked
        if (!!listItemChecked.find(x => x.foodId === item.foodId)) {
            newList = listItemChecked.filter(x => x.foodId !== item.foodId)
        } else {
            newList.push(item)
        }

        this.setState({listItemChecked: newList})
    }

    onCheckedAll = () => {
        const {data = [], listItemChecked = []} = this.state;
        if (listItemChecked.length === data.length) {
            this.setState({listItemChecked: []})
        } else {
            this.setState({listItemChecked: data})
        }
    }

    toggleModal = () => {
        // console.log('vào toggleModal')

        if (this.state.modalShow) {
            this.setState({
                modalShow: false,
                dataDetail: {}
            })
        } else {
            this.setState({
                modalShow: true
            })
        }
    }

    renderRow = (item, index) => {
        const {listItemChecked = []} = this.state;
        return <tr key={index}>
            <td>
                <Form.Check
                    className="inbox-check me-2"
                    checked={!!listItemChecked.find(x => x.foodId === item.foodId)}
                    onChange={e => this.onItemChecked(item)}
                />
            </td>
            <td>
                <span className="fw-normal"><b>{item?.foodId || '---'}</b></span>
            </td>
            <td>
                <span className="fw-bold">
                    {item?.foodName || '---'}
                </span>
            </td>
            <td>
                <span className={`fw-normal`}>{item?.foodGroup?.foodGroupName || '---'}</span>
            </td>
            <td>
                <span className={`fw-normal`}>{item?.nutritionalIngredients || '---'}</span>
            </td>
            <td>
                <div ref={ref => this.actionContainer = ref} className="dp-flex">
                    <div
                        onClick={() => this.getDetailData(item.foodId)}
                        className={'table-action-btn'}
                    >
                        <FontAwesomeIcon icon={Icon.faEdit} className="me-2"/>
                    </div>
                    <div
                        onClick={(e) => {
                            if (window.confirm("Bạn có chắc muốn tiếp tục thao tác?")) {
                                this.deleteFood(item?.foodId)
                            } else {
                                console.log('cancel called');
                            }
                        }}
                        className={'table-action-btn'}
                    >
                        <FontAwesomeIcon icon={Icon.faTrashAlt} className="me-2"/>
                    </div>

                </div>
            </td>
        </tr>
    }

    renderRowDishes = (item, index) => {
        const {listItemChecked = []} = this.state;
        return <tr key={index}>
            <td>
                <Form.Check
                    className="inbox-check me-2"
                    checked={!!listItemChecked.find(x => x.dishId === item.dishId)}
                    onChange={e => this.onItemChecked(item)}
                />
            </td>
            <td>
                <span className="fw-normal"><b>{item?.dishId || '---'}</b></span>
            </td>
            <td>
                <span className="fw-bold">
                    {item?.dishName || '---'}
                </span>
            </td>
            <td>
                <span className={`fw-normal`}>{item?.food?.foodName || '---'}</span>
            </td>
            <td>
                <div ref={ref => this.actionContainer = ref} className="dp-flex">
                    <div
                        onClick={() => this.getDetailData(item.dishId)}
                        className={'table-action-btn'}
                    >
                        <FontAwesomeIcon icon={Icon.faEdit} className="me-2"/>
                    </div>
                    <div
                        onClick={(e) => {
                            if (window.confirm("Bạn có chắc muốn tiếp tục thao tác?")) {
                                this.deleteDishes(item?.dishId)
                            } else {
                                console.log('cancel called');
                            }
                        }}
                        className={'table-action-btn'}
                    >
                        <FontAwesomeIcon icon={Icon.faTrashAlt} className="me-2"/>
                    </div>

                </div>
            </td>
        </tr>
    }

    renderContentDishes = () => {
        const {data = [], listItemChecked = [], page, metadata = {}} = this.state;
        if (this.state.isLoading) {
            return <div className="d-flex justify-content-center pt-8">
                <Spinner animation="border"/>
            </div>
        }

        return <div>
            <Table hover className="user-table align-items-center">
                <thead>
                <tr>
                    <th key={1} className="border-bottom">
                        <Form.Check
                            className="inbox-check me-2"
                            checked={listItemChecked.length === data.length}
                            onChange={e => this.onCheckedAll()}
                        />
                    </th>
                    <th key={2} className="border-bottom">ID</th>
                    <th key={3} className="border-bottom">Tên món ăn</th>
                    <th key={4} className="border-bottom">Thực phẩm chính</th>
                    <th key={5} className="border-bottom"/>
                </tr>
                </thead>
                <tbody>
                {
                    data.map((item, index) => this.renderRowDishes(item, index))
                }
                </tbody>
            </Table>
            <Pagination className="mb-2 mb-lg-0">
                <Pagination.Prev disabled={metadata?.page === 0} onClick={e => this.onPageChanged(page-1)}>Previous</Pagination.Prev>
                {[...Array(metadata?.pageCount).keys()].map(p => (
                    <Pagination.Item key={`page-${p}`} onClick={e => this.onPageChanged(p+1)} active={metadata.page === p} > { p + 1} </Pagination.Item>
                ))}
                <Pagination.Next disabled={metadata?.page+1 === metadata?.pageCount} onClick={e => this.onPageChanged(page+1)}>Next</Pagination.Next>
            </Pagination>
        </div>
    }

    renderContentFood = () => {
        const {data = [], listItemChecked = [], page, metadata = {}} = this.state;
        if (this.state.isLoading) {
            return <div className="d-flex justify-content-center pt-8">
                <Spinner animation="border"/>
            </div>
        }

        return <div>
            <Table hover className="user-table align-items-center">
                <thead>
                <tr>
                    <th key={1} className="border-bottom">
                        <Form.Check
                            className="inbox-check me-2"
                            checked={listItemChecked.length === data.length}
                            onChange={e => this.onCheckedAll()}
                        />
                    </th>
                    <th key={2} className="border-bottom">ID</th>
                    <th key={3} className="border-bottom">Tên thực phẩm</th>
                    <th key={4} className="border-bottom">Nhóm thực phẩm</th>
                    <th key={5} className="border-bottom">Thành phần dinh dưỡng</th>
                    <th key={6} className="border-bottom"/>
                </tr>
                </thead>
                <tbody>
                {
                    data.map((item, index) => this.renderRow(item, index))
                }
                </tbody>
            </Table>
            <Pagination className="mb-2 mb-lg-0">
                <Pagination.Prev disabled={metadata?.page === 0} onClick={e => this.onPageChanged(page-1)}>Previous</Pagination.Prev>
                {[...Array(metadata?.pageCount).keys()].map(p => (
                    <Pagination.Item key={`page-${p}`} onClick={e => this.onPageChanged(p+1)} active={metadata.page === p} > { p + 1} </Pagination.Item>
                ))}
                <Pagination.Next disabled={metadata?.page+1 === metadata?.pageCount} onClick={e => this.onPageChanged(page+1)}>Next</Pagination.Next>
            </Pagination>
        </div>
    }

    render() {
        const {tabActive, data, querySearch, modalShow, dataDetail = {}} = this.state;
        if (!Array.isArray(data)) {
            return <div>Có lỗi xảy ra vui lòng liên kệ kỹ thuật</div>
        }

        return (
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item to={Routes.DashboardOverview.path}><FontAwesomeIcon
                                icon={Icon.faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item active>Sổ tay dinh dưỡng</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>

                <Tabs
                    activeKey={tabActive}
                    onSelect={(k) => this.setState({
                        tabActive: k,
                        listItemChecked: [],
                        data: [],
                        isLoading: true,
                        querySearch: '',
                    }, () => this.getData())}
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="foods" title="Thực phẩm">
                        <div className="dp-flex justify-content-space-between align-items-center">
                            <div onClick={e => {
                                this.setState({dataDetail: {}}, () => {
                                    this.toggleModal()
                                })
                            }} className="custom-btn">
                                <FontAwesomeIcon icon={Icon.faPlus} className="me-2"/> Thêm bài viết
                            </div>

                            <div>
                                <InputGroup className="mb-3">
                                    <FormControl
                                        value={querySearch}
                                        onChange={e => this.setState({querySearch: e.target.value})}
                                        onKeyDown={e => {
                                            if (e?.keyCode === 13) {
                                                this.onSubmitSearch()
                                            }
                                        }}
                                        placeholder="Nhập tên thực phẩm"
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                    />
                                    <Button
                                        onClick={() => {
                                            this.onSubmitSearch()
                                        }}
                                        variant="outline-secondary"
                                        id="button-addon2"
                                    >
                                        <FontAwesomeIcon icon={Icon.faSearch} className="me-2"/>
                                    </Button>
                                </InputGroup>
                            </div>

                        </div>

                        {this.renderContentFood()}
                    </Tab>

                    <Tab eventKey="dishes" title="Món ăn">
                        <div className="dp-flex justify-content-space-between align-items-center">
                            <div onClick={e => {
                                this.setState({dataDetail: {}}, () => {
                                    this.toggleModal()
                                })
                            }} className="custom-btn">
                                <FontAwesomeIcon icon={Icon.faPlus} className="me-2"/> Thêm bài viết
                            </div>

                            <div>
                                <InputGroup className="mb-3">
                                    <FormControl
                                        value={querySearch}
                                        onChange={e => this.setState({querySearch: e.target.value})}
                                        onKeyDown={e => {
                                            if (e?.keyCode === 13) {
                                                this.onSubmitSearch()
                                            }
                                        }}
                                        placeholder="Nhập tên món ăn"
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                    />
                                    <Button
                                        onClick={() => {
                                            this.onSubmitSearch()
                                        }}
                                        variant="outline-secondary"
                                        id="button-addon2"
                                    >
                                        <FontAwesomeIcon icon={Icon.faSearch} className="me-2"/>
                                    </Button>
                                </InputGroup>
                            </div>

                        </div>

                        {this.renderContentDishes()}
                    </Tab>
                </Tabs>

                {
                    tabActive === 'foods' ? <ModalFood
                        dataDetail={dataDetail}
                        toggle={this.toggleModal}
                        getData={this.getData}
                        modalStatus={modalShow}
                    />
                    :
                    <ModalDishes
                        dataDetail={dataDetail}
                        toggle={this.toggleModal}
                        getData={this.getData}
                        modalStatus={modalShow}
                    />
                }

            </div>
        );
    }
};
