import React from "react";
import { Modal, Button, InputGroup, FormControl, Form, Dropdown, Collapse } from '@themesberg/react-bootstrap';
import moment from 'moment'
import _ from 'lodash'
import SurveysService from "../../services/surveys.service";
import FileService from "../../services/file.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import md5 from 'md5'
import Select from "react-select";

import {SURVEY_DISPLAY_TYPE, SURVEY_QUESTION_TYPE} from "../../contains/common";
import { toast } from 'react-toastify';
import Dropzone from "react-dropzone";


export default class ModalForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bodyData: {},
            listQuestions: []
        }

        this.fileService = new FileService()
    }

    componentDidMount() {
        this.getReceivers()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.dataDetail) !== JSON.stringify(this.props.dataDetail)) {
            const {dataDetail = {}} = this.props;

            let newData = {
                ...dataDetail,
                surveyReceiverCode: dataDetail?.surveyReceiver?.code
            }

            if (dataDetail?.id || dataDetail?.id === 0) {
                this.setState({
                    bodyData: newData,
                    originalData: _.cloneDeep(newData),
                    listQuestions: newData?.questions || []
                })
            } else {
                this.setState({
                    bodyData: {},
                    originalData: {},
                    listQuestions: []
                })
            }
        }
    }

    onSubmit = async () => {
        const {bodyData = {}, listQuestions = []} = this.state;
        const {getData} = this.props;

        if (!bodyData?.title) {
            toast.error("Vui lòng nhập tiêu đề")
            return
        }

        if (!bodyData?.surveyReceiverCode) {
            toast.error("Vui lòng chọn đối tượng nhận")
            return
        }

        if (!bodyData?.displayType) {
            toast.error("Vui lòng chọn cách hiển thị")
            return
        }


        let body = {
            "surveyReceiverCode": bodyData?.surveyReceiverCode,
            "displayType": bodyData?.displayType,
            "title": bodyData?.title,
            "image": bodyData?.image,
            "questions": listQuestions
        }
        // console.log('body', body)

        try {
            if (bodyData?.id || bodyData?.id === 0) {
                // await SurveysService.updateSurvey(bodyData?.id, body);
                // toast.success("Sửa thành công")
                return
            } else {
                await SurveysService.createSurvey(body);
                toast.success("Tạo thành công")
            }
            this.onToggle()
            getData()
        } catch (error) {
            toast.error(error?.message || "Có lỗi xảy ra vui lòng liên hệ kỹ thuật để được hỗ trợ.")
        }
    }

    handleFileUploadByPostsFormat = async (files) => {
        // console.log('vào handleFileUploadByPostsFormat')
        const file = files[0];
        let fileSize = file.size / 1024 / 1024; // in MiB
        if (fileSize > 500) {
            return alert('Dung lượng file lớn hơn 500 MiB, vui lòng chọn file khác.');
        }

        let {bodyData = {}} = this.state;

        let body = {
            objectType: "survey",
            displayType: 1
        }
        // objectType: "posts"
        // objectId: ID bài viết
        // displayType: 1(thumbnail), 2( icon), 3(Cover), 4(video), 5(Audio), 6(Infographic), 7(avatar)

        try {
            const resp = await this.fileService.uploadImage(file, body);
            if (resp?.linkUrl?.length > 0) {
                bodyData.image = resp.linkUrl[0]
            }
        } catch (error) {
            console.log(error);
        }

        this.setState({bodyData: bodyData})
    }

    getReceivers = async () => {
        const resp = await SurveysService.getReceivers();
        this.setState({
            receivers: resp.data,
        })

    }

    onToggle = () => {
        const {toggle} = this.props;
        this.setState({
            bodyData: {}
        }, () => {
            toggle()
        })
    }

    addQuestion = (type) => {
        let {listQuestions = []} = this.state;
        let newCode = md5(moment().valueOf())
        let lastQuestion = listQuestions[listQuestions.length - 1]

        if (lastQuestion) {
            let newAnswers = lastQuestion?.answers
            if (Array.isArray(newAnswers) && newAnswers.length > 0) {
                newAnswers = newAnswers.map(z => {
                    return {
                        ...z,
                        nextQuestionCode: z.nextQuestionCode ? z.nextQuestionCode : newCode
                    }
                })

                lastQuestion['answers'] = newAnswers
                listQuestions[listQuestions.length - 1] = lastQuestion
            }
        }

        this.setState({
            listQuestions: [
                ...listQuestions,
                {
                    code: newCode,
                    open: true,
                    order: listQuestions.length > 0 ? listQuestions[listQuestions.length - 1].order+1 : 1,
                    type: type
                }
            ]
        }, () => {
            if (type === SURVEY_QUESTION_TYPE[3].id) {
                this.addQuestionAnswer({
                    code: newCode,
                    open: true,
                    order: listQuestions.length > 0 ? listQuestions[listQuestions.length - 1].order+1 : 1,
                    type: type
                })
            }
        })
    }

    deleteQuestion = (item) => {
        let {listQuestions = []} = this.state;
        listQuestions = listQuestions.filter(x => x.code !== item.code)

        let cQuestionAnswer = null
        listQuestions = listQuestions.map(x => {
            cQuestionAnswer = x.answers
            if (Array.isArray(cQuestionAnswer)) {
                let lAnswer = cQuestionAnswer.map(a => {
                    if (a.nextQuestionCode === item.code) {
                        return {
                            ...a,
                            nextQuestionCode: ''
                        }
                    }
                    return a
                })
                return {
                    ...x,
                    answers: lAnswer
                }
            }
            return x
        })

        this.setState({
            listQuestions: listQuestions
        })
    }

    addQuestionAnswer = (question) => {
        let {listQuestions = []} = this.state;
        let questionIndex = listQuestions.findIndex(x => x.order === question.order)
        let currentQuestion = listQuestions[questionIndex];
        if (Array.isArray(currentQuestion['answers'])) {
            currentQuestion['answers'] = [
                ...currentQuestion['answers'],
                {
                    code: md5(moment().valueOf()),
                    order: currentQuestion['answers'][currentQuestion['answers'].length - 1].order+1,
                }
            ];
        } else {
            currentQuestion['answers'] = [{
                code: md5(moment().valueOf()),
                order: 1,
            }];
        }
        listQuestions[questionIndex] = currentQuestion;
        this.setState({
            listQuestions: listQuestions
        })
    }

    deleteQuestionAnswer = (question, answer) => {
        let {listQuestions = []} = this.state;
        let questionIndex = listQuestions.findIndex(x => x.code === question.code)
        let currentQuestion = listQuestions[questionIndex];
        currentQuestion['answers'] = currentQuestion['answers'].filter(x => x.code !== answer.code)
        listQuestions[questionIndex] = currentQuestion;
        this.setState({
            listQuestions: listQuestions
        })
    }

    onQuestionValueChange = (props, value, question) => {
        let {listQuestions = []} = this.state;
        let index = listQuestions.findIndex(x => x.code === question.code)
        let currentQuestion = listQuestions[index];

        currentQuestion[props] = value;
        listQuestions[index] = currentQuestion;
        this.setState({listQuestions})
    }

    onAnswerQuestionValueChange = (value, props, question, answer) => {
        let {listQuestions = []} = this.state;
        let questionIndex = listQuestions.findIndex(x => x.code === question.code)
        let currentQuestion = listQuestions[questionIndex];
        let answers = currentQuestion['answers']
        let answerIndex = answers.findIndex(x => x.code === answer.code)
        let currentAnswer = answers[answerIndex]
        currentAnswer[props] = value

        answers[answerIndex] = currentAnswer;
        currentQuestion['answers'] = answers
        listQuestions[questionIndex] = currentQuestion;
        this.setState({listQuestions})
    }

    renderContentQuestionByType = (type, item, index) => {
        const {listQuestions = []} = this.state;
        const listQuestionsModified = listQuestions.map((q, i) => {return {value: q.code, label: `Câu hỏi ${i+1}`}})
        if (type === SURVEY_QUESTION_TYPE[0].id) {
            return <div>
                {
                    Array.isArray(item?.answers) && item?.answers.map((x,y) => {
                        return <div key={`answer-${x.order}`} className='d-flex align-items-center mgbt-10'>
                            <div className="modal-label-small">
                                Đáp án trả lời {y+1}:
                            </div>
                            <FormControl
                                key={`answer-content-${x.order}`}
                                value={x.content || ''}
                                onChange={e => this.onAnswerQuestionValueChange(e.target.value, 'content', item, x)}
                                placeholder={`Đáp án trả lời ${y+1}`}
                            />
                            <div className="flex-none pdl-10 pdr-10">
                                Go to:
                            </div>
                            <Select
                                key={`answer-branch-${x.order}`}
                                className="custom-react-select"
                                placeholder="Chọn câu hỏi tiếp theo"
                                options={listQuestions.map((q, i) => {return {value: q.code, label: `Câu hỏi ${i+1}`}})}
                                value={listQuestionsModified.find(z => z.value === x.nextQuestionCode)}
                                isMulti={false}
                                onChange={(e) => this.onAnswerQuestionValueChange(e?.value, 'nextQuestionCode', item, x)}
                                isClearable={true}
                                backspaceRemovesValue={true}
                            />
                            <div
                                className="btn-item-survey flex-none"
                                onClick={() => this.deleteQuestionAnswer(item, x)}
                            >
                                <FontAwesomeIcon icon={Icon.faTrashAlt} className="me-2"/>
                            </div>
                        </div>
                    })
                }

                <div
                    onClick={e => this.addQuestionAnswer(item)}
                    className="custom-btn"
                >
                    <FontAwesomeIcon icon={Icon.faPlus} className="me-2"/> Thêm đáp án
                </div>
            </div>
        }
        if (type === SURVEY_QUESTION_TYPE[3].id) {
            return <div>
                {
                    Array.isArray(item?.answers) && item?.answers.map((x,y) => {
                        return <div key={`answer-${x.order}`} className='d-flex align-items-center mgbt-10'>
                            <div className="modal-label-small">
                                Đáp án trả lời:
                            </div>
                            <FormControl
                                key={`answer-content-${x.order}`}
                                value={x.content || ''}
                                onChange={e => this.onAnswerQuestionValueChange(e.target.value, 'content', item, x)}
                                placeholder={`Đáp án trả lời`}
                            />
                            <div className="flex-none pdl-10 pdr-10">
                                Go to:
                            </div>
                            <Select
                                key={`answer-branch-${x.order}`}
                                className="custom-react-select"
                                placeholder="Chọn câu hỏi tiếp theo"
                                options={listQuestions.map((q, i) => {return {value: q.code, label: `Câu hỏi ${i+1}`}})}
                                value={listQuestionsModified.find(z => z.value === x.nextQuestionCode)}
                                isMulti={false}
                                onChange={(e) => this.onAnswerQuestionValueChange(e?.value, 'nextQuestionCode', item, x)}
                                isClearable={true}
                                backspaceRemovesValue={true}
                            />
                        </div>
                    })
                }
            </div>
        }
        return <div/>
    }

    renderQuestionItem = (item, index) => {
        return <div
            key={`question-${item.order}`}
            className="survey-question-item mgbt-20"
        >
            <div
                className="d-flex align-items-center justify-content-between "
            >
                <div
                    className="bold cursor-pointer"
                    onClick={() => this.onQuestionValueChange('open', !item.open, item)}
                >
                    Câu hỏi {index+1} --- Loại: {SURVEY_QUESTION_TYPE.find(x => x.id === item.type).name}
                </div>

                <div className="d-flex">
                    <div
                        className="btn-item-survey"
                        onClick={() => this.deleteQuestion(item, index)}
                    >
                        <FontAwesomeIcon icon={Icon.faTrashAlt} className="me-2"/>
                    </div>

                    <div
                        className="btn-item-survey"
                        onClick={() => this.onQuestionValueChange('open', !item.open, item)}
                    >
                        {item.open ? <FontAwesomeIcon icon={Icon.faCaretUp} className="me-2"/> : <FontAwesomeIcon icon={Icon.faCaretDown} className="me-2"/>}
                    </div>
                </div>
            </div>

            <Collapse
                in={item.open}
            >
                <div className="mgt-15">
                    <div className="modal-row d-flex align-items-center">
                        <div className="modal-label-small">
                            Nội dung:
                        </div>

                        <FormControl
                            style={{height: 95}}
                            as="textarea"
                            key={`question-content-${item.order}`}
                            value={item.content || ''}
                            onChange={e => this.onQuestionValueChange('content', e.target.value, item)}
                            placeholder="Nhập câu hỏi"
                        />
                    </div>

                    {this.renderContentQuestionByType(item?.type, item, index)}

                </div>
            </Collapse>
        </div>
    }

    renderListQuestions = () => {
        const {listQuestions = []} = this.state;

        return <div className="survey-question-box">

            {
                listQuestions.map(this.renderQuestionItem)
            }

            <div className="dp-flex justify-content-space-between align-items-center">
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <FontAwesomeIcon icon={Icon.faPlus} className="me-2"/> Thêm câu hỏi
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {
                            SURVEY_QUESTION_TYPE.map((item,index) => {
                                return <Dropdown.Item key={index} onClick={() => this.addQuestion(item.id)}>{item.name}</Dropdown.Item>
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    }

    render() {
        const {modalStatus} = this.props;
        const {receivers = [], bodyData = {}} = this.state;

        const receiversModified = receivers.map(x => {return {value: x.code, label: x.name}})
        const displayTypeModified = SURVEY_DISPLAY_TYPE.map(x => {return {value: x.id, label: x.name}})

        return (
            <Modal dialogClassName="modal-90w" as={Modal.Dialog} show={modalStatus} onHide={this.onToggle}>
                <Modal.Header closeButton>
                    <Modal.Title>{bodyData?.id || bodyData?.id === 0 ? "Chỉnh sửa survey" : "Thêm mới survey"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"modal-sub-title"}>
                        Cấu hình survey
                    </div>

                    <div className="modal-row d-flex align-items-center">
                        <div className="modal-label">
                            Đối tượng nhận:
                        </div>
                        {
                            receivers &&
                            <Select
                                className="custom-react-select"
                                placeholder="Chọn đối tượng nhận"
                                options={receiversModified}
                                value={receiversModified.find(x => x.value === bodyData.surveyReceiverCode)}
                                isMulti={false}
                                onChange={(e) => {this.setState({bodyData: {...bodyData, surveyReceiverCode: e?.value}})}}
                                isClearable={true}
                                backspaceRemovesValue={true}
                            />
                        }
                    </div>

                    <div className="modal-row d-flex align-items-center">
                        <div className="modal-label">
                            Cách hiển thị:
                        </div>
                        <Select
                            className="custom-react-select"
                            placeholder="Chọn cách hiển thị"
                            options={displayTypeModified}
                            value={displayTypeModified.find(x => x.value === bodyData.displayType)}
                            isMulti={false}
                            onChange={(e) => {this.setState({bodyData: {...bodyData, displayType: e?.value}})}}
                            isClearable={true}
                            backspaceRemovesValue={true}
                        />
                    </div>

                    <div className={"modal-sub-title"}>
                        Nội dung survey
                    </div>

                    <div className="modal-row d-flex align-items-center">
                        <div className="modal-label">
                            Tiêu đề:
                        </div>

                        <FormControl
                            value={bodyData.title}
                            onChange={e => this.setState({bodyData: {...bodyData, title: e.target.value}})}
                            placeholder="Nhập tiêu đề"
                        />
                    </div>

                    <div className="modal-row">
                        <div className="d-flex align-items-center">
                            <div className="modal-label">
                                Tải lên ảnh survey:
                            </div>
                            <Dropzone
                                accept={'image/*'}
                                multiple={false}
                                onDrop={acceptedFiles => this.handleFileUploadByPostsFormat(acceptedFiles)}
                            >
                                {({getRootProps, getInputProps}) => (
                                    <section key={'dropzone'}>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <FontAwesomeIcon icon={Icon.faCloudUploadAlt} className="me-2" />
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="modal-label" />
                            {
                                bodyData.image && (
                                    <img src={bodyData.image} style={{height: 300, width: 'auto'}}/>
                                )
                            }
                        </div>
                    </div>


                    {this.renderListQuestions()}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.onToggle}>
                        Đóng
                    </Button>
                    {
                        !bodyData?.id && bodyData?.id !== 0 && <Button variant="primary" onClick={() => this.onSubmit()}>
                            {bodyData?.id || bodyData?.id === 0 ? "Lưu" : "Tạo mới"}
                        </Button>
                    }

                </Modal.Footer>
            </Modal>
        )
    }
}