import React, {useEffect, useRef, useState} from "react";
import {Button, Input, Spin, Upload} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icon from '@fortawesome/free-solid-svg-icons';
import WebSocketAsPromised from "websocket-as-promised";
import {CHAT_SOCKET_URL, defaultCustomerAvatar, ROOM_SOCKET_URL} from "../../contains/common";
import ChatsService from "../../services/chats.service";
import _ from "lodash";
import {RoomItem} from "./RoomItem";
import {MessageItem} from "./MessageItem";

const chatsService = new ChatsService();

export const Chat = () => {
    const chatMsgListRef = useRef()
    const [loading, setLoading] = useState(false)
    const [initialRooms, setInitalRooms] = useState([])
    const [rooms, setRooms] = useState([])
    const [messages, setMessages] = useState([])
    const [roomPagination, setRoomPagination] = useState({
        pageCount: 1,
        page: 1,
        size: 50,
        total: 0
    })
    const [currentRoom, setCurrentRoom] = useState(null)
    const [currentCustomer, setCurrentCustomer] = useState({
        userId: null,
        avatar: null,
        username: null,
        type: null
    });
    const [rocketUser, setRocketUser] = useState({
        authToken: "",
        userId: ""
    })
    const [initialWaitingRooms, setInitialWaitingRooms] = useState([])
    const [waitingRooms, setWaitingRooms] = useState([]);
    const [currentTabRoom, setCurrentTabRoom] = useState('waitingRoom')
    const [currentMsg, setCurrentMsg] = useState("")

    const chatSocket = new WebSocketAsPromised(CHAT_SOCKET_URL)

    chatSocket.onMessage.addListener(data => {
        try {
            let dataObj = JSON.parse(data);
            if (dataObj?.result && Array.isArray(dataObj?.result?.messages)) {
                setMessages(_.reverse(dataObj?.result?.messages))
                chatMsgListRef.current.scrollTo({
                    top: chatMsgListRef.current.scrollHeight + 10000,
                    behavior: 'smooth'
                })
            }
        } catch (error) {}
    })

    const getListRooms = async (params = {}) => {
        setLoading(true)
        try {
            const resp = await chatsService.getMyChatList({
                pageNumber: 0,
                pageSize: 1000,
                ...params
            });
            const headers = resp.headers || {};
            const metadata = {
                pageCount: parseFloat(headers['x-page-count']),
                page: parseFloat(headers['x-page-number']),
                size: parseFloat(headers['x-page-size']),
                total: parseFloat(headers['x-total-count']),
            }

            setLoading(false)
            if (metadata.page === 0) {
                setInitalRooms(resp?.data)
                setRooms(resp?.data)
            } else {
                setInitalRooms([...rooms, ...resp?.data])
                setRooms([...rooms, ...resp?.data])
            }
            setRoomPagination(metadata)
        } catch (error) {
            console.log(error);
        }
    }

    const joinRoom = (roomId) => {
        setLoading(true)

        chatsService.join({roomId})
            .then(response => {
                setRocketUser(response.body?.rocketUser)
                chatSocket.open()
                    .then(() => {
                        chatSocket.send(JSON.stringify({
                            msg: 'connect',
                            version: '1',
                            support: ['1', 'pre2', 'pre1'],
                        }),)
                    })
                    .then(() => {
                        chatSocket.send(
                            JSON.stringify({
                                id: roomId,
                                msg: 'method',
                                method: 'login',
                                params: [{resume: response?.body?.rocketUser?.authToken}],
                            }),
                        )
                    })
                    .then(() => {
                        chatSocket.send(JSON.stringify({
                            "msg": "method",
                            "method": "loadHistory",
                            id: roomId,
                            "params":[
                                roomId,
                                null
                            ]
                        }))
                    })
                    .finally(() => setLoading(false))
            })
            .catch(() => setLoading(false))
    }

    const handleSelectRoom = (room) => {
        setCurrentRoom(room)
        setCurrentCustomer({
            ...room?.customerProfile
        })
        joinRoom(room.roomId)
    }

    const handleChangeTabRoom = (type) => {
        setCurrentTabRoom(type)
        if (type === 'myRoom') {
            getListRooms()
        }
    }

    const handleReceiveRoom = (roomId) => {
        setLoading(true)
        chatsService.receiveRoom({
            roomId: roomId
        })
            .then(() => {
                setLoading(false)
                getListRooms()
            })
    }

    const handleSubmitMessage = (msg) => {
        setLoading(true)
        chatsService.sendMessage({
            headers: {
                "X-Auth-Token": rocketUser?.authToken,
                "X-User-Id": rocketUser?.userId
            },
            body: {
                "message": JSON.stringify({
                    msg: 'method',
                    method: "sendMessage",
                    params: [
                        {
                            "rid": currentRoom?.roomId,
                            "msg": msg
                        }
                    ]
                })
            }
        })
            .then(() => {
                setCurrentMsg("")
                joinRoom(currentRoom.roomId)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleChangeFile = (fileList) => {
        console.log('fileList', fileList)
    }

    const handleUploadFile = (file) => {
        setLoading(true)
        chatsService.uploadFile({
            roomId: currentRoom?.roomId,
            headers: {
                "X-Auth-Token": rocketUser?.authToken,
                "X-User-Id": rocketUser?.userId
            },
            file,
            description: ''
        })
            .then(() => {
                setLoading(false)
                joinRoom(currentRoom?.roomId)
            })
            .catch(() => setLoading(false))


        return false
    }

    const inMyRoom = (customerId) => {
        return !waitingRooms.find(item => item.userId === customerId)
    }

    const handleChangeKeyword = (keyword) => {
        if (currentTabRoom === 'waitingRoom') {
            if (keyword.length >= 3) {
                let rooms = initialWaitingRooms.filter(item => {
                    return !!((item.title && item.title.toString().toLowerCase().indexOf(keyword.toString().toLowerCase()) >= 0)
                        || (item?.customerProfile?.username && item?.customerProfile?.username.toString().toLowerCase().indexOf(keyword.toString().toLowerCase()) >= 0)
                        || (item.userId && item.userId.toString().toLowerCase().indexOf(keyword.toString().toLowerCase()) >= 0));

                })
                setWaitingRooms(rooms)
            }
            else {
                setWaitingRooms([...initialWaitingRooms])
            }
        }
        else {
            if (keyword.length >= 3) {
                let rooms = initialRooms.filter(item => {
                    return !!((item.title && item.title.toString().toLowerCase().indexOf(keyword.toString().toLowerCase()) >= 0)
                        || (item?.customerProfile?.username && item?.customerProfile?.username.toString().toLowerCase().indexOf(keyword.toString().toLowerCase()) >= 0)
                        || (item.userId && item.userId.toString().toLowerCase().indexOf(keyword.toString().toLowerCase()) >= 0));

                })
                setRooms(rooms)
            }
            else {
                setRooms([...initialRooms])
            }
        }
    }

    useEffect(() => {
        chatSocket.open()
            .then((response) => {
            console.log('chat response', response)
        })
            .catch(error => {
                console.log("ERROR", error)
            })

        getListRooms()
    }, [])

    useEffect(() => {
        const roomSocket = new WebSocketAsPromised(ROOM_SOCKET_URL);
        roomSocket
            .open()
            .then((response) => {
                console.log('room response', response)
            })
            .catch(error => {
                console.log("ERROR", error)
            })

        roomSocket.onMessage.addListener(data => {
            try {
                const jsonData = JSON.parse(data)
                setInitialWaitingRooms([...jsonData])
                setWaitingRooms([...jsonData])
            }
            catch (error) {}
        })
    }, [])



    return (
        <div className="full-page-container">
            <div className="chat-container">
                <div className="chat-sidebar">
                    <div className="chat-filter-box">
                        <Input
                            className={'chat-filter-input'}
                            placeholder={"Nhập tên để tìm kiếm"}
                            onChange={e => handleChangeKeyword(e.target.value)}
                        />
                        <div className={"chat-buttons-filter"}>
                            <Button onClick={() => handleChangeTabRoom('waitingRoom')} type={currentTabRoom === 'waitingRoom' ? 'primary' : 'default'} className={'chat-buttons-filter__btn mgr-5'}>Chưa xử lý ({waitingRooms.length})</Button>
                            <Button onClick={() => handleChangeTabRoom('myRoom')} type={currentTabRoom === 'myRoom' ? 'primary' : 'default'} className={'chat-buttons-filter__btn'}>DS của tôi ({rooms.length})</Button>
                        </div>
                    </div>
                    <div className="chat-user-list">
                        {currentTabRoom === 'waitingRoom' && (
                            <Spin spinning={loading}>
                                {waitingRooms.map((item) => (
                                    <RoomItem
                                        key={item.roomId}
                                        item={item}
                                        currentRoom={currentRoom}
                                        onSelectRoom={() => handleSelectRoom(item)}
                                    />
                                ))}
                            </Spin>
                        )}

                        {currentTabRoom === 'myRoom' && (
                            <Spin spinning={loading}>
                                {rooms.map((item) => (
                                    <RoomItem
                                        key={item.roomId}
                                        item={item}
                                        currentRoom={currentRoom}
                                        onSelectRoom={() => handleSelectRoom(item)}
                                    />
                                ))}
                            </Spin>
                        )}

                    </div>
                </div>


                <div className="chat-message">
                    <div className="chat-message-header">
                        <div className="chat-message-header__userinfo">
                            <img src={currentCustomer.avatar || defaultCustomerAvatar} className={'chat-message-header__userinfo__avatar'} />
                            <span className={'chat-message-header__userinfo__username'}>{_.get(currentRoom, 'customerProfile.username')}</span>
                            <span className="chat-message-header__userinfo__account-type">{_.get(currentCustomer, 'type', '---')}</span>
                            {!inMyRoom(currentCustomer.userId) && (
                                <Button
                                    loading={loading}
                                    disabled={loading}
                                    onClick={() => handleReceiveRoom(currentRoom.roomId)}
                                    className="chat-message-header__userinfo__txt"
                                >Nhận</Button>
                            )}
                        </div>
                        {_.get(currentCustomer, 'codeFlow') && (
                            <span className={'chat-message-header__target'}>
                                <b>Mục tiêu</b> <span className={'chat-message-header__target__value'}>{_.get(currentCustomer, 'codeFlow')}</span>
                            </span>
                        )}
                    </div>
                    <div className="chat-message-list" ref={chatMsgListRef}>
                        {messages.map((item) => (
                            <MessageItem
                                key={_.uniqueId('msgid_')}
                                item={item}
                                itMe={rocketUser.userId === item?.u?._id}
                                currentCustomer={currentCustomer}
                            />
                        ))}
                    </div>

                    <Spin spinning={loading}>
                        {currentRoom && inMyRoom(currentCustomer.userId) && (
                            <div className="chat-message-input-box">
                                <div className="chat-message-helper">
                                    <Spin spinning={loading}>
                                        <Upload
                                            showUploadList={false}
                                            onChange={handleChangeFile}
                                            beforeUpload={handleUploadFile}
                                            multiple={true}
                                        >
                                            <FontAwesomeIcon className={'pointer'} icon={Icon.faPaperclip} />
                                        </Upload>

                                        <FontAwesomeIcon
                                            className={'pointer mgl-10'}
                                            icon={Icon.faPaperPlane}
                                            onClick={() => handleSubmitMessage(currentMsg)}
                                        />
                                    </Spin>
                                </div>
                                <Input.TextArea
                                    autoFocus={true}
                                    value={currentMsg}
                                    onChange={e => setCurrentMsg(e.target.value)}
                                    onPressEnter={e => handleSubmitMessage(e.target.value)}
                                    className={'chat-message-input'}
                                    placeholder={"Enter để gửi"}
                                />
                            </div>
                        )}
                    </Spin>
                </div>
            </div>
        </div>
    )
}
