import React, {useEffect, useState} from 'react'
import {Breadcrumb} from "@themesberg/react-bootstrap";
import moment from 'moment';
import lodash from 'lodash';
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import {Button, Checkbox, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select} from "antd";
import {notificationObjectService} from "../../services/notification-object.service";
import {getAppUtilities, gotoItems, loopTypes} from "../../common/notification.constant";
import notificationService from "../../services/notification.service";
import {categoryService} from "../../services/category.service";
import {topicService} from "../../services/topic.service";
import {postService} from "../../services/post.service";
import {Link} from "react-router-dom";

const repeatTypes = [
    {
        code: "daily",
        name: "Ngày"
    },
    {
        code: "weekly",
        name: "Tuần"
    },
    {
        code: "monthly",
        name: "Tháng"
    }
]

export const NotificationDetail = (props) => {
    const [notificationObjects, setNotificationObjects] = useState([])
    const [subGoals, setSubGoals] = useState([])
    const [topics, setTopics] = useState([])
    const [loading, setLoading] = useState(false)
    const [posts, setPosts] = useState([])
    const [isShowLoopConfig, setIsShowLoopConfig] = useState(false)
    const defaultForm = {
        displayType: [],
        openScreen: {}
    }
    const [form, setForm] = useState({...defaultForm})

    const id = props.match.params.id

    const getNotificationDetail = () => {
        setLoading(true)
        notificationService.getDetail(id)
            .then(response => {
                setForm({
                    ...response.data,
                    displayType: response.data.displayType.split(','),
                    openScreen: lodash.get(response, 'data.openScreen') ? JSON.parse(response.data.openScreen) : {},
                    loopType: !response.data.interval ? 'none' : 'loop'
                })

                if (response.data.interval) {
                    setIsShowLoopConfig(true)
                }

            })
            .catch(() => {
                setForm({...defaultForm})
            })
    }

    useEffect(() => {
        getNotificationDetail()
    }, [])

    useEffect(() => {
        setLoading(true)
        notificationObjectService.filter({size: 1000})
            .then(response => setNotificationObjects(response.data))
            .catch(() => setNotificationObjects([]))
            .finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        categoryService.getSubGoal({size: 1000})
            .then(response => setSubGoals(response.data))
            .catch(() => setSubGoals([]))
    }, [])

    useEffect(() => {
        topicService.search({size: 1000})
            .then(response => setTopics(lodash.get(response, 'body.content') || []))
            .catch(() => setTopics([]))
    }, [])

    const handleChangeFieldForm = (field, value) => {
        if (field === 'openScreen.topic' && value) {
            setPosts([])
            setLoading(true)
            lodash.unset(form, 'openScreen.post')
            setForm({...form})
            postService.search({
                topicIds: [value]
            })
                .then(response => {
                    setPosts(lodash.get(response, 'body.content'))
                })
                .catch(() => setPosts([]))
                .finally(() => setLoading(false))
        }

        lodash.set(form, field, value)

        setForm({
            ...form
        })
    }

    const handleSearchPost = (filter) => {
        setLoading(true)
        postService.search({...filter})
            .then(response => {
                setPosts(lodash.get(response, 'body.content'))
            })
            .catch(() => setPosts([]))
            .finally(() => setLoading(false))
    }

    const handleChangeDisplayType = (value) => {
        if (form.displayType.indexOf(value) < 0) {
            form.displayType.push(value)
        }
        else {
            form.displayType.splice(form.displayType.indexOf(value), 1)
        }

        setForm({
            ...form
        })
    }

    return (
        <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block"
                                listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                        <Breadcrumb.Item to={Routes.DashboardOverview.path}><FontAwesomeIcon icon={Icon.faHome}/></Breadcrumb.Item>
                        <Breadcrumb.Item to={Routes.NotificationObject.path}><FontAwesomeIcon icon={Icon.faBell}/></Breadcrumb.Item>
                        <Breadcrumb.Item active>Chi tiết thông báo</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>

            <div className={'white-box pd-20'}>
                <Form labelCol={{sm: 24, xl: 6}} wrapperCol={{sm: 24, xl: 12}}>
                    <Divider orientation={"left"}>Cấu hình thông báo</Divider>

                    <Form.Item label={"Tên thông báo"}>
                        <Input value={form.name} onChange={e => handleChangeFieldForm('name', e.target.value)} />
                    </Form.Item>

                    <Form.Item label={"Đối tượng nhận thông báo"} required={true}>
                        <Select
                            value={form.customerGroupDefinitionId}
                            onChange={value => handleChangeFieldForm('customerGroupDefinitionId', value)}
                        >
                            {notificationObjects.map(item => (
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label={"Hiển thị"}>
                        <Row gutter={20}>
                            <Col span={6}>
                                <Form.Item noStyle>
                                    <Checkbox checked={form.displayType.indexOf('onscreen') >= 0} value={'onscreen'} onChange={e => handleChangeDisplayType(e.target.value)}>Ngoài màn hình</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item noStyle>
                                    <Checkbox checked={form.displayType.indexOf('inapp') >= 0} value={'inapp'} onChange={e => handleChangeDisplayType(e.target.value)}>In app(quả chuông)</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item label={"Thời gian gửi"}>
                        <DatePicker
                            showTime
                            disabledDate={(current) => {
                                return current && current < moment.now();
                            }}
                            format={"YYYY-MM-DD HH:mm:ss"}
                            value={form.sendingTime ? moment(form.sendingTime) : null}
                            onChange={value => handleChangeFieldForm('sendingTime', value ? value.toISOString() : null)}
                        />
                    </Form.Item>

                    <Form.Item label={"Lặp lại"}>
                        <Select
                            allowClear={true}
                            value={lodash.get(form, 'loopType')}
                            onChange={value => handleChangeFieldForm('loopType', value)}
                        >
                            {loopTypes.map(item => (
                                <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {isShowLoopConfig && (
                        <>
                            <Divider orientation={"left"}>Lặp lại</Divider>

                            <Form.Item label={"Ngày bắt đầu"}>
                                <DatePicker
                                    showTime
                                    format={"YYYY-MM-DD HH:mm:ss"}
                                    disabledDate={(current) => {
                                        return current && current < moment.now();
                                    }}
                                    value={form.repeatTimeBegin ? moment(form.repeatTimeBegin) : null}
                                    onChange={value => handleChangeFieldForm('repeatTimeBegin', value ? value.toISOString() : null)}
                                />
                            </Form.Item>
                            <Form.Item label={"Lặp lại sau mỗi"}>
                                <Row gutter={20}>
                                    <Col span={12}>
                                        <Form.Item noStyle>
                                            <InputNumber value={form.interval} onChange={value => handleChangeFieldForm('interval', value)} placeholder={'n'} className={'w100p'} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item noStyle>
                                            <Select
                                                value={form.repetitionInterval}
                                                onChange={value => handleChangeFieldForm('repetitionInterval', value)}
                                                placeholder={'ngày/tuần/tháng'}
                                                allowClear={true}
                                            >
                                                {repeatTypes.map(item => (
                                                    <Select.Option key={item.code}>{item.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </>
                    )}

                    <Divider orientation={"left"}>Nội dung thông báo</Divider>
                    <Form.Item label={"Tiêu đề"}>
                        <Input value={form.title} onChange={e => handleChangeFieldForm('title', e.target.value)} />
                    </Form.Item>
                    <Form.Item label={"Nội dung"}>
                        <Input.TextArea value={form.content} onChange={e => handleChangeFieldForm('content', e.target.value)} />
                    </Form.Item>
                    <Form.Item label={"Goto"}>
                        <Select value={form.goTo} onChange={value => handleChangeFieldForm('goTo', value)}>
                            {gotoItems.map(item => (
                                <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {form.goTo === 'home' && (
                        <Form.Item label={"Lộ trình"}>
                            <Select
                                allowClear={true}
                                value={lodash.get(form, 'openScreen.subGoal')}
                                showSearch={true}
                                filterOption={false}
                                onChange={value => handleChangeFieldForm('openScreen.subGoal', value)}
                            >
                                {subGoals.map(item => (
                                    <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}

                    {form.goTo === 'insights' && (
                        <>
                            <Form.Item label={"Chủ đề"}>
                                <Select
                                    allowClear={true}
                                    value={lodash.get(form, 'openScreen.topic')}
                                    showSearch={true}
                                    onChange={value => handleChangeFieldForm('openScreen.topic', value)}
                                >
                                    {topics.map(item => (
                                        <Select.Option key={item.topicId} value={item.topicId}>{item.topicName}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label={"Bài viết"}>
                                <Select
                                    value={lodash.get(form, 'openScreen.post')}
                                    showSearch={true}
                                    allowClear={true}
                                    onSearch={value => handleSearchPost({ topicIds: lodash.get(form, 'openScreen.topic'), keyword: value})}
                                    onChange={value => handleChangeFieldForm('openScreen.post', value)}
                                >
                                    {posts.map(item => (
                                        <Select.Option key={item.postsId} value={item.postsId}>{item.postsName}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </>
                    )}

                    {form.goTo === 'app' && (
                        <Form.Item label={"Tiện ích"}>
                            <Select
                                allowClear={true}
                                value={lodash.get(form, 'openScreen.utility')}
                                showSearch={true}
                                onChange={value => handleChangeFieldForm('openScreen.utility', value)}
                            >
                                {getAppUtilities().map(item => (
                                    <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}

                    <Form.Item wrapperCol={{offset: 6, xs: 12}}>
                        <Button loading={loading} disabled={loading} type={'primary'}>
                            <Link to={'/notifications'} style={{color: "#fff"}}>Quay lại trang chủ</Link>
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
