import React from 'react'
import {Button, Col, Form, Row, Select} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import {NOTIFICATION_OBJECT_ACCOUNT_TYPE_ARR, NOTIFICATION_OBJECT_TYPE_ARR} from "../../common/notification.constant";

export const Filter = (props = {}) => {
    const [form] = Form.useForm()

    const handleSubmit = (filter = {}) => {
        props.onFilter({...form.getFieldsValue(), ...filter})
    }

    const handleClear = () => {
        form.resetFields()
        props.onFilter({})
    }

    return (
        <div className={'white-box white-box--filter'}>
            <Form form={form} labelCol={{span: 6}} wrapperCol={{span: 16}}>
                <Row gutter={20}>
                    <Col span={8}>
                        <Form.Item label={"Phân loại"} name={'customerClassification'}>
                            <Select onChange={value => handleSubmit({customerClassification: value})}>
                                {NOTIFICATION_OBJECT_TYPE_ARR.map(item => (
                                    <Select.Option value={item.code} key={item.code}>{item.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={"Loại tài khoản"} name={'customerType'}>
                            <Select onChange={value => handleSubmit({customerType: value})}>
                                {NOTIFICATION_OBJECT_ACCOUNT_TYPE_ARR.map(item => (
                                    <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Button onClick={handleSubmit} icon={<FontAwesomeIcon icon={Icon.faSearch} />}> Tìm kiếm</Button>
                        <Button onClick={handleClear} type={'link'}>Xoá lọc</Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
