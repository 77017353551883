import btoa from 'btoa';
import APIService from './api.services';
import qs from 'querystring';

class UserService extends APIService {

    /**
     * Đăng nhập
     * @param username Username hoặc email
     * @param password Mật khẩu
     * @param type Username = 1, Email = 2
     * @returns 
     */
    async login(username = '', password = '', type = 1) {
        this.instance.defaults.headers.common['pwd'] = btoa(password);
        const { body } = await this.request('POST', `account-managers/login?type=${type}`, { uname: username });
        this.store.accessToken = body?.access_token;
        delete this.instance.defaults.headers.common['pwd'];
        return body;
    }

    async loginGoogle(token) {
        const { body } = await this.request('GET', `account-managers/login-google?id_token=${token}`);
        this.store.accessToken = body?.access_token;
        return body;
    }

    async loginFacebook(token) {
        const { body } = await this.request('GET', `account-managers/login-facebook?id_token=${token}`);
        this.store.accessToken = body?.access_token;
        return body;
    }

    async register(newUser) {
        const resp = await this.request('POST', 'account-managers/create', newUser);
        this.store.accessToken = resp?.body?.access_token;
        return resp;
    }

    async passwordNew(passwordNew, passwordAccuracy, token = null) {
        const resp = await this.request('POST', `account-managers/password-new?tokenEmail=${token}`, {
            passwordNew,
            passwordAccuracy
        });
        return resp;
    }

    async getMe() {
        const me = await this.request('GET', `account-managers/profile`);
        this.store.profile = me.body;
        return me;
    }

    async updateProfileSocial(user) {
        const resp = await this.request('PUT', `account-managers/user`, user);
        this.store.profile = resp?.body;
        return resp;
    }

    async updateProfile(user) {
        const resp = await this.request('PUT', `account-managers/update/profile`, user);
        this.store.profile = resp?.body;
        return resp;
    }

    async uploadAvatar(userId, srcBase64) {
        const resp = await this.request('POST', `/account-managers/${userId}/updateAvatar`, srcBase64);
        // this.store.profile = resp?.body;
        return resp;
    }

    async changePassword(data) {
        const resp = await this.request('PUT', `account-managers/change-pass`, data);
        this.store.profile = resp?.body;
        return resp;
    }

    async forgotPassword(email) {
        return await this.request('GET', `account-managers/reset-password?email=${email}`)
    }

    // Admin
    async getListUsers(paramsSearch) {
        return await this.request('GET', `account-managers?${qs.stringify(paramsSearch)}`);
    }

    async search(paramsSearch) {
        return await this.request('POST', `account-managers/system/search?${qs.stringify(paramsSearch)}`);
    }

    async getUserById(userId) {
        return await this.request('GET', `account-managers/${userId}`);
    }

    async getRoles() {
        return await this.request('GET', `account-managers/role`);
    }

    async adminCreateUser(user) {
        return await this.request('POST', `account-managers`, user);
    }

    async adminUpdateUser(user) {
        return await this.request('PUT', `account-managers`, user);
    }

    async logout() {

    }

}

export default UserService;