import React from 'react'
import {Card, Spinner, Table} from "@themesberg/react-bootstrap";
import {Paging} from "../../components/Paging";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import moment from "moment";

export const List = ({searchParams, onChangePage, data}) => {
    const loading = useSelector(({customer}) => customer.loading)
    if (loading) {
        return <div className={'text-center'}><Spinner animation="border" /></div>
    }

    return (
        <>
            <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Body>
                    <Table hover className="user-table align-items-center">
                        <thead>
                            <tr>
                                <th className="border-bottom">ID</th>
                                <th className="border-bottom">Name</th>
                                <th className="border-bottom">Phone Number</th>
                                <th className="border-bottom">Username</th>
                                <th className="border-bottom">Email</th>
                                <th className="border-bottom">Status</th>
                                <th className="border-bottom">Type</th>
                                <th className="border-bottom">Expired Date</th>
                                <th className={'border-bottom'}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.items.map(item => (
                            <tr key={item.userId}>
                                <td>{item.userId}</td>
                                <td>{item.fullname}</td>
                                <td>{item.phoneNumber}</td>
                                <td>{item.username}</td>
                                <td>{item.email}</td>
                                <td>{item.status === 1 ? 'Activated' : 'Unactivated'}</td>
                                <td>{item.type}</td>
                                <td>{item.expiredDate ? moment(item.expiredDate).format("DD/MM/YYYY HH:mm:ss") : '--'}</td>
                                <td>
                                    <Link className={'pointer'} to={`/customers/${item.userId}`}><FontAwesomeIcon icon={faEye} className="me-2" /> Chi tiết</Link>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Card.Body>

                <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                    <Paging
                        searchParams={searchParams}
                        onChangePage={onChangePage}
                        data={data}
                    />
                </Card.Footer>
            </Card>
        </>
    )
}
