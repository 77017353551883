import React, {useEffect, useState} from 'react'
import {Breadcrumb, Button, Card, Col, Form, Row, Spinner, Table} from "@themesberg/react-bootstrap";
import lodash from 'lodash';
import {Modal} from 'antd';
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import CustomerService from "../../services/customer.services";
import moment from "moment";
import Datetime from "react-datetime";
import CategoryService from "../../services/category.service";
import {getCustomerCodeFlow} from "../../contains/common";
import {toast} from "react-toastify";

const customerService = new CustomerService()
const categoryService = new CategoryService()

export const CustomerDetail = (props) => {

    const {id} = props.match.params
    const [loading, setLoading] = useState(false)
    const [customer, setCustomer] = useState(null)
    const [upVipSuccess, setUpVipSuccess] = useState(false)
    const [showBoxUpgradeAccount, setShowBoxUpgradeAccount] = useState(false)
    const [dataUpgradeAccount, setDataUpgradeAccount] = useState({
        packageCode: "",
        userId: id,
        activatedTime: ""
    })
    const [vipPackages, setVipPackages] = useState([])

    const handleUpgradeAccountVisibleChange = (visible) => {
        setShowBoxUpgradeAccount(visible)
    }

    const handleChangeActivateDate = (value) => {
        setDataUpgradeAccount({
            ...dataUpgradeAccount,
            activatedTime: value.format('x')
        })
    }

    const handleChangeVipPackage = (value) => {
        setDataUpgradeAccount({
            ...dataUpgradeAccount,
            packageCode: value
        })
    }

    const handleUpgradeAccount = () => {
        if (!dataUpgradeAccount.packageCode) {
            toast.error("Chưa chọn gói");
            return
        }

        if (!dataUpgradeAccount.activatedTime) {
            toast.error("Chưa chọn ngày kích hoạt");
            return
        }

        Modal.confirm({
            title: customer.type !== 'Vip' ? 'Bạn có chắc muốn nâng cấp tài khoản cho User này?' : 'Đây là tài khoản VIP. Bạn có chắc chắn muốn thay đổi?',
            onOk: () => {
                setLoading(true)
                customerService.upVip(dataUpgradeAccount)
                    .then(() => {
                        setLoading(false)
                        toast.success("Cập nhật thành công")
                        setUpVipSuccess(true)
                    })
                    .catch((error) => {
                        setLoading(false)
                        toast.error(lodash.get(error, 'response.data') || "Có lỗi xảy ra")
                    })
            },
            okButtonProps: {
                loading: loading,
                disabled: loading
            },
            cancelButtonProps: {
                loading: loading,
                disabled: loading
            }
        })
    }

    useEffect(() => {
        setLoading(true)
        customerService.getById(id)
            .then(response => {
                setLoading(false)
                setCustomer(response.data)
            })
            .catch(() => {
                setLoading(false)
            })
    }, [id, upVipSuccess])

    useEffect(() => {
        categoryService.getVipPackages()
            .then(response => {
                setVipPackages(response.data)
            })
    }, [id])

    if (loading) {
        return <div className={'text-center'}><Spinner animation="border" /></div>
    }

    return (
        <div className={'customer-detail'}>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item to={Routes.DashboardOverview.path}>
                            <FontAwesomeIcon icon={faHome} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Khách hàng</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Khách hàng</h4>
                    <p className="mb-0">Chi tiết khách hàng.</p>
                </div>
            </div>

            <Row>
                <Col xs={24} md={8}>
                    <Card border="light" className="table-wrapper table-responsive shadow-sm">
                        <Card.Body>
                            <Card.Title>Thông tin khách hàng</Card.Title>
                            <Table hover bordered className="customer-table align-items-center">
                                <tbody>
                                    <tr>
                                        <td>Id</td>
                                        <td>{lodash.get(customer, 'userId')}</td>
                                    </tr>
                                    <tr>
                                        <td>Tên</td>
                                        <td>{lodash.get(customer, 'fullname')}</td>
                                    </tr>
                                    <tr>
                                        <td>Số điện thoại</td>
                                        <td>{lodash.get(customer, 'phoneNumber')}</td>
                                    </tr>
                                    <tr>
                                        <td>Username</td>
                                        <td>{lodash.get(customer, 'username')}</td>
                                    </tr>
                                    <tr>
                                        <td>Facebook Id</td>
                                        <td>{lodash.get(customer, 'facebookId')}</td>
                                    </tr>
                                    <tr>
                                        <td>Apple Id</td>
                                        <td>{lodash.get(customer, 'appleId')}</td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                        <td>{lodash.get(customer, 'status') === 1 ? 'Activated' : 'Unactivated'}</td>
                                    </tr>
                                    <tr>
                                        <td>Type</td>
                                        <td>{lodash.get(customer, 'type')}</td>
                                    </tr>
                                    <tr>
                                        <td>Code Flow</td>
                                        <td>{lodash.get(getCustomerCodeFlow(lodash.get(customer, 'codeFlow')), 'name')}</td>
                                    </tr>
                                    <tr>
                                        <td>Expired Date</td>
                                        <td>{lodash.get(customer, 'expiredDate') ? <div className={'text-danger'}>{moment(customer.expiredDate).format('DD/MM/YYYY HH:mm:ss')}</div> : '--'}</td>
                                    </tr>
                                    <tr>
                                        <td>Ngày dự sinh</td>
                                        <td>{lodash.get(customer, 'dateOfBirthEstimateDoctor') ? moment(customer.dateOfBirthEstimateDoctor).format("DD/MM/YYYY HH:mm:ss") : '--'}</td>
                                    </tr>
                                    <tr>
                                        <td>Ngày thực sinh</td>
                                        <td>{lodash.get(customer, 'dateOfBirth') ? moment(customer.dateOfBirth).format('DD/MM/YYYY HH:mm:ss') : '--'}</td>
                                    </tr>
                                    <tr>
                                        <td>Ngày đầu chu kỳ</td>
                                        <td>{lodash.get(customer, 'firstDayOfPeriod') ? moment(customer.firstDayOfPeriod).format('DD/MM/YYYY HH:mm:ss') : '--'}</td>
                                    </tr>
                                    <tr>
                                        <td>Chu kỳ kinh</td>
                                        <td>{lodash.get(customer, 'cycleTime')} ngày</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={24} md={4}>
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Chức năng</Card.Title>
                            <Button onClick={() => handleUpgradeAccountVisibleChange(true)} variant={'primary'} className={'mgr-15'}>Nâng cấp tài khoản</Button>
                            <Button variant={'link'} className={'text-danger'}>Khoá tài khoản</Button>

                            {showBoxUpgradeAccount && (
                                <Form className={'mgt-10'}>
                                    <Form.Group>
                                        <Form.Label>Gói sử dụng</Form.Label>
                                        <Form.Select onChange={e => handleChangeVipPackage(e.target.value)}>
                                            <option value="">--</option>
                                            {vipPackages.map(item => (
                                                <option key={item.id} value={item.code}>{item.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group className={'mgt-10'}>
                                        <Form.Label>Ngày kích hoạt</Form.Label>
                                        <Datetime
                                            dateFormat={"DD/MM/YYYY"}
                                            timeFormat={false}
                                            closeOnSelect={false}
                                            value={dataUpgradeAccount.activatedTime ? moment(dataUpgradeAccount.activatedTime).format("DD/MM/YYYY") : ""}
                                            onChange={handleChangeActivateDate}
                                        />
                                    </Form.Group>

                                    <Form.Group className={'mgt-10'}>
                                        <Button onClick={handleUpgradeAccount} className={'mgr-10'}>Cập nhật</Button>
                                        <span className={'text-blue pointer d-inline-block'} onClick={() => handleUpgradeAccountVisibleChange(false)} type={'link'}>Đóng</span>
                                    </Form.Group>
                                </Form>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
