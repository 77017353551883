import React from "react";
import ReactQuill from "react-quill";
import FileService from "../services/file.service";

const fileService = new FileService();

class Editor extends React.Component {

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    imageHandler = () => {
        const range = this.ref.getEditor().getSelection();
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
        input.onchange = async () => {
            const file = input.files ? input.files[0] : null;
            this.ref.getEditor().insertText(range.index, 'đang tải ảnh lên...', 'silent');
            try {
                const resp = await fileService.uploadImage(file);
                const src = resp.linkUrl[0];
                if (src) {
                    this.ref.getEditor().deleteText(range.index, 'đang tải ảnh lên...'.length, 'silent');
                    this.ref.getEditor().insertEmbed(range.index, 'image', src, "silent");
                }
            } catch (error) {
                alert('error_when_upload_image');
                this.ref.getEditor().deleteText(range.index, 'đang tải ảnh lên...'.length, 'silent');
            }
        }
    }

    formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ]

    modules = {
        toolbar: {
            table: true,
            container: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],

                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction

                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }],
                [{ 'align': [] }],

                ['link', 'image', 'video'],                       // show image, link, video

                ['clean']                                         // remove formatting button
            ],
            handlers: {
                image: this.imageHandler.bind(this)
            }
        }

    };

    render() {
        return (
            <ReactQuill {...this.props} ref={(e) => { this.ref = e; }} modules={this.modules} formats={this.formats} />
        );
    }
}

export default Editor;