import APIService from './customAPI.services';

class ProfessionalsService extends APIService {

    constructor() {
        super();
    }

    async listProfessions(query) {
        return await this.request('GET', `professionals${query || ''}`, {});
    }

}
const professionalsService = new ProfessionalsService();
export default professionalsService