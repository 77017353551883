import APIService from './api.services';

class TopicService extends APIService {

    constructor() {
        super();
        this.modulePath = 'topic';
    }
}

export const topicService = new TopicService()

export default TopicService;
