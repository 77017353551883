import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {Button, Form, FormControl, Modal} from '@themesberg/react-bootstrap';
import _ from 'lodash';
import {Field, Formik} from "formik";
import * as Yup from 'yup';
import {fetchAll as targetFetchAll} from "../../store/target";
import {fetchAll as topicFetchAll} from "../../store/topic";
import {fetchAll as catFetchAll} from "../../store/category";
import {search as searchHashtag} from "../../store/hashtag";
import {hideModal, onCreate, onUpdate, setActiveItem} from "../../store/post";
import {DEVICE_FORMAT, PORTRAIT_ORIENTATION, POST_FORMAT, POST_TYPE, SEARCH_PARAMS} from "../../contains/common";

import {SelectField} from "../../components/SelectField";
import Editor from "../../components/Editor";

import mammoth from 'mammoth';

import {faCloudUploadAlt, faTrash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import HashTagService from "../../services/hashtag.service";
import TargetService from "../../services/target.service";
import CategoryService from "../../services/category.service";
import TopicService from "../../services/topic.service";
import FileService from "../../services/file.service";
import ReactHlsPlayer from 'react-hls-player';
import Select from "react-select";
import ProfessionalsService from "../../services/professionals.service";
import CampaignService from "../../services/campaign.service";
import {ERROR_MESSAGE} from "../../contains/errors";
import {Col, Row, Spin} from "antd";

export default () => {

    const dispatch = useDispatch();
    let timeOut = ''
    const selectRef = useRef();
    const inputFileRef = useRef()

    const isOpenModal = useSelector(({ post }) => post.isOpenModal, _.isEqual);
    const activeItem = useSelector(({ post }) => post.item, _.isEqual);

    const listTargets = useSelector(({ targets }) => targets.table.items, _.isEqual);
    const listTopics = useSelector(({ topic }) => topic.table.items, _.isEqual);
    const listCategories = useSelector(({ category }) => category.table.items, _.isEqual);
    const listHashtag = useSelector(({ hashtag }) => hashtag.table.items.map(x => {
        return { value: x.hashtagId, label: x.hashtagName }
    }), _.isEqual);

    const fileService = new FileService();
    const hastagService = new HashTagService();

    const [professionals, setProfessionals] = useState([]);
    const [campaigns, setCampaigns] = useState([]);
    const [redirectUrl, setRedirectUrl] = useState('');
    const [isOpenModalRedirect, setIsOpenModalRedirect] = useState(false);
    const [isFileUploading, setIsFileUploading] = useState(false)

    useEffect(() => {
        (async () => {
            await dispatch(targetFetchAll())
            await dispatch(searchHashtag({ size: 9999 }))
        })();
    }, [])

    useEffect(() => {
        (async () => {
            if (activeItem.targetId) {
                await dispatch(catFetchAll({
                    targetId: activeItem.targetId
                }))
                await dispatch(topicFetchAll({
                    categoryId: activeItem.categoryId,
                    targetId: activeItem.targetId
                }))
                getListCampaign()
            }
        })();
        // console.log('vào đây')
    }, [activeItem])


    useEffect(() => {
        getListProfessions()
    }, [])

    const getListProfessions = async (searchString) => {
        let queryString = `?page=0&size=${100}&sort=createdDate:desc${searchString ? `&name=${encodeURIComponent(searchString)}` : ''}`

        const resp = await ProfessionalsService.listProfessions(queryString);
        // const headers = resp.headers || {};
        setProfessionals(resp.data || [])

        // this.setState({
        //     foods: resp.data,
        //     metadata: {
        //         pageCount: parseFloat(headers['x-page-count']),
        //         page: parseFloat(headers['x-page-number']),
        //         size: parseFloat(headers['x-page-size']),
        //         total: parseFloat(headers['x-total-count']),
        //     },
        //     isLoading: false,
        // })
    }


    const onInputSelectChange = (value) => {
        if (timeOut) {
            clearTimeout(timeOut);
        }

        timeOut = setTimeout(() => {
            getListProfessions(value)
        }, 500)
    }

    const getListCampaign = async () => {
        let queryString = `?postsId=${activeItem?.postsId}`

        const resp = await CampaignService.listCampaign(queryString);
        // console.log('resp', resp, activeItem)
        setCampaigns(resp?.data?.body || [])
    }

    const createCampaign = async () => {

        if (!redirectUrl) return
        if (!redirectUrl.trim()) return

        const body = {
            "postsId": activeItem?.postsId,
            "redirectUrl": redirectUrl,
            "urlSlug": activeItem?.slug
        }
        const resp = await CampaignService.createCampaign(body);
        // console.log('resp', resp)
        setRedirectUrl('')
        getListCampaign()
    }

    const deleteCampaign = async (item) => {
        const resp = await CampaignService.removeCampaign(item);
        getListCampaign()
    }

    const changeToSlug = (string = "") => {
        let slug = ''

        //Đổi chữ hoa thành chữ thường
        slug = string.toLowerCase();

        //Đổi ký tự có dấu thành không dấu
        slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
        slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
        slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
        slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
        slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
        slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
        slug = slug.replace(/đ/gi, 'd');
        //Xóa các ký tự đặt biệt
        slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi, '');
        //Đổi khoảng trắng thành ký tự gạch ngang
        slug = slug.replace(/ /gi, "-");
        //Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
        //Phòng trường hợp người nhập vào quá nhiều ký tự trắng
        slug = slug.replace(/\-\-\-\-\-/gi, '-');
        slug = slug.replace(/\-\-\-\-/gi, '-');
        slug = slug.replace(/\-\-\-/gi, '-');
        slug = slug.replace(/\-\-/gi, '-');
        //Xóa các ký tự gạch ngang ở đầu và cuối
        slug = '@' + slug + '@';
        slug = slug.replace(/\@\-|\-\@|\@/gi, '');
        //In slug ra textbox có id “slug”
        return slug
    }

    // Upload file docx
    const handleFileUpload = async (e, setFieldValue) => {

        const file = e.target.files[0];
        var fileSize = file.size / 1024 / 1024; // in MiB
        if (fileSize > 20) {
            return alert('Dung lượng ảnh lớn hơn 20 MiB, vui lòng xem lại nội dung bài viết');
        }

        const { name } = file;
        const [target, cat, topic] = name.match(/([0-9A-Z]+)_/g).map(x => x.replace('_', ''));

        onSearchTarget({
            target, cat, topic
        }, setFieldValue);

        try {
            var reader = new FileReader();
            const options = {
                convertImage: mammoth.images.imgElement(function (image) {
                    return image.read("base64").then(async (imageBuffer) => {
                        const sourceImage = "data:" + image.contentType + ";base64," + imageBuffer;
                        return {
                            src: await handleImageUploadInDocx(sourceImage, Date.now())
                        };
                    });
                })
            };
            reader.onload = async function () {
                const doc = await mammoth.convertToHtml({ arrayBuffer: this.result }, options);
                const html = doc?.value;

                if (html) {
                    const div = document.createElement('div');
                    div.innerHTML = html;

                    const h1 = div.firstChild;
                    const pNote = Array.from(div.querySelectorAll('p')).find(x => x.textContent.indexOf('Tài liệu tham khảo:') >= 0);
                    const pTags = Array.from(div.querySelectorAll('p')).find(x => x.textContent.search(/(#[a-zA-Z0-9]+)/gi) >= 0);
                    const ol = div.querySelector('ol');

                    h1 && div.removeChild(h1);
                    pTags && div.removeChild(pTags);
                    pNote && div.removeChild(pNote);
                    ol && div.removeChild(ol);

                    h1 && setFieldValue('postsName', h1.textContent);
                    html && setFieldValue('postsContent', div.innerHTML);

                    // auto create tag
                    if (pTags) {
                        const tags = pTags?.textContent?.match(/(#[a-zA-Z0-9]+)/gi);
                        // const hashtagIds = await tags.map(async tag => await onCreateHashtag(tag));
                        // Promise.all(hashtagIds).then(r => {
                        //     console.log(r);
                        //     setFieldValue('hashtagIds', r);
                        // });

                        let newString = ""
                        if (Array.isArray(tags)) {
                            tags.map(x => {
                                newString = newString + x + ' '
                            })
                        }
                        try {
                            const resp = await hastagService.convertHashTag(newString);
                            let arrRes = resp?.body.map(x => ({value: x.hashtagId, label: x.hashtagName}))
                            setFieldValue('hashtagIds', arrRes);
                        } catch (error) {
                            console.log(error);
                        }

                    }
                }
            }
            reader.readAsArrayBuffer(file);
        } catch (error) {
            console.log(error);
        }
    }

    const handleImageUploadInDocx = async (dataUrl, fileName) => {
        const res = await fetch(dataUrl);
        const blob = await res.blob();
        const file = new File([blob], fileName, { type: 'image/png' });
        try {
            const resp = await fileService.uploadImage(file);
            return resp.linkUrl[0];
        } catch (error) {
            return dataUrl;
        }
    }

    const handleFileUploadByPostsFormat = async (e, setFieldValue, postFormat, props) => {
        const file = e.target.files[0];
        var fileSize = file.size / 1024 / 1024; // in MiB
        if (fileSize > 500) {
            return alert('Dung lượng file lớn hơn 500 MiB, vui lòng chọn file khác.');
        }

        const { name } = file;

        if (Array.isArray(name.match(/([0-9A-Z]+)_/g))) {

            const [target, cat, topic] = name.match(/([0-9A-Z]+)_/g).map(x => x.replace('_', ''));

            onSearchTarget({
                target, cat, topic
            }, setFieldValue);

            let pName = name?.split ? name?.split('_')[3] : ''

            if (pName) {
                let na = pName.split('.')[0]
                setFieldValue('postsName', na)
            }
        }

        let body = {
            objectType: "posts",
            displayType: 6
        }

        // objectType: "posts"
        // objectId: ID bài viết
        // displayType: 1(thumbnail), 2( icon), 3(Cover), 4(video), 5(Audio), 6(Infographic), 7(avatar)
        switch (postFormat) {
            case '2':
            case 2:
                body.displayType = 4
                break;
            default:
                body.displayType = 6
                break;
        }

        if (body.displayType === 4 && props.values.fileType === 'video') {
            body.orientation = _.get(props, 'values.orientation')
        }

        try {
            setIsFileUploading(true)
            const resp = await fileService.uploadImage(file, body);

            if (resp.attachId) {
                setTimeout(() => {
                    setFieldValue('attachIds', [resp.attachId]);
                    setFieldValue('attachs', [resp]);
                }, 10000)
                // setAttachment(resp)
            }
            setIsFileUploading(false)
        } catch (error) {
            setIsFileUploading(false)
            console.log(error);
        }
    }

    const handleImageUpload = async (e, setFieldValue, prop) => {
        const file = e.target.files[0];
        var fileSize = file.size / 1024 / 1024; // in MiB
        if (fileSize > 5) {
            return alert('Dung lượng ảnh lớn hơn 5 MiB, vui lòng chọn ảnh khác.');
        }

        let body = {
            objectType: "posts",
            displayType: 7
        }

        // objectType: "posts"
        // objectId: ID bài viết
        // displayType: 1(thumbnail), 2( icon), 3(Cover), 4(video), 5(Audio), 6(Infographic), 7(avatar)
        switch (prop) {
            case 'postsThumbnail':
                body.displayType = 1
                break;
            case 'postsIcon':
                body.displayType = 2
                break;
            default:
                body.displayType = 7
                break;
        }

        try {
            const resp = await fileService.uploadImage(file, body);
            if (Array.isArray(resp.linkUrl)) {
                setFieldValue(prop, resp.linkUrl[0]);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onCreateHashtag = async (keyword) => {
        try {
            const { body } = await hastagService.create({ hashtagName: keyword });
            const tag = body;
            return { value: tag.hashtagId.toString(), label: tag.hashtagName };
        } catch (error) {
            console.error(`onCreateHashtag ~ `, error);
            return await onSearchHashtag(keyword);
        }
    }

    const onSearchHashtag = async (keyword) => {
        try {
            const { body } = await hastagService.search({ ...SEARCH_PARAMS, keyword, size: 1, properties: 'createdDate' });
            const tag = body.content.shift();
            return { value: tag.hashtagId.toString(), label: tag.hashtagName };
        } catch (error) {
            console.error(`onSearchHashtag ~ `, error);
            return;
        }
    }

    const onPasteHashtag = async (e, props, cb) => {
        // console.log('onPasteHashtag', e)
        if (e?.keyCode === 13) {
            if (e?.target?.value) {
                try {
                    const resp = await hastagService.convertHashTag(e.target.value);
                    let arrRes = resp?.body.map(x => ({value: x.hashtagId, label: x.hashtagName}))
                    // console.log('onPasteHashtag',  _.unionBy(arrRes, props.values.hashtagIds, 'value'))
                    props.setFieldValue('hashtagIds', _.unionBy(arrRes, props.values.hashtagIds, 'value'));
                    if (cb) cb();
                } catch (error) {
                    console.log(error);
                }
            }
        } else if ((e?.metaKey || e?.ctrlKey) && e?.keyCode === 86) {
            if (navigator?.clipboard?.readText) {
                let text = await navigator?.clipboard?.readText()
                if (text) {
                    try {
                        const resp = await hastagService.convertHashTag(text);
                        let arrRes = resp?.body.map(x => ({value: x.hashtagId, label: x.hashtagName}))
                        props.setFieldValue('hashtagIds', _.unionBy(arrRes, props.values.hashtagIds, 'value'));
                        if (cb) cb();
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
        }
    }

    // auto choose select box
    const onSearchTarget = async (groupPrefix, setFieldValue) => {
        const prefix = groupPrefix.target;

        const targetService = new TargetService();
        try {
            const { body } = await targetService.readAll({ prefix });
            const [targetItem] = body;
            if (targetItem) {
                setFieldValue('targetId', targetItem.targetId);
                await dispatch(catFetchAll({
                    targetId: targetItem.targetId
                }))
                await onSearchCategory(groupPrefix, targetItem.targetId, setFieldValue);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onSearchCategory = async (groupPrefix, targetId, setFieldValue) => {
        const prefix = groupPrefix.cat;

        const catService = new CategoryService();
        try {
            const { body } = await catService.readAll({ targetId, prefix });
            const [categoryItem] = body;
            if (categoryItem) {
                setFieldValue('categoryId', categoryItem.categoryId);
                await dispatch(topicFetchAll({
                    categoryId: categoryItem.categoryId,
                    targetId: targetId
                }));
                await onSearchTopic(groupPrefix, categoryItem.categoryId, setFieldValue);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onSearchTopic = async (groupPrefix, categoryId, setFieldValue) => {
        const prefix = groupPrefix.topic;

        const topicService = new TopicService();
        try {
            const { body } = await topicService.readAll({ categoryId, prefix });
            const [topicItem] = body;
            if (topicItem) {
                setFieldValue('topicId', topicItem.topicId);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const closeModal = () => {
        // setAttachment('')
        dispatch(hideModal());
    }

    const onChooseTarget = (e, { handleChange }) => {
        handleChange(e);
        dispatch(setActiveItem({
            categoryId: '',
            targetId: e?.currentTarget?.value
        }));
    }

    const onChooseCategory = (e, { values, handleChange }) => {
        handleChange(e);
        dispatch(setActiveItem({
            categoryId: e?.currentTarget?.value,
            targetId: values.targetId
        }));
    }

    const onValidate = Yup.object().shape({
        postsName: Yup.string().required(ERROR_MESSAGE.POST_NAME_REQUIRED),
        slug: Yup.string().required(ERROR_MESSAGE.FIELD_REQUIRED),
        postsContent: Yup.string().required(ERROR_MESSAGE.POST_CONTENT_REQUIRED),
        type: Yup.string().required(ERROR_MESSAGE.POST_TYPE_REQUIRED),
        targetId: Yup.string().required(ERROR_MESSAGE.TARGET_OPTION_REQUIRED),
        categoryId: Yup.string().required(ERROR_MESSAGE.CATEGORY_OPTION_REQUIRED),
        topicId: Yup.string().required(ERROR_MESSAGE.TOPIC_OPTION_REQUIRED)
    });

    const onSubmit = async (values) => {
        let dataPost = {
            'postsId': values.postsId,
            'postFormat': parseFloat(values.postFormat) || 1,
            'postsName': values.postsName,
            'slug': values.slug,
            'postsContent': values.postsContent,
            'postsAvatar': values.postsAvatar,
            'postsThumbnail': values.postsThumbnail,
            'postsIcon': values.postsIcon,
            'type': values.type,
            'topicId': values.topicId,
            'targetId': values.targetId,
            'categoryId': values.categoryId,
            'professionalId': values?.professional ? values?.professional?.id : '',
            'order': values.order,
            'hashtagIds': values?.hashtagIds?.map(x => x.value) || [],
            'deviceType': values?.deviceType ? parseFloat(values?.deviceType) : 0
        };

        if (values?.attachIds) {
            dataPost['attachIds'] = values?.attachIds || []
        }

        if (values.priority && parseFloat(values.priority) != NaN) {
            dataPost.priority = parseFloat(values.priority)
        }

        if (activeItem.postsId > 0) {
            await dispatch(onUpdate(dataPost));
        } else {
            await dispatch(onCreate(dataPost));
        }

    }

    const renderUploadByPostsFormat = (postFormat, props) => {
        let attachLink = Array.isArray(props?.values?.attachs) ? props?.values?.attachs[0]?.linkUrl[0] : ""
        let contentType = Array.isArray(props?.values?.attachs) ? props?.values?.attachs[0]?.contentType : ""

        switch (postFormat) {
            case '2':
            case 2:
                return(
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Label>Loại file <b className={'text-danger'}>*</b></Form.Label>
                            <Form.Select name="fileType" onChange={props.handleChange} value={props.values.fileType}>
                                <option value="" />
                                <option value="video">Video</option>
                                <option value="audio">Audio</option>
                            </Form.Select>
                        </Col>
                        {props.values.fileType === 'video' && (
                            <Col span={8}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Định hướng video <b className="text-danger">*</b></Form.Label>
                                    <Form.Select name="orientation" onChange={props.handleChange} isInvalid={props.errors.orientation && props.touched.orientation} value={props.values.orientation} >
                                        <option value="">--</option>
                                        {PORTRAIT_ORIENTATION.map((item) => <option key={`post-orientation-${item.code}`} value={item.code} label={item.name} />)}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{props.errors.orientation}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        )}

                        {props.values.fileType === 'video' && props.values.orientation && (
                            <Col span={8}>
                                <Form.Label>Tải lên Video <Spin spinning={isFileUploading} /></Form.Label>
                                <Field name="file" render={({ field }) => (
                                    <label className="mx-3" style={{ cursor: 'pointer' }}>
                                        <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2 d-none" />
                                        <input ref={inputFileRef} type="file" accept="video/*,audio/*" {...field} onChange={e => handleFileUploadByPostsFormat(e, props.setFieldValue, postFormat, props)} />
                                    </label>
                                )} />
                            </Col>
                        )}

                        {props.values.fileType === 'audio' && (
                            <Col span={8}>
                                <Form.Label>Tải lên Audio <Spin spinning={isFileUploading} /></Form.Label>
                                <Field name="file" render={({ field }) => (
                                    <label className="mx-3" style={{ cursor: 'pointer' }}>
                                        <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2 d-none" />
                                        <input ref={inputFileRef} type="file" accept="audio/*" {...field} onChange={e => handleFileUploadByPostsFormat(e, props.setFieldValue, postFormat, props)} />
                                    </label>
                                )} />
                            </Col>
                        )}

                        <Col span={24}>
                            <Form.Group className="mb-3 mt-3">
                                <div>
                                    {
                                        !!attachLink && contentType.indexOf('video/') >= 0 && <ReactHlsPlayer
                                            src={attachLink}
                                            autoPlay={false}
                                            controls={true}
                                            width="100%"
                                            height="auto"
                                        />
                                    }

                                    {
                                        !!attachLink && contentType.indexOf('audio/') >= 0 && <audio controls>
                                            <source src={attachLink} type={contentType} />
                                            Trình duyệt hiện tại không hỗ trợ audio tag
                                        </audio>
                                    }

                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                )
            case '3':
            case 3:
                return <Form.Group className="mb-3">
                    <Form.Label>Tải lên hình ảnh</Form.Label>
                    <Field name="file" render={({ field }) => (
                        <label className="mx-3" style={{ cursor: 'pointer' }}>
                            <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" />
                            <input type="file" accept="image/*" {...field} onChange={e => handleFileUploadByPostsFormat(e, props.setFieldValue, postFormat)} className="d-none" />
                        </label>
                    )} />
                    {/*{*/}
                    {/*    attachLink && contentType.indexOf('image/') >= 0 && (*/}
                    {/*    <div style={{ backgroundImage: `url('${attachLink}')`, backgroundSize: 'contain', height: '300px', backgroundRepeat: "no-repeat" }}/>*/}
                    {/*)}*/}
                    <div>
                        {
                            attachLink && contentType.indexOf('image/') >= 0 && (
                                <img src={attachLink} style={{height: 300, width: 'auto'}}/>
                            )
                        }
                    </div>
                </Form.Group>
            default:
                return <Form.Group className="mb-3">
                    <Form.Label>Tải tệp tin văn bản lên</Form.Label>
                    <Field name="file" render={({ field }) => (
                        <label className="mx-3" style={{ cursor: 'pointer' }}>
                            <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" />
                            <input type="file" accept=".docx, .doc" {...field} onChange={e => handleFileUpload(e, props.setFieldValue)} className="d-none" />
                        </label>
                    )} />
                </Form.Group>
        }
    }

    const renderModalRedirect = () => {
        return <Modal dialogClassName="modal-50w" show={isOpenModalRedirect} onHide={() => {
            setIsOpenModalRedirect(false)
        }}>
            <Modal.Header>
                <Modal.Title className="h6">Danh sách redirect link</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={() => setIsOpenModalRedirect(false)} />
            </Modal.Header>

            <div className="pd-20">

                <div className="row mgbt-20">
                    <div className="col-9">
                        <FormControl
                            value={redirectUrl}
                            onChange={e => setRedirectUrl(e.target.value)}
                            placeholder="Nhập đường dẫn"
                        />
                    </div>
                    <div className="col-3">
                        <Button
                            variant="secondary"
                            className="text-gray ms-auto"
                            onClick={() => createCampaign()}
                        >
                            Thêm
                        </Button>
                    </div>
                </div>

                {
                    campaigns.length > 0 && <div className="mgbt-20 bold">
                        Danh sách đường dẫn
                    </div>
                }
                {
                    campaigns.map((item,index) => <div key={index} className="mgbt-20 d-flex align-items-center justify-content-between">
                        <div>{item.redirectUrl}</div>

                        <div
                            className="pdl-10 cursor-pointer"
                            onClick={() => deleteCampaign(item)}
                        >
                            <FontAwesomeIcon icon={faTrash} className="" />
                        </div>
                    </div>)
                }
            </div>

        </Modal>
    }

    // console.log('vào đây render')
    return (
        <>
            <Modal dialogClassName="modal-90w" as={Modal.Dialog} show={isOpenModal} onHide={closeModal}>
                <Formik initialValues={activeItem} onSubmit={onSubmit} validationSchema={onValidate}>
                    {props => {
                        return (
                            <Form onSubmit={props.handleSubmit}>
                                <Modal.Header>
                                    <Modal.Title className="h6">Bài viết</Modal.Title>
                                    <Button variant="close" aria-label="Close" onClick={closeModal} />
                                </Modal.Header>
                                <Modal.Body>

                                    <div className="row">
                                        <div className="col-8">

                                            <Form.Group className="mb-3">
                                                <Form.Label>Tên bài viết</Form.Label>
                                                <Form.Control type="text" placeholder="Nhập tên bài viết" name="postsName" isInvalid={props.errors.postsName && props.touched.postsName} onChange={props.handleChange} value={props?.values?.postsName} />
                                                <Form.Control.Feedback type="invalid">{props.errors.postsName}</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Đường dẫn</Form.Label>
                                                <div className='row'>
                                                    <div className="col-8">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Nhập đường dẫn"
                                                        name="slug"
                                                        isInvalid={props.errors.slug && props.touched.slug}
                                                        onChange={props.handleChange}
                                                        value={props?.values?.slug}
                                                        onFocus={() => {
                                                            if (props?.values?.postsName && !props?.values?.slug) {
                                                                props.setFieldValue('slug', changeToSlug(props?.values?.postsName))
                                                            }
                                                        }}
                                                    />
                                                    </div>
                                                    <div className="col-4">
                                                    {
                                                        activeItem.postsId && <Button
                                                            variant="secondary"
                                                            className="text-gray ms-auto"
                                                            onClick={() => setIsOpenModalRedirect(true)}
                                                        >
                                                            Redirect
                                                        </Button>
                                                    }
                                                    </div>
                                                </div>

                                                <Form.Control.Feedback type="invalid">{props.errors.slug}</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Số thứ tự</Form.Label>
                                                <Form.Control type="text" placeholder="Nhập số thứ tự" name="priority" isInvalid={props.errors.priority && props.touched.priority} onChange={props.handleChange} value={props.values.priority} />
                                                <Form.Control.Feedback type="invalid">{props.errors.priority}</Form.Control.Feedback>
                                            </Form.Group>

                                            {renderUploadByPostsFormat(props.values.postFormat, props)}

                                            {/*<Form.Group className="mb-3">*/}
                                            {/*    <Form.Label>Tải tệp tin văn bản lên</Form.Label>*/}
                                            {/*    <Field name="file" render={({ field }) => (*/}
                                            {/*        <label className="mx-3" style={{ cursor: 'pointer' }}>*/}
                                            {/*            <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" />*/}
                                            {/*            <input type="file" accept=".docx, .doc" {...field} onChange={e => handleFileUpload(e, props.setFieldValue)} className="d-none" />*/}
                                            {/*        </label>*/}
                                            {/*    )} />*/}
                                            {/*</Form.Group>*/}

                                            {
                                                props.values.postFormat == 2 || props.values.postFormat == 3 ?
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Mô tả</Form.Label>
                                                        <Form.Control as="textarea" placeholder="Nhập mô tả" rows="3" name="postsContent" isInvalid={props.errors.postsContent && props.touched.postsContent} onChange={props.handleChange} value={props?.values?.postsContent} />
                                                    </Form.Group>
                                                    :
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Nội dung</Form.Label>
                                                        <Field name="postsContent">
                                                            {({ field }) => <Editor className={(props.errors.postsContent && props.touched.postsContent && `editor-errors`)} value={field.value} onChange={field.onChange(field.name)} />}
                                                        </Field>
                                                        {props.errors.postsContent && props.touched.postsContent && <div className="input-error">{props.errors.postsContent}</div>}
                                                    </Form.Group>
                                            }



                                        </div>
                                        <div className="col-4">
                                            <div className='row'>
                                                <div className="col-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Định dạng bài viết</Form.Label>
                                                        <Form.Select name="postFormat" onChange={props.handleChange} isInvalid={props.errors.postFormat && props.touched.postFormat} value={props.values.postFormat} >
                                                            {POST_FORMAT.map((item) => <option key={`post-postFormat-${item.id}`} value={item.id} label={item.name} />)}
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">{props.errors.postFormat}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>

                                                <div className="col-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Loại thiết bị</Form.Label>
                                                        <Form.Select name="deviceType" onChange={props.handleChange} isInvalid={props.errors.deviceType && props.touched.deviceType} value={props.values.deviceType} >
                                                            {DEVICE_FORMAT.map((item) => <option key={`post-deviceType-${item.id}`} value={item.id} label={item.name} />)}
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">{props.errors.deviceType}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>



                                            <Form.Group className="mb-3">
                                                <Form.Label>Loại bài viết</Form.Label>
                                                <Form.Select name="type" onChange={props.handleChange} isInvalid={props.errors.type && props.touched.type} value={props.values.type} >
                                                    {POST_TYPE.map((type) => <option key={`post-type-${type}`} value={type} label={type} />)}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{props.errors.type}</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Mục tiêu</Form.Label>
                                                <Form.Select name="targetId" onChange={e => onChooseTarget(e, props)} isInvalid={props.errors.targetId && props.touched.targetId} value={props.values.targetId} >
                                                    <option value=''>-- Chọn mục tiêu --</option>
                                                    {listTargets.map((target) => <option key={`target-${target.targetId}`} value={(target.targetId)} label={target.targetName} />)}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{props.errors.targetId}</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Chuyên mục</Form.Label>
                                                <Form.Select name="categoryId" onChange={e => onChooseCategory(e, props)} isInvalid={props.errors.categoryId && props.touched.categoryId} value={props.values.categoryId} >
                                                    <option value=''>-- Chọn chuyên mục --</option>
                                                    {listCategories.map((category) => <option key={`category-${category.categoryId}`} value={(category.categoryId)} label={category.categoryName} />)}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{props.errors.categoryId}</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Chủ đề</Form.Label>
                                                <Form.Select name="topicId" onChange={props.handleChange} isInvalid={props.errors.topicId && props.touched.topicId} value={props.values.topicId} >
                                                    <option value=''>-- Chọn chủ đề --</option>
                                                    {listTopics.map((topic) => <option key={`topic-${topic.topicId}`} value={(topic.topicId)} label={topic.topicName} />)}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{props.errors.topicId}</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Ảnh bìa bài viết</Form.Label>
                                                <Form.Control type="hidden" name="postsAvatar" isInvalid={props.errors.postsAvatar && props.touched.postsAvatar} onChange={props.handleChange} value={props.values.postsAvatar} />
                                                <Field name="file" render={({ field }) => (
                                                    <label className="mx-3" style={{ cursor: 'pointer' }}>
                                                        <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" />
                                                        <input type="file" accept="image/*" {...field} onChange={e => handleImageUpload(e, props.setFieldValue, 'postsAvatar')} className="d-none" />
                                                    </label>
                                                )} />
                                                {/*{props.values.postsAvatar && (*/}
                                                {/*    <div style={{ backgroundImage: `url('${props?.values?.postsAvatar}')`, backgroundSize: 'cover', height: '300px', backgroundPosition: 'center' }}></div>*/}
                                                {/*)}*/}

                                                <div>
                                                    {
                                                        props.values.postsAvatar && (
                                                            <img src={props.values.postsAvatar} style={{height: 300, width: 'auto'}}/>
                                                        )
                                                    }
                                                </div>

                                                <Form.Control.Feedback type="invalid">{props.errors.postsAvatar}</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Ảnh Thumbnail</Form.Label>
                                                <Form.Control type="hidden" name="postsThumbnail" isInvalid={props.errors.postsThumbnail && props.touched.postsThumbnail} onChange={props.handleChange} value={props.values.postsThumbnail} />
                                                <Field name="file" render={({ field }) => (
                                                    <label className="mx-3" style={{ cursor: 'pointer' }}>
                                                        <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" />
                                                        <input type="file" accept="image/*" {...field} onChange={e => handleImageUpload(e, props.setFieldValue, 'postsThumbnail')} className="d-none" />
                                                    </label>
                                                )} />
                                                {/*{props.values.postsThumbnail && (*/}
                                                {/*    <div style={{ backgroundImage: `url('${props?.values?.postsThumbnail}')`, backgroundSize: 'cover', height: '300px', backgroundPosition: 'center' }}></div>*/}
                                                {/*)}*/}

                                                <div>
                                                    {
                                                        props.values.postsThumbnail && (
                                                            <img src={props.values.postsThumbnail} style={{height: 300, width: 'auto'}}/>
                                                        )
                                                    }
                                                </div>

                                                <Form.Control.Feedback type="invalid">{props.errors.postsThumbnail}</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Ảnh Icon</Form.Label>
                                                <Form.Control type="hidden" name="postsIcon" isInvalid={props.errors.postsIcon && props.touched.postsIcon} onChange={props.handleChange} value={props.values.postsIcon} />
                                                <Field name="file" render={({ field }) => (
                                                    <label className="mx-3" style={{ cursor: 'pointer' }}>
                                                        <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" />
                                                        <input type="file" accept="image/*" {...field} onChange={e => handleImageUpload(e, props.setFieldValue, 'postsIcon')} className="d-none" />
                                                    </label>
                                                )} />
                                                {/*{props.values.postsIcon && (*/}
                                                {/*    <div style={{ backgroundImage: `url('${props?.values?.postsIcon}')`, backgroundSize: 'cover', height: '300px', backgroundPosition: 'center' }}></div>*/}
                                                {/*)}*/}

                                                <div>
                                                    {
                                                        props.values.postsIcon && (
                                                            <img src={props.values.postsIcon} style={{height: 300, width: 'auto'}}/>
                                                        )
                                                    }
                                                </div>

                                                <Form.Control.Feedback type="invalid">{props.errors.postsIcon}</Form.Control.Feedback>
                                            </Form.Group>


                                            <Form.Group className="mb-3">
                                                <Form.Label>Hashtag</Form.Label>
                                                {listHashtag &&
                                                <SelectField
                                                    ref={selectRef}
                                                    name="hashtagIds"
                                                    placeholder="Thêm hashtag cho bài viết"
                                                    options={listHashtag}
                                                    value={props.values.hashtagIds}
                                                    isMulti={true}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    onKeyDown={(e, cb) => onPasteHashtag(e, props, cb)}
                                                    touched={props.touched.hashtagIds}
                                                    error={props.errors.hashtagIds}
                                                    isClearable={true}
                                                    backspaceRemovesValue={true}
                                                />
                                                }
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Chuyên gia</Form.Label>
                                                {
                                                    professionals &&
                                                    <Select
                                                        className="custom-react-select"
                                                        placeholder="Chọn chuyên gia"
                                                        onInputChange={onInputSelectChange}
                                                        options={professionals.map(x => {return {value: x.id, label: x.name}})}
                                                        value={
                                                            props.values.professional ? {
                                                                ...props.values.professional,
                                                                value: props.values.professional?.id,
                                                                label: props.values.professional?.name,
                                                            }
                                                            : undefined
                                                        }
                                                        isMulti={false}
                                                        onChange={(value) => {
                                                            props.setFieldValue('professional', {
                                                                id: value?.value,
                                                                name: value?.label,
                                                            })
                                                        }}
                                                        isClearable={true}
                                                        backspaceRemovesValue={true}
                                                    />
                                                }
                                            </Form.Group>

                                        </div>
                                    </div>


                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" className="text-gray ms-auto" onClick={() => onSubmit(props.values)}>
                                        {activeItem.postsId > 0 ? `Cập nhật` : `Tạo bài viết`}
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        )
                    }}
                </Formik>
            </Modal>
            {renderModalRedirect()}
        </>
    )
}
