import axios from 'axios';
import qs from 'querystring';
import { BASIC_TOKEN } from '../contains/common';
import StoreService from './store.services';
import {API_URL} from "../contains/common";

class APIService {

    constructor() {
        this.store = new StoreService();
        this.modulePath = null;
        this.instance = axios.create({
            baseURL: API_URL
        });
    }

    /**
     * Hàm gửi request chung
     * @param method Phương thức gửi lên
     * @param path Phần url khai báo trên swagger
     * @param data Dữ liệu gửi lên
     * @returns Nội dung do server quy định trên swagger
     */
    async request(method, path, data = {}, isUpload = false, config = {}) {
        try {
            if (!config?.notAuthorization) {
                this.handleRequestHeader(path, isUpload);
            }
            // console.log('{ method, url: path, data }', { method, url: path, data })
            const response = await this.instance.request({ method, url: path, data, ...config});
            return response;
        } catch (error) {
            throw this.handleResponseError(error);
        }
    }

    handleRequestHeader(path, isUpload = false) {

        if (isUpload) {
            this.instance.defaults.headers.common['Content-Type'] = `multipart/form-data`;
        } else {
            this.instance.defaults.headers.common['Content-Type'] = `application/json;charset=UTF-8`;
        }

        // Do not add header for login
        if (path?.includes('account-managers/login') === true) {
            delete this.instance.defaults.headers.common['Authorization'];
            return;
        }

        if (this.store.accessToken) {
            this.instance.defaults.headers.common['Authorization'] = `Bearer ${this.store.accessToken}`;
        } else {
            this.instance.defaults.headers.common['Authorization'] = `Basic ${BASIC_TOKEN}`;
        }
    }

    handleResponseError({ response, request, message }) {
        // Server trả về lỗi không thành công
        if (response) {
            this.handleLoginState(response);
            if (response.data && response.data.errors) {
                return { code: response.status, message: response.data.errors.join(',') };
            }
            return { code: response.status, message: `${response.status}: ${JSON.stringify(response.data.error)}` };
        } else {
            // Reponse dont have, token is faild
            // comment when request_timeout data.
            // this.store.clearData();
            // window.location.reload();
        }

        // Không nhận được phản hồi từ phía server
        if (request) {
            return { code: '408', message: `request_timeout` };
        }

        return { code: '999', message };
    }

    // xử lý thông tin ngoài đăng nhập
    handleLoginState(response) {
        if (response.status === 401) {
            this.store.clearData();
            window.location.reload();
        }
    }

    // COMMON FUNC
    async search(paramsSearch) {
        if (!this.modulePath) return;
        return await this.request('GET', `${this.modulePath}/search?${qs.stringify(paramsSearch)}`);
    }

    async readAll(paramsSearch) {
        if (!this.modulePath) return;
        return await this.request('GET', `${this.modulePath}/findAll?${qs.stringify(paramsSearch)}`);
    }

    async readOne(id) {
        if (!this.modulePath) return;
        return await this.request('GET', `${this.modulePath}/${id}`);
    }

    async create(basicDto) {
        if (!this.modulePath) return;
        return await this.request('POST', this.modulePath, basicDto);
    }

    async update(basicDto) {
        if (!this.modulePath) return;
        return await this.request('PUT', this.modulePath, basicDto);
    }

    async delete(basicDto) {
        if (!this.modulePath) return;
        return await this.request('DELETE', this.modulePath, basicDto);
    }
}

export default APIService;