import APIService from './api.services';
import qs from 'querystring';

export default class CustomerService extends APIService {

    async search(paramsSearch = {}) {
        return await this.makeRequest('GET', `user-customers?${qs.stringify(paramsSearch)}`);
    }

    getById(id) {
        return this.makeRequest('GET', `user-customers/${id}`)
    }

    upVip(data) {
        return this.makeRequest('POST', 'user-customer-vip', data)
    }
}
