import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Modal } from '@themesberg/react-bootstrap';

import { Formik } from "formik";
import * as Yup from 'yup';

import { ERROR_MESSAGE } from '../../contains/errors';
import { onCreate, onUpdate, hideModal } from "../../store/package";

export default () => {

    const dispatch = useDispatch();
    const isOpenModal = useSelector(({ packages }) => packages.isOpenModal);
    const activeItem = useSelector(({ packages }) => packages.item);

    const closeModal = () => {
        dispatch(hideModal());
    }

    const onValidate = Yup.object().shape({
        packageName: Yup.string()
            .required(ERROR_MESSAGE.NEWS_NAME_REQUIRED)
    });

    const onSubmit = async (values) => {

        delete values.target;
        delete values.createdBy;
        delete values.modifiedBy;
        delete values.modifiedDate;
        delete values.createdDate;
        delete values.status;
        delete values.statusStr;
        delete values.isChecked;
        delete values.order;

        if (activeItem.packageId > 0) {
            await dispatch(onUpdate(values));
        } else {
            await dispatch(onCreate(values));
        }
    }

    return (
        <Modal size="xl" as={Modal.Dialog} centered show={isOpenModal} onHide={closeModal}>
            <Formik initialValues={activeItem} onSubmit={onSubmit} validationSchema={onValidate}>
                {props => (
                    <Form onSubmit={props.handleSubmit}>
                        <Modal.Header>
                            <Modal.Title className="h6">Tin tức</Modal.Title>
                            <Button variant="close" aria-label="Close" onClick={closeModal} />
                        </Modal.Header>
                        <Modal.Body>


                            <Form.Group className="mb-3">
                                <Form.Label>Tên gói</Form.Label>
                                <Form.Control type="text" placeholder="Nhập tên gói" name="packageName" isInvalid={props.errors.packageName && props.touched.packageName} onChange={props.handleChange} value={props.values.packageName} />
                                <Form.Control.Feedback type="invalid">{props.errors.packageName}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Nội dung</Form.Label>
                                <Form.Control as="textarea" rows="20" name="packageContent" isInvalid={props.errors.packageContent && props.touched.packageContent} onChange={props.handleChange} value={props.values.packageContent} />
                            </Form.Group>


                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" className="text-gray ms-auto" type="submit">
                                {activeItem.packageId > 0 ? `Cập nhật` : `Tạo gói`}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}