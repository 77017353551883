import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import {Card} from "antd";
import React from "react";

export const RoomItem = (props) => {
    const {currentRoom, item, onSelectRoom} = props
    return (
        <Card
            onClick={() => onSelectRoom()}
            className={`chat-user-item ${currentRoom && currentRoom?.roomId === item.roomId  ? 'chat-user-item--selected' : ''}`}
            title={item?.customerProfile?.username || `User: ${item?.userId}`}
            extra={
                <FontAwesomeIcon
                    className={'chat-user-item__online-status chat-user-item__online-status--online'}
                    icon={Icon.faCircle} />
            }
        >
            <div className={'chat-user-item__message'}>{item?.title}</div>
        </Card>
    )
}
