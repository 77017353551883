import {Breadcrumb} from "@themesberg/react-bootstrap";
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import lodash from 'lodash';
import {Button, Card, Form, InputNumber, Modal, notification, Select} from "antd";
import CategoryService from "../../services/category.service";
import CodeGeneratorService from "../../services/code-generator.service";
import {useForm} from "antd/es/form/Form";
import {Link} from "react-router-dom";

const categoryService = new CategoryService()
const codeGeneratorService = new CodeGeneratorService()

export const ActivationCodeBatchCreate = () => {
    const [loading, setLoading] = useState(false)
    const [vipPackages, setVipPackages] = useState([])
    const [form] = useForm()

    useEffect(() => {
        setLoading(true)
        categoryService.getVipPackages()
            .then(response => {
                setLoading(false)
                setVipPackages(response.data)
            })
            .catch(() => setLoading(false))
    }, [])

    const handleSubmit = () => {
        Modal.confirm({
            title: "Bạn có chắc chắn muốn tạo mã nâng cấp tài khoản với thông tin sau",
            content: (
                <div>
                    <div>Gói: {lodash.get(lodash.find(vipPackages, p => p.code === form.getFieldValue('vipPackage')), 'name')}</div>
                    <div>Số lượng: {form.getFieldValue('numberOfCode')}</div>
                </div>
            ),
            onOk: () => {
                form.validateFields()
                    .then(values => {
                        setLoading(true)
                        codeGeneratorService.generateCode({
                            numberOfCode: values.numberOfCode,
                            vipPackage: values.vipPackage
                        })
                            .then(() => {
                                setLoading(false)
                                notification.success({
                                    message: "Tạo mã thành công"
                                })

                                form.resetFields()
                            })
                            .catch(() => {
                                setLoading(false)
                                notification.error({
                                    message: "Tạo mã thất bại"
                                })
                            })
                    })
            },
            okButtonProps: {
                loading: loading,
                disabled: loading
            },
            cancelButtonProps: {
                loading: loading,
                disabled: loading
            }
        })

    }

    return (
        <div className="activation-code-create-page">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item to={Routes.DashboardOverview.path}>
                            <FontAwesomeIcon icon={faHome} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active><Link to={Routes.ActivationCodeBatch.path}>Mã kích hoạt</Link></Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Mã kích hoạt</h4>
                    <p className="mb-0">Tạo mã kích hoạt</p>
                </div>
            </div>

            <Card title={"Tạo mã kích hoạt"}>
                <Form form={form} layout={'vertical'} onFinish={handleSubmit}>
                    <Form.Item
                        label={"Loại"}
                        name={"vipPackage"}
                        required={true}
                        rules={[
                            {required: true, message: "Không để trống"}
                        ]}
                    >
                        <Select loading={loading} style={{width: 200}}>
                            {vipPackages.map(item => (
                                <Select.Option key={item.code}>{item.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={"Số lượng"}
                        name={"numberOfCode"}
                        required={true}
                        rules={[
                            {required: true, message: "Không để trống"},
                            () => ({
                                validator(_, value) {
                                    if (value <= 1000) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Tối đa 1000'));
                                },
                            }),
                        ]}
                    >
                        <InputNumber max={1000} style={{width: 200}} />
                    </Form.Item>

                    <Button loading={loading} disabled={loading} htmlType={'submit'} type={'primary'}>Tạo mã</Button>

                    <Button type={'link'}><Link to={Routes.ActivationCodeBatch.path}>Quay lại danh sách</Link></Button>
                </Form>
            </Card>

        </div>
    )
}
