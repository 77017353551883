import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

import { useSelector, useDispatch } from 'react-redux';

import { Toast, Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBandcamp } from '@fortawesome/free-brands-svg-icons';

import { hideToast } from "../store/global";

const BbuToast = () => {

    const dispatch = useDispatch();
    const toast = useSelector(({ global }) => global.toast);

    const hiddenToast = () => {
        dispatch(hideToast());
    }

    useEffect(() => {
        let timer = setTimeout(() => hiddenToast(), toast.deplay)
        return () => {
            clearTimeout(timer);
        };
    }, [toast])

    if (toast.isHidden === false) {

        let type = 'done';
        let icon = faBandcamp;
        switch (toast.type) {
            case 'error':
                type = 'bg-danger text-white ';
                icon = faBandcamp;
                break;
            case 'warning':
                type = 'bg-warning text-white ';
                icon = faBandcamp
                break;
            case 'info':
                type = 'bg-info text-white ';
                icon = faBandcamp;
                break;
            default:
                type = 'bg-success text-white ';
                icon = faBandcamp;
                break;
        }

        return ReactDOM.createPortal(
            <React.Fragment>
                <Toast show={!toast.isHidden} onClose={hiddenToast} className="bbtoast my-3">
                    <Toast.Header className={type} closeButton={false}>
                        <FontAwesomeIcon icon={icon} />
                        <strong className="me-auto ms-2">Thông báo</strong>
                        {/* <small>11 mins ago</small> */}
                        <Button variant="close" size="xs" onClick={hiddenToast} />
                    </Toast.Header>
                    <Toast.Body>
                        {toast.message}
                    </Toast.Body>
                </Toast>
            </React.Fragment>,
            document.body
        );
    } else return <React.Fragment></React.Fragment>;
};

export default BbuToast;
