import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Form } from '@themesberg/react-bootstrap';

import { Field, Formik } from "formik";
import * as Yup from 'yup';

import UserService from "../../services/user.services";

import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import FileService from "../../services/file.service";
import { showToast } from "../../store/global";
import dayjs from "dayjs";
import { FORMAT_DATE_NORMAL } from "../../contains/common";

export default (props) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const { userId } = props.match.params;
    const [user, setUser] = useState({
        "file": '',
        "avatar": '',
        "userId": '',
        "username": '',
        "phoneNumber": '',
        "email": '',
        "fullname": '',
        "password": '',
        "roleCode": "USER",
        "type": "",
        "status": 0
    });
    const [roles, setRoles] = useState([]);
    const [listStatus, setListStatus] = useState([
        { label: 'Active', value: 1 },
        { label: 'Inactive', value: 2 },
    ]);

    const userService = new UserService();
    const fileService = new FileService();

    useEffect(() => {
        (async () => {
            await fetchRole();
            await findUser();
        })();
    }, [userId]);

    useEffect(() => {
        roles && roles.indexOf(user.roleCode) < 0 && setRoles([user.roleCode, ...roles]);
    }, [user.roleCode, roles])

    const fetchRole = async () => {
        try {
            const { body } = await userService.getRoles();
            setRoles(body);
        } catch (error) {
            dispatch(showToast([`error`, error.message]));
        }
    }

    const findUser = async () => {
        if (!userId) return;
        try {
            const { body } = await userService.getUserById(userId);
            setUser(body);
        } catch (error) {
            dispatch(showToast([`error`, error.message]));
        }
    }

    const onCreate = async (user) => {
        try {
            const { body } = await userService.adminCreateUser(user);
            dispatch(showToast([`done`, `Thêm mới người dùng thành công`]));
            return body;
        } catch (error) {
            dispatch(showToast([`error`, error.message]));
        }
    }

    const onUpdate = async (user, values) => {
        try {
            const { body } = await userService.adminUpdateUser(user);
            dispatch(showToast([`done`, `Cập nhật thông tin người dùng thành công`]));
            setUser(body);
            return body;
        } catch (error) {
            dispatch(showToast([`error`, error.message]));
        }
    }

    const handleFileUpload = async (e, setFieldValue) => {

        const file = e.target.files[0];
        var fileSize = file.size / 1024 / 1024; // in MiB
        if (fileSize > 5) {
            return alert('Dung lượng ảnh lớn hơn 5 MiB, vui lòng chọn ảnh khác.');
        }

        try {
            const resp = await fileService.uploadImage(file);
            if (Array.isArray(resp.linkUrl)) {
                setFieldValue('avatar', resp.linkUrl[0]);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onValidate = Yup.object().shape({});

    const onSubmit = async (values) => {

        let dataPost = {
            "userId": values.userId,
            "email": values.email,
            "username": values.username,
            "fullname": values.fullname,
            "phoneNumber": values.phoneNumber,
            "password": values.password,
            "roleCode": values.roleCode,
            "status": values.status,
        }

        if (userId && userId > 0) {
            dataPost.type = values.type;
            dataPost.avatar = values.avatar;
            await onUpdate(dataPost, values);
        } else {
            delete dataPost.userId;
            const respUser = await onCreate(dataPost, values);
            if (respUser.userId) {
                await userService.uploadAvatar(respUser.userId, values.avatar);
                history.push(`/user/${respUser.userId}`);
            }
        }

    }

    return (
        <React.Fragment>
            {((userId && user.userId) || !userId) && (
                <Formik initialValues={user} onSubmit={onSubmit} validationSchema={onValidate}>
                    {props => (
                        <Form onSubmit={props.handleSubmit}>
                            <div className="row">
                                <div className="col-xl-8 col-12">
                                    <div className="bg-white shadow-sm mb-4 card border-light">
                                        <div className="card-body">
                                            <h5 className="mb-4">Thông tin chung</h5>

                                            <div className="row">
                                                <div className="mb-3 col-md-6">
                                                    <Form.Label>Tài khoản</Form.Label>
                                                    <Form.Control type="text" placeholder="Nhập tên người dùng" name="username" disabled={userId > 0} onChange={props.handleChange} value={props?.values?.username} />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="text" placeholder="Nhập địa chỉ hòm thư" name="email" isInvalid={props.errors.email && props.touched.email} onChange={props.handleChange} value={props?.values?.email} />
                                                    <Form.Control.Feedback type="invalid">{props.errors.email}</Form.Control.Feedback>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="mb-3 col-md-6">
                                                    <Form.Label>Quyền</Form.Label>
                                                    <Form.Select name="roleCode" isInvalid={props.errors.roleCode && props.touched.roleCode} onChange={props.handleChange} value={props?.values?.roleCode} >
                                                        <option value=''>-- Chọn quyền --</option>
                                                        {roles.map((role) => <option key={`role-${role}`} value={role} label={role} />)}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{props.errors.roleCode}</Form.Control.Feedback>
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <Form.Label>Mật khẩu</Form.Label>
                                                    <Form.Control type="password" placeholder="Nhập mật khẩu" name="password" isInvalid={props.errors.password && props.touched.password} onChange={props.handleChange} value={props?.values?.password} />
                                                    <Form.Control.Feedback type="invalid">{props.errors.password}</Form.Control.Feedback>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="mb-3 col-md-6">
                                                    <Form.Label>Tên đầy đủ</Form.Label>
                                                    <Form.Control type="text" placeholder="Nhập tên đầy đủ" name="fullname" isInvalid={props.errors.fullname && props.touched.fullname} onChange={props.handleChange} value={props?.values?.fullname} />
                                                    <Form.Control.Feedback type="invalid">{props.errors.fullname}</Form.Control.Feedback>
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <Form.Label>Số điện thoại</Form.Label>
                                                    <Form.Control type="text" placeholder="Nhập số điện thoại" name="phoneNumber" isInvalid={props.errors.phoneNumber && props.touched.phoneNumber} onChange={props.handleChange} value={props?.values?.phoneNumber} />
                                                    <Form.Control.Feedback type="invalid">{props.errors.phoneNumber}</Form.Control.Feedback>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="mb-3 col-md-6">
                                                    <Form.Label>Trạng thái thài khoản</Form.Label>
                                                    <Form.Select name="status" isInvalid={props.errors.status && props.touched.status} onChange={props.handleChange} value={props?.values?.status} >
                                                        {listStatus.map((status) => <option key={`status-${status.value}`} value={status.value} label={status.label} />)}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{props.errors.status}</Form.Control.Feedback>
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <Form.Label>Loại tài khoản {props?.values?.expiredResourcesDate && `(hết hạn : ${dayjs(props?.values?.expiredResourcesDate).format(FORMAT_DATE_NORMAL) ?? '-'})`}</Form.Label>
                                                    <Form.Control type="text" placeholder="Nhập loại tài khoản" name="type" onChange={props.handleChange} value={props?.values?.type} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="mb-3 col-md-6">
                                                    <Form.Label>Người tạo</Form.Label>
                                                    <Form.Control type="text" disabled value={props?.values?.createdBy} />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <Form.Label>Người cập nhập</Form.Label>
                                                    <Form.Control type="text" disabled value={props?.values?.modifiedBy} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="mb-3 col-md-6">
                                                    <Form.Label>Ngày tham gia</Form.Label>
                                                    <Form.Control type="text" disabled value={dayjs(props?.values?.createdDate).format(FORMAT_DATE_NORMAL) ?? '-'} />
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <button type="submit" className="btn btn-primary">{userId > 0 ? `Cập nhật` : `Tạo mới`}</button>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className="col-xl-4 col-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="bg-white shadow-sm mb-4 card border-light">
                                                <div className="card-body">
                                                    <h5 className="mb-4">Chọn ảnh đại diện</h5>
                                                    <div className="d-xl-flex align-items-center">
                                                        <div className="user-avatar xl-avatar bg-tertiary">
                                                            <div className="img-fluid rounded text-uppercase">
                                                                {!props.values.avatar ?
                                                                    (<span>{props?.values?.username.slice(0, 2)}</span>) :
                                                                    <img src={props?.values?.avatar} />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="file-field">
                                                            <div className="d-flex justify-content-xl-center ms-xl-3">
                                                                <Form.Control type="hidden" name="avatar" isInvalid={props?.errors?.avatar && props?.touched?.avatar} onChange={props?.handleChange} value={props?.values?.avatar ?? ''} />
                                                                <Field name="file" render={({ field }) => (
                                                                    <label className="mx-3 d-flex" style={{ cursor: 'pointer' }}>
                                                                        <span className="icon icon-md">
                                                                            <FontAwesomeIcon icon={faPaperclip} className="me-2" />
                                                                        </span>
                                                                        <div className="d-md-block text-start">
                                                                            <div className="fw-normal text-dark mb-1">Choose Image</div>
                                                                            <div className="text-gray small">JPG, GIF or PNG. Max size of 800K</div>
                                                                        </div>
                                                                        <input type="file" accept="image/*" {...field} onChange={e => handleFileUpload(e, props?.setFieldValue)} className="d-none" />
                                                                    </label>
                                                                )} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </React.Fragment >
    )
}