import React from 'react';
import { useDispatch } from 'react-redux';
import { Nav, Pagination } from '@themesberg/react-bootstrap';

export const Paging = ({ data, searchParams, onChangePage }) => {
    const dispatch = useDispatch();
    if (data.totalPages > 1) {
        return (
            <React.Fragment>
                <Nav>
                    <Pagination className="mb-2 mb-lg-0">
                        <Pagination.Prev onClick={e => dispatch(onChangePage(searchParams.page - 1))}>Previous</Pagination.Prev>
                        {[...Array(data.totalPages).keys()].map(page => (
                            <Pagination.Item key={`page-${page}`} onClick={e => dispatch(onChangePage(page))} active={data.number === page} > { page + 1} </Pagination.Item>
                        ))}
                        <Pagination.Next onClick={e => dispatch(onChangePage(searchParams.page + 1))}>Next</Pagination.Next>
                    </Pagination>
                </Nav>
                <small className="fw-bold">
                    Showing <b>{data.numberOfElements}</b> out of <b>{data.totalElements}</b> entries
                </small>
            </React.Fragment>
        )
    } else {
        return <React.Fragment />;
    }
}