import APIService from './api.services';

class NotificationService extends APIService {

    async filter(filter = {}) {
        return await this.makeRequest('GET', `customer-notification-scheduler`, {
            params: filter
        });
    }

    create(data = {}) {
        return this.makeRequest('POST', `customer-notification-scheduler`, data);
    }

    getDetail(id) {
        return this.makeRequest('GET', `customer-notification-scheduler/${id}`);
    }

    delete(id) {
        return this.makeRequest('DELETE', `customer-notification-scheduler/${id}`);
    }
}
const notificationService = new NotificationService();
export default notificationService
