import React from "react";
import moment from "moment";
import {CHAT_IMG_URL, defaultCustomerAvatar} from "../../contains/common";

export const MessageItem = (props) => {
    const {item, itMe, currentCustomer} = props
    return (
        <div className={`chat-message-item ${!itMe ? 'chat-message-item--not-me' : 'chat-message-item--it-me'}`}>
            {!itMe && (
                <img src={currentCustomer?.avatar || defaultCustomerAvatar} className={'chat-message-item__avatar'} />
            )}
            <div className="chat-message-item__message">
                {item.msg && (
                    <span className={'text'}>{item.msg}</span>
                )}

                {Array.isArray(item.attachments) && item.attachments.map((attachment, k) => (
                    <React.Fragment key={k}>
                        <div className="chat-partner-content">
                            <div className="chat-partner-text">{attachment?.description}</div>
                            {
                                attachment.image_type && attachment?.image_type.indexOf("image/") >= 0 && <div>
                                    <img
                                        className="content-image"
                                        src={`${CHAT_IMG_URL}${attachment?.image_url}`}
                                    />
                                </div>
                            }
                        </div>
                    </React.Fragment>
                ))}

                <span className={'time'}>{moment(item.ts.$date).format("DD/MM/YYYY HH:mm")}</span>
            </div>
        </div>
    )
}
