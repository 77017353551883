import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faEye, faUserShield, faTimesCircle, faHandSparkles } from '@fortawesome/free-solid-svg-icons';
import { Card, Button, Table, Dropdown, ButtonGroup, Form, InputGroup } from '@themesberg/react-bootstrap';

import { Paging } from "../../components/Paging";

import {search, onDelete, checkAll, checkItem, onChangePage, openModal, fetchById, setKeyword, setDeviceType} from "../../store/post";
import Preloader from "../../components/Preloader";

import dayjs from 'dayjs';
import { FORMAT_DATE_NORMAL, PUBLIC_URL } from "../../contains/common";
import { Helper } from "../../common/helper";

const TableRow = (row) => {

    const dispatch = useDispatch()

    const onEditItem = async (row) => {
        await dispatch(fetchById(row.postsId));
        dispatch(openModal());
    }

    const onDeleteItem = async (row) => {
        await dispatch(fetchById(row.postsId));
        const del = window.confirm(`Xóa bản ghi [${row.postsName}] ?`);
        if (del) {
            dispatch(onDelete({
                postsId: row.postsId
            }));
        }
    }

    const { postsId, postsName, postsContent, createdDate, modifiedBy, status, statusStr, topicBasic } = row;
    const statusVariant = status === "Paid" ? "success" : status === "Due" ? "warning" : status === "Canceled" ? "danger" : "primary";

    return (
        <tr>
            <td>
                <Form.Check className="inbox-check me-2" checked={row.isChecked} onChange={e => dispatch(checkItem(row))} />
            </td>
            <td>
                <span className="fw-normal"><b>{postsId ?? '-'}</b></span>
            </td>
            <td>
                <a href={`${PUBLIC_URL}/${Helper.slugUrl(postsName)}-p${postsId}`} target="_blank">
                    <span className="fw-bold">
                        {postsName ?? '-'}
                    </span>
                    <div className="small text-gray">{Helper.stripTags(postsContent) ?? '-'}</div>
                </a>
            </td>
            <td>
                <span className="fw-normal">{dayjs(createdDate).format(FORMAT_DATE_NORMAL) ?? '-'}</span>
            </td>
            <td>
                <span className="fw-normal">{modifiedBy}</span>
            </td>
            <td>
                <span className={`fw-normal text-${statusVariant}`}>{statusStr}</span>
            </td>
            <td>
                <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                        <span className="icon icon-sm">
                            <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item>
                            <FontAwesomeIcon icon={faUserShield} className="me-2" /> Ẩn
                        </Dropdown.Item>
                        <Dropdown.Item onClick={e => onEditItem(row)}>
                            <FontAwesomeIcon icon={faEye} className="me-2" /> Chỉnh sửa
                        </Dropdown.Item>
                        <Dropdown.Item onClick={e => onDeleteItem(row)} className="text-danger">
                            <FontAwesomeIcon icon={faTimesCircle} className="me-2" /> Loại bỏ
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        </tr>
    );
};

export const PostTable = () => {

    const dispatch = useDispatch()
    const data = useSelector(({ post }) => post.table);
    const loading = useSelector(({ post }) => post.loading);
    const searchParams = useSelector(({ post }) => post.searchParams);

    useEffect(() => {
        (async () => {
            await dispatch(search(searchParams));
        })();
    }, [dispatch, searchParams]);

    return (
        <React.Fragment>
            <Preloader show={loading} />
            <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Body>
                    <div className="d-flex mb-3">
                        <div className="mb-0 me-3">
                            <InputGroup>
                                <Form.Select
                                    size="md"
                                    onChange={e => {
                                        // console.log('change', e.target.value)
                                        dispatch(setDeviceType(e.target.value))
                                    }}
                                >
                                    <option value=''>Chọn loại thiết bị</option>
                                    <option value='0'>App</option>
                                    <option value='1'>Web Babiuni</option>
                                    <option value='2'>Tất cả</option>
                                </Form.Select>
                                <Button className="border-light" variant="white" size="md">
                                    <FontAwesomeIcon icon={faHandSparkles} className="me-2" />
                                </Button>
                            </InputGroup>
                        </div>
                        <div>

                        </div>
                    </div>
                    <Table hover className="user-table align-items-center">
                        <thead>
                            <tr>
                                <th className="border-bottom">
                                    <Form.Check className="inbox-check me-2" checked={data.isCheckedAll} onChange={e => dispatch(checkAll(!data.isCheckedAll))} />
                                </th>
                                <th className="border-bottom">#</th>
                                <th className="border-bottom">Name</th>
                                <th className="border-bottom">Date Created</th>
                                <th className="border-bottom">Modifier</th>
                                <th className="border-bottom">Status</th>
                                <th className="border-bottom">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.items.map((t, index) => (
                                <TableRow key={`post-${t.postsId}-${t.createdDate}`} {...t} />
                            ))}
                        </tbody>
                    </Table>
                    <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                        <Paging {...{ searchParams, onChangePage, data }} />
                    </Card.Footer>
                </Card.Body>
            </Card>
        </React.Fragment>
    );
};
