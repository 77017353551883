import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Modal } from '@themesberg/react-bootstrap';

import { Formik } from "formik";
import * as Yup from 'yup';

import { ERROR_MESSAGE } from '../../contains/errors';
import { onCreate, onUpdate, hideModal } from "../../store/target";
import { showToast } from "../../store/global";

export default () => {

    const dispatch = useDispatch();
    const isOpenModal = useSelector(({ targets }) => targets.isOpenModal);
    const activeItem = useSelector(({ targets }) => targets.item);

    const closeModal = () => {
        dispatch(hideModal());
    }

    const onValidate = Yup.object().shape({
        targetName: Yup.string()
            .required(ERROR_MESSAGE.TARGET_NAME_REQUIRED),
        preFix: Yup.string()
            .required(ERROR_MESSAGE.PREFIX_REQUIRED),
    });

    const onSubmit = async (values) => {
        if (activeItem.targetId > 0) {
            await dispatch(onUpdate({
                target_id: values.targetId,
                target_name: values.targetName,
                preFix: values.preFix,
                target_content: values.targetContent
            }));
        } else {
            await dispatch(onCreate({
                target_name: values.targetName,
                preFix: values.preFix,
                target_content: values.targetContent
            }));
        }
    }

    return (
        <Modal as={Modal.Dialog} centered show={isOpenModal} onHide={closeModal}>
            <Formik initialValues={activeItem} onSubmit={onSubmit} validationSchema={onValidate}>
                {props => (
                    <Form onSubmit={props.handleSubmit}>
                        <Modal.Header>
                            <Modal.Title className="h6">Mục tiêu</Modal.Title>
                            <Button variant="close" aria-label="Close" onClick={closeModal} />
                        </Modal.Header>
                        <Modal.Body>


                            <Form.Group className="mb-3">
                                <Form.Label>Tên mục tiêu</Form.Label>
                                <Form.Control type="text" placeholder="Nhập tên mục tiêu" name="targetName" isInvalid={props.errors.targetName && props.touched.targetName} onChange={props.handleChange} value={props.values.targetName} />
                                <Form.Control.Feedback type="invalid">{props.errors.targetName}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Prefix mục tiêu</Form.Label>
                                <Form.Control type="text" placeholder="Nhập prefix mục tiêu" name="preFix" isInvalid={props.errors.preFix && props.touched.preFix} onChange={props.handleChange} value={props.values.preFix} />
                                <Form.Control.Feedback type="invalid">{props.errors.preFix}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Nội dung</Form.Label>
                                <Form.Control as="textarea" rows="3" name="targetContent" isInvalid={props.errors.targetContent && props.touched.targetContent} onChange={props.handleChange} value={props.values.targetContent} />
                            </Form.Group>


                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" className="text-gray ms-auto" type="submit">
                                {activeItem.targetId > 0 ? `Cập nhật` : `Tạo mục tiêu`}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}