import APIService from './customAPI.services';

class SurveysService extends APIService {

    constructor() {
        super();
    }

    async listSurveys(query) {
        return await this.request('GET', `surveys/search${query || ''}`, {});
    }

    async getDetail(id) {
        return await this.request('GET', `surveys/${id}`, {});
    }

    async deleteSurvey(id) {
        return await this.request('DELETE', `surveys/${id}`, {});
    }

    async changeActiveSurvey(id, status) {
        return await this.request('PATCH', `surveys/${id}?active=${status}`, {});
    }

    async createSurvey(body) {
        return await this.request('POST', `surveys`, body);
    }

    async updateSurvey(id, body) {
        return await this.request('PATCH', `surveys/${id}`, body);
    }

    async getReceivers() {
        return await this.request('GET', `surveys/receivers`, {});
    }

}
const surveysService = new SurveysService();
export default surveysService