import APIService from './api.services';
import axios from 'axios';
import {CHAT_API_URL} from "../contains/common";

class ChatsService extends APIService {

    constructor() {
        super();
    }

    async getList(obj) {
        return await this.request('GET', `chat/list?page=${obj.pageNumber}&size=${obj.pageSize}`, {});
    }

    async join(obj) {
        return await this.request('POST', `chat/join`, obj);
    }

    async receiveRoom(data) {
        return await this.request('POST', `chat/receive-room`, data);
    }

    async sendMessage(data) {

        return await axios.post(`${CHAT_API_URL}/method.call/sendMessage`, data?.body, {
            headers: data.headers,
        })
        // return await this.request('POST', `http://chat.dev.babiuni.com/api/v1/method.call/sendMessage`, body?.body, false, {
        //     notAuthorization: true,
        //     headers: body.headers,
        // });
    }

    async uploadFile(data) {

        const body = new FormData();
        body.append('description', data.description);
        body.append('file', data?.file);

        return await axios.post(`${CHAT_API_URL}/rooms.upload/${data.roomId}`, body, {
            headers: data.headers,
        })

        // return await this.request('POST', `http://chat.dev.babiuni.com/api/v1/rooms.upload/${data.roomId}`, body, false, {
        //     notAuthorization: true,
        //     headers: body.headers,
        // });
    }

    async getMyChatList(obj) {
        return await this.makeRequest('GET', `chat/current-user?page=${obj.pageNumber}&size=${obj.pageSize}`, {});
    }
}

export default ChatsService;
