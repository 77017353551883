import APIService from './api.services';

class CodeGeneratorService extends APIService {

    constructor() {
        super();
        this.modulePath = 'category';
    }

    generateCode(data = {numberOfCode: "", vipPackage: ""}) {
        return this.makeRequest('POST', `code-generator/generate`, data)
    }

    getList(params) {
        return this.makeRequest('GET', `code-generator/list-batch`, {
            params
        })
    }

    getDetailBatchById(id, params) {
        return this.makeRequest('GET', `code-generator/list-batch/${id}`, {
            params
        })
    }
}

export default CodeGeneratorService;
